import { ellipsisStyles, scrollBar } from '../../shared/styles/commonStyles';
import { changeColorLightness } from '../../shared/styles/utils';

const styles = theme => ({
  root: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: 20
    },
    minHeight: 'calc(100vh - 128px)'
  },
  fullHeight: {
    height: 'calc(100vh - 128px)'
  },
  redeemPageCardName: {
    color: '#0b1829',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '20px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '-0.03em'
  },
  redeemPageDetailsName: {
    color: '#131415',
    fontSize: '40px',
    fontFamily: 'Inter',
    fontWeight: 700,
    lineHeight: '52px',
    letterSpacing: '-0.03em',
    ...ellipsisStyles
  },
  // TODO: search usage and remove
  title: {
    fontSize: 16,
    lineHeight: 1
  },
  card: {
    padding: '20px 20px 24px 20px',
    borderRadius: 12,
    maxWidth: 285,
    width: 285,
    border: '1px solid #e5e7e8',
    '&:hover': {
      border: '1px solid #3577d4'
    }
  },
  lightText: {
    color: '#989EA4'
  },
  butonSizeSmall: {
    height: 40
  },
  separateProofButtonsContainer: {
    marginTop: 16,
    paddingTop: 10,
    borderTop: '1px solid  #E5E7E8'
  },
  historyStatus: {
    color: '#4A4F54'
  },
  snackbarContainer: {
    marginTop: 112,
    zIndex: 99
  },
  failureShipmentAlert: {
    width: '460px'
  },
  successShipmentAlert: {
    width: 'auto',
    minWidth: '300px',
    maxWidth: '500px'
  },
  drawerLink: {
    background: 'none !important',
    border: 'none',
    padding: '0 !important',
    color: '#069',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontWeight: '500',
    textDecoration: 'underline'
  },
  historyStatusContainer: {
    paddingBottom: 12
  },
  isSearching: {
    marginTop: 4,
    position: 'absolute',
    textAlign: 'center',
    color: '#787B80'
  },
  inputSection: {
    paddingBottom: 20
  },
  inputSectionHeader: {
    paddingBottom: 4,
    paddingTop: 12,
    paddingRight: 24
  },
  sectionLabel: {
    fontWeight: 500
  },
  statusText: {
    color: '#4A4F54',
    fontFamily: 'Gilroy',
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'left',
    marginTop: 8
  },
  emptyContainer: {
    height: 'calc(100% - 42px)'
  },
  packTitle: {
    marginTop: 24,
    color: '#131415',
    fontFamily: 'Gilroy',
    textAlign: 'left',
    fontWeight: 600,
    fontSize: 20,
    height: 52
  },
  redemptionTable: {
    '& .MuiTableCell-root': {
      borderRight: '0px !important',
      '& .divContent': theme.typography.body4RegularInter
    },
    '& th.MuiTableCell-root .divContent': {
      color: '#989EA4'
    }
  },
  proofImg: {
    width: 256,
    height: 256,
    objectFit: 'contain',
    textAlign: 'center',
    borderRadius: 15,
    [theme.breakpoints.between('md', 'lg')]: { width: 200, height: 200 },
    [theme.breakpoints.down('sm')]: { width: 256, height: 256 },
    [theme.breakpoints.down('xs')]: { width: 200, height: 200 }
  },
  tableContainer: {
    borderTop: 'solid 1px #f0f2f5',
    borderBottom: 'solid 1px #f0f2f5',
    backgroundColor: '#ffffff',
    overflowY: 'auto',
    overflow: 'hidden',
    flex: 1,
    minHeight: 44,
    ...scrollBar
  },
  tableWrapper: { position: 'relative', height: 'calc(100vh - 318px)' },
  tableWrapperSmall: { position: 'relative', height: 'calc(100vh - 348px)' },
  filtersWrapper: {
    borderTop: '1px solid #E5E7E8',
    borderBottom: '1px solid #E5E7E8',
    padding: '5px 0px',
    height: '56px'
  },
  shippingSuccess: { height: 10, width: 10, borderRadius: '50%', background: '#4CAF50', marginRight: 8 },
  shippingWarning: { height: 10, width: 10, borderRadius: '50%', background: '#FF9800', marginRight: 8 },
  shippingError: { height: 10, width: 10, borderRadius: '50%', background: '#F44336', marginRight: 8 },
  shippingBall: { height: 10, width: 10, borderRadius: '50%', marginRight: 8 },
  shippingStatusContainer: { textAlign: 'left', maxWidth: 256 },
  shippingStatusDescription: { ...ellipsisStyles },
  redemptionMenu: { borderRadius: 2 },
  redemptionMenuItem: {
    '& p': { color: '#131415' },
    zIndex: 999,
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' }
    // '&:disabled': { '& p': { color: 'red' } }
  },
  warningSign: { width: 14, height: 12, color: '#EF6C00', position: 'absolute', left: '-8px' },
  redeemCardMenu: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12), 0px 12px 24px 2px rgba(0, 0, 0, 0.06)',
    borderRadius: '2px'
  },
  redeemCardMenuItem: { padding: '8px 16px' },
  redeemCardMenuItemDelete: { padding: '8px 16px', color: '#C62828' },
  goBackIcon: {
    cursor: 'pointer',
    fontSize: 16,
    height: 16,
    marginRight: 10
  },
  goBack: {
    display: 'inline-flex',
    alignItems: 'center',
    color: '#787b80',
    transition: 'color 300ms ease',
    '&:hover': { color: changeColorLightness('#787b80') }
  },
  emptytitle: {
    marginTop: 20,
    fontSize: 24,
    color: '#0b1829',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  emptySubtitle: {
    fontSize: 14,
    lineHeight: 1.57,
    color: '#4A4F54',
    marginTop: 4
  },
  paginationContainer: {
    marginTop: -4,
    backgroundColor: '#ffffff',
    borderRadius: 12,
    borderTop: 'solid 1px #f0f2f5',
    padding: '32px 0px'
  },
  paginationContainerHome: {
    padding: '8px 20px',
    paddingBottom: 32
  },
  quantitySection: {
    marginTop: 8,
    borderRadius: '0px 0px 20px 20px',
    padding: '4px 10px',
    borderTop: '1px solid #cdcfd5'
  },
  uploadButton: {
    border: '1px solid #cdcfd5',
    borderRadius: 20,
    width: '100%',
    height: 40,
    '&:hover': {
      border: '1px solid #3577d4'
    }
  },
  uploadRegion: {
    border: '1px dashed #cdcfd5',
    borderRadius: 10,
    width: '100%',
    padding: '18px 38px',
    '&:hover': {
      border: '1px dashed #3577d4'
    }
  },
  dragDrop: {
    color: '#0B1829',
    marginTop: 12,
    marginBottom: 4,
    '& span': {
      color: '#3577d4'
    }
  },
  exportButtom: {
    padding: '0px 12px',
    fontFamily: 'Gilroy',
    cursor: 'pointer',
    color: '#3577d4',
    '&:disabled': {
      color: 'ebeef2'
    }
  },
  preferedImageTypes: {
    color: '#787B80',
    textAlign: 'center'
  },
  preferedImageError: {
    color: '#F44336',
    textAlign: 'center'
  },
  logoSelector: {
    borderRadius: 12,
    padding: '11px 16px 11px 16px',
    background: '#E5E7E8',
    marginBottom: 12,
    cursor: 'pointer'
  },
  lsImageContainer: {
    height: 24,
    width: 32
  },
  lsImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  inputColorText: {
    '& input': { padding: '14px 10px !important' }
  },
  inputColorTextError: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F44336 !important'
    }
  },
  lsImageName: {
    color: '#4A4F54',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  lsAlternativeText: {
    color: '#4A4F54',
    marginBottom: 4
  },
  cardsContainer: {
    gridTemplateColumns: 'repeat(auto-fill, 276px)',
    transition: 'all 1s',
    marginTop: 0
  },
  imageContainer: {
    // width: 246,
    height: 216,
    margin: '12px 0px'
  },
  designImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain !important'
  },
  cardHeader: {
    paddingBottom: 14
  },
  productCheckbox: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  productCard: {
    position: 'relative',
    padding: 0,
    paddingTop: 16,
    border: '1px solid #ebeef2',
    '&:hover': {
      border: '1px solid #3577d4'
    }
  },
  productCardsContainer: {
    paddingTop: 24,
    gridTemplateColumns: 'repeat(auto-fill, 132px)',
    transition: 'all 1s',
    marginTop: 0
  },
  productImageContainer: {
    width: 100,
    height: 100
  },
  productImage: {
    width: '100%',
    height: '100%',
    objectFit: 'scale-down !important'
  },
  productCardHeader: {
    paddingBottom: 8
  },
  designPriceInfo: {
    marginTop: 5,
    marginBottom: 14,
    marginLeft: 6,
    height: 15
  },
  priceInfo: {
    fontFamily: 'Gilroy-Semibold',
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: 'center',
    color: '#0b1829'
  },
  emptyImg: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  tableEmptyStateImage: {
    height: 192,
    width: 192
  },
  tableEmptyStateImageBig: {
    height: 300,
    width: 300
  },
  link: {
    cursor: 'pointer',
    alignItems: 'center',
    display: 'inline-flex',
    transition: 'color 250ms ease',
    '&:hover': { color: changeColorLightness('#3577d4') }
  },
  // TODO: search usage and remove
  linkIcon: {
    cursor: 'pointer',
    fontSize: 16,
    marginLeft: 6
  },
  checkedIcon: {
    height: 24,
    width: 24,
    cursor: 'pointer',
    fontSize: 16,
    color: '#3577D4'
  },
  closeIcon: {
    height: 20,
    width: 20,
    padding: 2,
    borderRadius: 10,
    background: '#4A4F54',
    cursor: 'pointer',
    fontSize: 16,
    color: '#ffffff'
  },
  cellCentered: {
    '& .divContent': {
      width: '100%',
      textAlign: 'center'
    }
  },
  moreOptions: {
    '&:hover': {
      backgroundColor: 'rgba(53, 119, 212, 0.32)',
      borderRadius: '4px'
    }
  },
  designName: {
    color: '#0b1829',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  readonly: {
    fontSize: 8,
    color: '#787B80',
    marginLeft: 8
  },
  designType: {
    marginTop: 2,
    color: '#787B80'
  },
  redeemPageType: {
    color: '#989EA4',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  pageCardDetails: {
    color: '#4A4F54',
    fontWeight: 400
  },
  cardName: {
    height: 26,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    lineHeight: 1.63,
    letterSpacing: 'normal',
    color: '#0b1829',
    ...ellipsisStyles
  },
  searchInput: {
    minWidth: 244,
    '& .MuiInputAdornment-positionStart': {
      paddingLeft: 14,
      paddingRight: 0
    },
    '& input': {
      background: 'transparent',
      fontSize: 12,
      color: '#787b80',
      paddingLeft: 4
    }
  },
  searchField: {
    height: 56,
    minWidth: 276
  },
  searchFieldHistory: {
    minWidth: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 8
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 2px',
      paddingRight: 32,
      color: '#45484d'
    }
  },
  stepContainer: {
    paddingTop: '0 !important',
    maxHeight: 'calc(100vh - 292px)',
    overflowY: 'auto',
    ...scrollBar
  },
  listItem: {
    padding: '8px 12px'
  },
  swipeableViews: {
    height: '100%',
    width: '100%',
    '& .react-swipeable-view-container': {
      height: '100%'
    }
  },
  infoModal: {
    width: 480,
    borderRadius: 8,
    background: '#ffffff',
    padding: '15px 21px 32px 24px'
  },
  limitInfoTitles: { marginBottom: 8, marginTop: 8 },
  notEnoughCreditsModalTitle: { marginBottom: 16 },
  notEnoughCreditsModalDivider: { marginBottom: 12, marginTop: 32 },
  notEnoughCreditsModal: {
    width: 600,
    borderRadius: 8,
    background: '#ffffff',
    padding: 24,
    paddingBottom: 12
  },
  deletePageModal: {
    width: 480,
    borderRadius: 8,
    background: '#ffffff',
    padding: 24,
    paddingBottom: 12
  },
  infoIconContainer: {
    marginBottom: 10,
    height: 20,
    width: 20
  },
  infoIconContainerSmall: {
    marginTop: 1,
    height: 20,
    width: 20
  },
  infoIcon: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    cursor: 'pointer'
  },
  wizardFooter: {
    padding: '24px 10px 0px 0px'
  },
  previous: {
    color: '#787B80',
    paddingLeft: 0
  },
  previousIcon: {
    color: '#787B80',
    marginRight: 6
  },
  steps: {
    color: '#989EA4',
    marginTop: 12,
    marginBottom: 4
  },
  stepTitle: {
    color: '#131415',
    fontSize: 24,
    lineHeight: '32px',
    marginBottom: 20
  },
  inputTextMultiline: {
    '& .MuiOutlinedInput-multiline': {
      padding: '14px 16px'
    }
  },
  createUpdatePageTitle: { color: '#0B1829' },
  inputLabel: { marginBottom: 8, cursor: 'default' },
  dataTemplate: {
    borderRadius: 12,
    marginBottom: 20,
    padding: '12px 16px',
    cursor: 'pointer',
    border: ({ selected }) => `1px solid #${selected ? '3577d4' : 'E5E7E8'}`,
    '&:hover': {
      boxShadow: '0 8px 12px 0 rgba(12, 23, 18, 0.05)',
      border: ({ selected }) => `1px solid #${selected ? '3577d4' : 'C5C7C8'}`
    }
  },
  formControl: {
    marginTop: 0,
    marginLeft: 0,
    alignItems: 'flex-start',
    width: '100%',
    '& > div': {
      width: '100%'
    },
    '& .MuiFormLabel-root': {
      marginTop: -6
    }
  },
  selectField: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    '& .MuiPaper-root': {
      height: '320px !important'
    },
    '& .MuiSelect-root': {
      paddingTop: '14px !important',
      paddingBottom: '13px !important'
    }
  },
  allCheckbox: {
    paddingLeft: 0,
    width: 24,
    height: 4,
    color: '#ced1d6',
    '& svg': {
      width: 24,
      height: 24
    },
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#3577d4'
    }
  },
  sameAsLabelCheckBox: {
    marginBottom: 8,
    marginLeft: 216
  },
  infoMessage: {
    maxWidth: 198,
    top: 0
  },
  floatingInfoMessage: {
    maxWidth: 198,
    top: 0,
    position: 'absolute'
  },
  createRedeemBar: {
    width: 620,
    padding: 20
  },
  closeDrawer: {
    height: 24,
    width: 24,
    color: '#989EA4',
    cursor: 'pointer',
    '&:hover': {
      color: '#787E84'
    }
  },
  createRedeemOptionTitle: {
    padding: '8px 0px'
  },
  linkCreate: {
    '&:active ': {
      '& > div': {
        border: '1px solid #3577D4'
      }
    }
  },
  createRedeemOption: {
    border: '1px solid #D6D8DB',
    borderRadius: 12,
    padding: '24px 16px',
    cursor: 'pointer',
    marginTop: 20,
    '&:active': {
      border: '1px solid #3577D4'
    },
    '&:hover': {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12), 0px 12px 24px 2px rgba(0, 0, 0, 0.06)',
      border: '1px solid #989EA4'
    }
  },
  createRedeemOptionDisabled: {
    border: '1px solid #D6D8DB',
    borderRadius: 12,
    padding: '24px 16px',
    cursor: 'pointer',
    marginTop: 20,
    '&:hover': {
      border: '1px solid #D6D8DB',
      cursor: 'default'
    }
  },
  croContent: {
    paddingLeft: 20
  },
  croContentText: {
    marginTop: 8,
    color: '#4A4F54'
  },
  croImageContainer: {
    height: 140,
    width: 140
  },
  croImage: {
    objectFit: 'contain',
    width: '100%'
  },
  crDrawerContent: {
    color: '#131415',
    padding: '0px 20px'
  },
  selectProductsText: {
    color: '#131415',
    marginTop: 4,
    marginBottom: 8,
    '& span': {
      color: '#989EA4'
    }
  },
  deleteProductIcon: {
    '& svg': {
      fill: '#4A4F54',
      width: 22,
      height: 22
    },
    '&:hover': {
      '& svg': {
        fill: '#F44336'
      }
    },
    height: 30,
    width: 30,
    cursor: 'pointer',
    paddingTop: 4,
    background: '#ffffff',
    borderRadius: '50%'
  },
  productSquareImageContainer: {
    padding: 4,
    border: '1px solid #989EA4',
    borderRadius: 8,
    position: 'relative',
    width: 120,
    height: 120,
    overflow: 'hidden',
    '& .product-square-image-container-hover': {
      display: 'none',
      borderRadius: 8,
      background: 'rgba(0,0,0,0.5)',
      position: 'absolute',
      width: 120,
      height: 120,
      marginLeft: -5,
      marginTop: -5
    },
    '&:hover': {
      '& .product-square-image-container-hover': {
        display: 'block'
      }
    }
  },
  deleteProduct: {
    position: 'absolute',
    right: 9,
    top: 9
  },
  productSquareImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain !important'
  },
  addProductSquare: {
    height: 120,
    width: 120,
    border: '1px solid #989EA4',
    borderRadius: 8,
    cursor: 'pointer',
    '& svg': {
      fill: '#4A4F54'
    },
    '&:hover': {
      boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.05)',
      border: '1px solid #686E74'
    }
  },
  productsSelectionPanel: {
    paddingTop: 8,
    paddingBottom: 16
  },
  createRedeemOptionImage: {
    height: '100%',
    objectFit: 'contain'
  },
  radioSelectorPill: {
    border: '1px solid #D6D8DB',
    borderRadius: 12,
    height: 44,
    cursor: 'pointer'
  },
  radioSelectorPillTooltip: {
    zIndex: 1500,
    pointerEvents: 'none',
    maxWidth: 500
  },
  creditBalanceTitle: {
    lineHeight: '24px'
  },
  creditBalance: {
    color: '#2E7D32',
    textAlign: 'right',
    lineHeight: '32px',
    fontSize: '24px'
  },
  creditBalanceWarning: {
    color: '#989EA4',
    textAlign: 'right',
    marginTop: 4
  },
  creditBalanceLink: {
    marginTop: 4,
    color: '#3577D4'
  },
  creditRecomendation: {
    marginTop: 12,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#4A4F54'
  },
  finalDisclaimer: {
    color: '#4A4F54',
    marginBottom: 16,
    '& a': {
      color: '#3577D4',
      textDecoration: 'underline'
    }
  },
  recipientsRedeemed: { color: '#4A4F54', marginBottom: 4 },
  recipientsRedeemedTotal: { color: '#0B1829' },
  recipientsRedeemedTotalSummary: { color: '#125CFF', '& span': { fontSize: 24, fontWeight: 600 } },
  pageRedemptionsBlock: {
    height: 80,
    padding: '0px 16px',
    borderRadius: 4,
    border: '1px solid #EBEEF2',
    // backgroundColor: '#EBF1FB',
    // backgroundImage: 'url("/images/redeem/page-redemptions-block.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '108px 70px',
    backgroundPositionX: 'right',
    backgroundPositionY: 'bottom'
  },
  pageInventoryBlock: {
    height: 80,
    padding: '0px 16px',
    borderRadius: 4,
    border: '1px solid #EBEEF2',
    // backgroundColor: '#F5ECFC',
    // backgroundImage: 'url("/images/redeem/page-inventory-block.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '84px 84px',
    backgroundPositionX: 'right',
    backgroundPositionY: 'bottom'
  },
  availableCreditBlock: {
    height: 80,
    padding: '0px 16px',
    borderRadius: 4,
    border: '1px solid #EBEEF2'
    // backgroundColor: '#E8F5E9'
  },
  shareStatusBlock: {
    height: 80,
    padding: '0px 16px',
    borderRadius: 4,
    border: '1px solid #EBEEF2'
  },
  cardImgContainer: {
    width: 75,
    height: 52,
    position: 'relative'
  },
  cardImage: {
    height: '100%',
    objectFit: 'contain'
  },
  historyLink: {
    color: '#125CFF'
  },
  disclaimerTitleText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 2,
    color: '#989EA4',
    marginTop: 20
  },
  shipmentRedemptionRow: {
    height: 48,
    '& p': {
      color: '#4A4F54'
    },
    '&:hover': {
      '& .small-checkbox': { display: 'inherit !important' }
    }
  },
  customQuestionAActionSection: {
    paddingTop: 24
  },
  customQuestionAdd: {
    height: 40,
    width: 160
  },
  customQuestionRemove: {
    height: 40,
    width: 'fit-content',
    paddingRight: 4
  },
  questionnaireAsk: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 16
  }
});
export default styles;
