import React, { useState } from 'react';
import {
  CircularProgress,
  ClickAwayListener,
  Grid,
  InputAdornment,
  makeStyles,
  Modal,
  Tooltip,
  Drawer,
  withStyles,
  Divider,
  TableHead,
  Checkbox,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  Box,
  TableRow
} from '@material-ui/core';
import { Button, TextField, Typography } from '@swagup-com/components';
import {
  Add,
  Remove,
  CheckCircle,
  CloseRounded,
  CloudUpload,
  Link as LinkIcon,
  DeleteOutlineRounded,
  Edit,
  Warning
} from '@material-ui/icons';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { ChromePicker } from 'react-color';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import Close from '@material-ui/icons/Close';
import isEmpty from 'lodash/isEmpty';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Img, ImgWithHandler } from '../global/ImgUtils';
import { StandardCard } from '../shared/containers/Cards';
import styles from './styles/redeemPagesHome';
import { formatDate, imageSrcSet, joinFields, moneyStr, s3, truncateFileName } from '../../helpers/utils';
import CircularCheckbox from '../pages/orders/requested/common/CircularCheckbox';
import { TableCell } from '../pages/orders/requested/shipments/TableCommon';
import { StylessButton } from '../buttons';
import Select from '../global/Select';
import TableSortBy from './TableSortBy';
import { ellipsisStyles } from '../shared/styles/commonStyles';
import { CustomTooltip } from '../products/commonProductsElements';
import EditField from '../global/EditField';

const useStyles = makeStyles(styles);

const getStatus = active => (active ? 'Active' : 'Inactive');
const getStatusAllowed = allow => (allow ? 'Allowed' : 'Restricted');
const prepare = text => text.toLowerCase().replaceAll(' ', '-');
const getUrlSlugParts = urlSlug => {
  const allWords = urlSlug.split('-');
  const accountId = allWords.splice(0, 1);
  const projectName = allWords.join('-');
  return { accountId, projectName };
};

const getPageLink = ({ urlSlug }) => {
  if (!urlSlug) return '/';
  const parts = getUrlSlugParts(urlSlug);
  return `${process.env.REACT_APP_REDEEM_PAGES_LANDINGS}/${parts.accountId}/${parts.projectName}`;
};

const RedeemPageCard = ({ page, selected, onEdit, isLoading, onDelete }) => {
  const classes = useStyles(selected);
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  return (
    <StandardCard className={classes.card} data-testid={`card-${page.id}`}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="body4RegularInter" className={classes.redeemPageType}>
                {page.collectionOnly ? 'COLLECTION' : 'AUTOSHIP'}
              </Typography>
            </Grid>
            <Grid item>
              <StylessButton onClick={() => setOpen(true)}>
                <Box
                  ref={anchorRef}
                  className={classes.moreOptions}
                  style={open ? { backgroundColor: 'rgba(53, 119, 212, 0.32)', borderRadius: '4px' } : null}
                >
                  <Typography variant="body4RegularInter">•••</Typography>
                </Box>
              </StylessButton>
              <Popper open={open} anchorEl={anchorRef.current} role="menu" transition placement="bottom-end">
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom' }}
                  >
                    <Paper className={classes.redeemCardMenu}>
                      <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <MenuList autoFocusItem={open} id="menu-list-grow">
                          <MenuItem className={classes.redeemCardMenuItem}>
                            <a href={`${getPageLink(page)}/preview`} target="_blank" rel="noreferrer">
                              <Typography variant="body4RegularInter">Preview page</Typography>
                            </a>
                          </MenuItem>
                          <MenuItem onClick={onDelete} className={classes.redeemCardMenuItemDelete}>
                            <Typography variant="body4RegularInter" style={{ color: '#C62828' }}>
                              Delete
                            </Typography>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              {/* <a href={`${getPageLink(page)}/preview`} target="_blank" rel="noreferrer">
                <Typography variant="body4RegularInter" color="primary" className={classes.link}>
                  Visit <LinkIcon className={classes.linkIcon} />
                </Typography>
              </a> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Grid className={classes.imageContainer}>
            <Img src={page.clientImage} alt={page.projectName} className={classes.designImage} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <EditField
                value={page.customProjectName || page.projectName}
                className={classes.redeemPageCardName}
                onEdit={name => onEdit(page.id, name)}
                isLoading={isLoading}
                // data-testid={`editfield-${product.id}`}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body3MediumInter" className={classes.pageCardDetails} style={{ marginBottom: 2 }}>
                Status:{' '}
                <span style={{ color: page.isActive ? '#2E7D32' : '#989EA4', fontWeight: 500 }}>
                  {getStatus(page.isActive)}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body3MediumInter" className={classes.pageCardDetails}>
                Date Created: <span>{formatDate(page.createdDate)}</span>
              </Typography>
            </Grid>
            {/* <Grid item>
              <Tooltip title="Last date modified" width={190} color="blue">
                <p className={classes.designType} style={{ opacity: 0.5 }}>
                  <span>{page.lastModified}</span>
                </p>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Button
              size="small"
              variant="primary"
              component={Link}
              to={`/redeem-history/${page.urlSlug}`}
              fullWidth
              className={classes.butonSizeSmall}
            >
              View Details
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </StandardCard>
  );
};

const ProductCard = ({ product, selected, onSelect, onSetQuanity, current }) => {
  const classes = useStyles({ selected });

  return (
    <StandardCard className={classes.productCard} data-testid={`card-${product.id}`}>
      <CircularCheckbox
        className={classes.productCheckbox}
        checked={selected}
        onChange={onSelect}
        inputProps={{ 'aria-label': product.name, 'aria-checked': selected }}
      />
      <Grid container style={{ padding: '0px 8px' }}>
        <Grid item xs={12} container justifyContent="center">
          <Grid className={classes.productImageContainer}>
            <Img src={product.image} alt={product.name} className={classes.productImage} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2SemiBoldInter" className={classes.designName} style={{ textAlign: 'center' }}>
            {product.name}
          </Typography>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              {product.stock
                .filter(stock => stock.quantity > 0)
                .map(stock => (
                  <Grid key={stock.size.id} item>
                    <Typography variant="body4RegularInter" style={{ color: stock.quantity ? 'inherit' : '#C6CACD' }}>
                      {`${stock.size.name}: ${stock.quantity}`}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.quantitySection}>
        <Grid container alignItems="center">
          <Grid item style={{ height: 16 }}>
            <Button
              variant="text"
              disabled={!current?.quantity}
              style={{ height: 16 }}
              onClick={() => onSetQuanity(current, current.quantity - 1)}
            >
              <Remove className={classes.linkIcon} style={{ margin: 0 }} />
            </Button>
          </Grid>
          <Grid item xs>
            <p style={{ textAlign: 'center' }}>{current?.quantity || 0}</p>
          </Grid>
          <Grid itemstyle={{ height: 16 }}>
            <Button
              variant="text"
              disabled={!current}
              style={{ height: 16 }}
              onClick={() => onSetQuanity(current, (current?.quantity || 0) + 1)}
            >
              <Add className={classes.linkIcon} style={{ margin: 0 }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </StandardCard>
  );
};

const PresetTemplate = ({ selected, onSelect, name, subtext, image }) => {
  const classes = useStyles({ selected });

  const handleKeyDown = e => {
    if (e.keyCode === 13) onSelect();
  };

  const containerProps = { role: 'button', tabIndex: 0, onClick: onSelect, onKeyDown: handleKeyDown };

  return (
    <div {...containerProps} className={classes.dataTemplate}>
      <Grid container alignItems="center">
        <Grid item>
          <div style={{ height: 54, width: 54, background: '#F5F5F6', borderRadius: 12 }}>
            <img src={`/images/redeem/${image}.png`} alt={name} style={{ objectFit: 'scale-down', width: '100%' }} />
          </div>
        </Grid>
        <Grid item xs>
          <Grid container alignItems="center" style={{ paddingLeft: 20 }}>
            <Grid item>
              <Typography variant="h6SemiBoldInter" style={{ color: '#131415' }}>
                {name}
              </Typography>
              <Typography variant="body3RegularInter" style={{ color: '#787B80' }}>
                {subtext}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid item style={{ height: 24 }}>
            {selected && <CheckCircle className={classes.checkedIcon} />}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const SelectedProductName = ({ selectedProduct }) => {
  if (isEmpty(selectedProduct)) return <p style={{ width: 56 }}>{' - '}</p>;
  return <span>{selectedProduct[0].name}</span>;
};

const SmallCheckbox = withStyles({
  root: {
    padding: 0,
    height: 14,
    width: 14,
    color: '#989EA4',
    '&$checked': { color: '#3577d4' },
    '& .MuiSvgIcon-root': {
      height: 14,
      width: 14
    }
  },
  checked: {}
})(Checkbox);

const shipmentStatuses = {
  Success: 'Success',
  Warning: 'Warning',
  Error: 'Error'
};
const getShippingStatus = status => {
  switch (status) {
    case 'Scheduled':
    case 'On Its Way':
    case 'Delivered':
    case 'Redeemed':
      return shipmentStatuses.Success;
    case 'Out Of Stock':
    case 'Insufficient Credit':
    case 'Unexpected Error':
    case 'Pending Production':
    case 'Unredeemed':
      return shipmentStatuses.Warning;
    case 'Failure':
    case 'Return To Sender':
    case 'Invalid Address':
    case 'Cancelled':
    case 'Canceled':
      return shipmentStatuses.Error;
    default:
      return shipmentStatuses.Warning;
  }
};

const ShippingNote = ({ shipping, classes }) => {
  const note = ['Failure', 'On Its Way', 'Delivered'].includes(shipping.status) ? (
    <span>
      {shipping.trackingUrl && shipping.trackingNumber ? (
        <>
          See{' '}
          <a target="_blank" rel="noopener noreferrer" className={classes.link} href={shipping.trackingUrl}>
            tracking info
          </a>
        </>
      ) : (
        'No tracking available'
      )}
    </span>
  ) : (
    <>
      {shipping.status === 'Scheduled'
        ? `${
            dayjs(shipping.shippingDate).isAfter('01/01/2022')
              ? `Shipping Date: ${dayjs(shipping.shippingDate).format('MM/DD/YYYY')}`
              : ''
          }`
        : shipping.note}
    </>
  );
  return (
    <Typography variant="body4RegularInter" className={classes.shippingStatusDescription}>
      {note}
    </Typography>
  );
};

const attachedShippingNote = shipping => {
  switch (shipping.status) {
    case 'Out Of Stock':
      return 'Order more inventory';
    case 'Insufficient Credit':
      return 'Reload credit balance';
    case 'Unexpected Error':
      return 'Try again or contact customer support';
    default:
      return shipping.note;
  }
};

const attachShippingNote = shipping => ({ ...shipping, note: attachedShippingNote(shipping) });

const ShippingStatus = ({ shipping = {}, classes }) => (
  <div className={classes.shippingStatusContainer}>
    <Grid container alignItems="center">
      <Grid item>
        {shipping.status && <div className={classes[`shipping${getShippingStatus(shipping.status)}`]} />}
      </Grid>
      <Grid item xs>
        <Typography variant="body3RegularInter" className={classes.shippingStatusText}>
          {shipping.status}
        </Typography>
      </Grid>
    </Grid>
    <Grid container alignItems="center">
      <Grid item xs style={{ paddingLeft: 18 }}>
        <ShippingNote shipping={attachShippingNote(shipping)} classes={classes} />
      </Grid>
    </Grid>
  </div>
);

export const CustomTableRow = withStyles({
  root: {
    borderBottom: '1px solid #E5E7E8',
    backgroundColor: '#ffffff',
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
    '&.Mui-selected, &.Mui-selected:hover': { backgroundColor: 'rgba(53, 119, 212, 0.08) !important' }
  }
})(TableRow);

const RedemptionRowAutoShip = ({
  classes,
  redemption,
  isSelected,
  toogleSelectedItems,
  onRetryShipment,
  onDeleteRedemption,
  onQuestionnaireAnswers,
  onCancelShipment,
  onOpenShipmentDetails,
  onSendEmail,
  selectedShipmentStatus = [],
  isCollection,
  triggerCopyNotifications,
  uniqueUrl,
  exportAction
}) => {
  const [open, setOpen] = React.useState(false);
  const [copyNotification, setCopyNotification] = useState('Click to copy');
  const anchorRef = React.useRef(null);

  const isUnredeemed = redemption.contactId < 0;
  const disableRetry = isUnredeemed || redemption.shipment?.shipmentId > 0;
  const disableViewShipmentDetails = redemption.shipment?.shipmentId < 1;
  const disableDelete = redemption.shipment?.shipmentId > 0;
  const disableCancel = !['Pending Production', 'Scheduled'].includes(redemption.shipment?.status);
  const showWarningSign = ['Out Of Stock', 'Insufficient Credit', 'Unexpected Error'].includes(
    redemption.shipment?.status
  );
  const disableSendEmail = redemption.contactId > 0 || isEmpty(redemption.emailAddress);

  const isFailed = id => {
    return !!selectedShipmentStatus.find(i => i.failedRedemptionId === id);
  };
  const openActionlist = e => {
    setOpen(true);
    e.stopPropagation();
  };

  const copyToClipboard = e => {
    navigator.clipboard.writeText(uniqueUrl);
    if (open) {
      triggerCopyNotifications('Link copied to clipboard');
      setOpen(false);
    } else setCopyNotification('Link copied to clipboard');

    setTimeout(() => {
      setCopyNotification('Click to copy');
    }, 3000);

    e.stopPropagation();
  };

  const background = open || isSelected ? 'rgba(53, 119, 212, 0.08)' : undefined;

  const shipmentMenu = [
    {
      text: 'Shipping details',
      action: () => onOpenShipmentDetails(redemption.shipment.shipmentId),
      disabled: disableViewShipmentDetails
    },
    {
      text: 'Cancel Shipment',
      action: () => {
        onCancelShipment(redemption);
        setOpen(false);
      },
      disabled: disableCancel
    },
    {
      text: 'Retry Shipment',
      action: () => {
        onRetryShipment(redemption);
        setOpen(false);
      },
      disabled: disableRetry
    }
  ];

  const { customQuestionsRedeem } = useFlags();

  const sizeOrCost = isCollection ? redemption.apparelSize || '-' : moneyStr(redemption.shipment?.cost || 0);

  return (
    <CustomTableRow
      onClick={() => !open && toogleSelectedItems(redemption)}
      onMouseLeave={() => setOpen(false)}
      className={classes.shipmentRedemptionRow}
      style={{
        background: isFailed(redemption.id) ? '#FFCDD2' : background,
        cursor: 'pointer'
      }}
    >
      <TableCell scope="row" width="16px">
        <SmallCheckbox
          className="small-checkbox"
          checked={isSelected}
          style={{ display: isSelected ? 'inherit' : 'none' }}
        />
      </TableCell>
      <TableCell style={{ position: 'relative', overflowX: 'visible' }}>
        <Grid container alignItems="center">
          <Grid item style={{ height: 12 }}>
            {showWarningSign && <Warning className={classes.warningSign} />}
          </Grid>
          <Grid item xs style={{ cursor: 'text' }}>
            {isUnredeemed ? (
              <div>
                <Typography variant="body3MediumInter">
                  {redemption.emailAddress
                    ? `${joinFields([redemption.firstName, redemption.lastName], ' ')} / ${redemption.emailAddress}`
                    : 'Unique url'}
                </Typography>
                <StylessButton onClick={copyToClipboard} style={{ textAlign: 'left', maxWidth: 300 }}>
                  <CustomTooltip title={copyNotification} disableHoverListener={open}>
                    <Typography
                      variant="body4RegularInter"
                      className={classes.lightText}
                      style={{
                        ...ellipsisStyles,
                        textDecoration: 'underline',
                        direction: 'rtl',
                        marginLeft: '-3px',
                        textAlign: 'left'
                      }}
                    >
                      {uniqueUrl}
                    </Typography>
                  </CustomTooltip>
                </StylessButton>
              </div>
            ) : (
              <>
                <Typography variant="body3MediumInter">{`${joinFields(
                  [redemption.firstName, redemption.lastName],
                  ' '
                )}`}</Typography>
                <Typography variant="body4RegularInter" className={classes.lightText}>
                  {redemption.emailAddress}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell className={classes.cellCentered}>
        <ShippingStatus
          shipping={{
            ...redemption.shipment,
            status:
              // isEmpty(redemption.uniqueUrl) &&
              // isEmpty(redemption.country) &&
              // isEmpty(redemption.addressLine1) &&
              // redemption.contactId < 0 &&
              // (!isEmpty(redemption.firstName) || !isEmpty(redemption.lastName))
              //   ? 'Redeemed' :
              redemption.shipment?.status,
            note: isUnredeemed ? (
              <span>
                Download <StylessButton onClick={exportAction}>CSV</StylessButton>
              </span>
            ) : (
              redemption.shipment.note
            )
          }}
          classes={classes}
        />
      </TableCell>
      <TableCell className={classes.cellCentered}>{isUnredeemed ? '' : sizeOrCost}</TableCell>
      <TableCell className={classes.cellCentered} style={{ ...ellipsisStyles, maxWidth: '282px' }}>
        {isUnredeemed ? undefined : <SelectedProductName selectedProduct={redemption.selectedProduct} />}
      </TableCell>
      <TableCell className={classes.cellCentered}>
        {redemption.redeemedDate ? formatDate(redemption.redeemedDate) : 'XX/XX/XX'}
      </TableCell>
      <TableCell className={classes.cellCentered}>
        <StylessButton onClick={e => openActionlist(e)}>
          <Box
            ref={anchorRef}
            className={classes.moreOptions}
            style={open ? { backgroundColor: 'rgba(53, 119, 212, 0.32)', borderRadius: '4px' } : null}
          >
            <Typography variant="body4RegularInter">•••</Typography>
          </Box>
        </StylessButton>
        <Popper open={open} anchorEl={anchorRef.current} role="menu" transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.redemptionMenu}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    {isUnredeemed && (
                      <MenuItem className={classes.redemptionMenuItem} onClick={copyToClipboard}>
                        <Typography variant="body4RegularInter">Copy link</Typography>
                      </MenuItem>
                    )}
                    <MenuItem
                      className={classes.redemptionMenuItem}
                      disabled={!redemption.contactId || redemption.contactId < 0 || isUnredeemed}
                    >
                      <Link to={`/contact-details/${redemption.contactId}`}>
                        <Typography variant="body4RegularInter">Contact Information</Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem
                      className={classes.redemptionMenuItem}
                      onClick={() => onSendEmail(redemption)}
                      disabled={disableSendEmail}
                    >
                      <Typography variant="body4RegularInter">Send Email</Typography>
                    </MenuItem>
                    {!isCollection &&
                      shipmentMenu.map(({ text, action, disabled }) => (
                        <MenuItem
                          key={text}
                          className={classes.redemptionMenuItem}
                          disabled={disabled}
                          onClick={action}
                        >
                          <Typography variant="body4RegularInter">{text}</Typography>
                        </MenuItem>
                      ))}
                    {customQuestionsRedeem && redemption.customQuestions?.length > 0 && (
                      <MenuItem
                        onClick={() => onQuestionnaireAnswers(redemption)}
                        className={classes.redemptionMenuItem}
                      >
                        <Typography variant="body4RegularInter">Questionnaire Answers</Typography>
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => onDeleteRedemption(redemption)}
                      className={classes.redemptionMenuItem}
                      disabled={disableDelete}
                    >
                      <Typography variant="body4RegularInter">Delete</Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </TableCell>
    </CustomTableRow>
  );
};

const RedemptionRow = ({
  redemption,
  isCollection,
  customAction,
  onRetryShipment,
  onDeleteRedemption,
  onQuestionnaireAnswers,
  toogleSelectedItems,
  onCancelShipment,
  onOpenShipmentDetails,
  isSelected,
  selectedShipmentStatus,
  triggerCopyNotifications,
  onSendEmail,
  uniqueUrl,
  exportAction
}) => {
  const classes = useStyles();
  return (
    <RedemptionRowAutoShip
      classes={classes}
      redemption={redemption}
      customAction={customAction}
      onRetryShipment={onRetryShipment}
      toogleSelectedItems={toogleSelectedItems}
      onCancelShipment={onCancelShipment}
      onOpenShipmentDetails={onOpenShipmentDetails}
      onDeleteRedemption={onDeleteRedemption}
      onQuestionnaireAnswers={onQuestionnaireAnswers}
      onSendEmail={onSendEmail}
      isSelected={isSelected}
      selectedShipmentStatus={selectedShipmentStatus}
      isCollection={isCollection}
      triggerCopyNotifications={triggerCopyNotifications}
      uniqueUrl={uniqueUrl}
      exportAction={exportAction}
    />
  );
};

const TableEmptyState = ({ isSearching, imageSrc }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.emptyContainer}>
      <div className={imageSrc ? classes.tableEmptyStateImageBig : classes.tableEmptyStateImage}>
        <img
          src={imageSrc || '/images/redeem/search.png'}
          srcSet={!imageSrc && imageSrcSet('/images/redeem/search.png')}
          className={classes.emptyImg}
          alt="No recipients found"
        />
      </div>
      <Typography variant="h5SemiBoldInter" className={classes.emptytitle}>
        {isSearching ? 'No submissions found' : 'No submissions yet'}
      </Typography>
      <Typography variant="body1RegularInter" className={classes.emptySubtitle}>
        {isSearching ? 'Remove filters to see all the shipments.' : 'Come back again later, we’ll keep an eye out.'}
      </Typography>
    </Grid>
  );
};

const TableHeaderCollection = ({ classes }) => (
  <TableHead>
    <CustomTableRow>
      <TableCell scope="row" width="30%">
        NAME
      </TableCell>
      <TableCell className={classes.cellCentered} width="30%">
        PRODUCT SELECTED
      </TableCell>
      <TableCell className={classes.cellCentered}>SIZE</TableCell>
      <TableCell className={classes.cellCentered}>
        <Grid container alignItems="center">
          <Grid item xs={2} />
          <Grid item xs>
            DATE ADDED
          </Grid>
          <Grid item xs={2}>
            <TableSortBy />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell className={classes.cellCentered}>ACTION</TableCell>
    </CustomTableRow>
  </TableHead>
);

const TableHeaderAutoShip = ({ classes, toogleSelectAll, allSelected, isCollection }) => (
  <TableHead>
    <CustomTableRow>
      <TableCell scope="row" width="16px">
        <SmallCheckbox checked={allSelected} onChange={toogleSelectAll} />
      </TableCell>
      <TableCell scope="row" width="25%">
        NAME
      </TableCell>
      <TableCell width="25%">STATUS</TableCell>
      <TableCell className={classes.cellCentered}>{isCollection ? 'SIZE' : 'SHIPPING COST'}</TableCell>
      <TableCell className={classes.cellCentered}>PRODUCT SELECTED</TableCell>
      <TableCell className={classes.cellCentered}>
        <Grid container alignItems="center" style={{ minWidth: 110 }}>
          <Grid item xs={2} />
          <Grid item xs>
            DATE ADDED
          </Grid>
          <Grid item>
            <TableSortBy />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell scope="row" width="32px" />
    </CustomTableRow>
  </TableHead>
);

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 12,
      '& input': {
        padding: '14px 16px',
        fontFamily: 'Helvetica'
      },
      '& fieldset': {
        border: '1px solid #D6D8DB',
        borderRadius: 12
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #3577D4'
      }
    }
  }
})(TextField);

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
      allowNegative={false}
      isAllowed={({ value }) => value.charAt(0) !== '0' && value.charAt(0) !== '.'}
    />
  );
});

const useModalStyles = makeStyles({
  text: {
    fontFamily: 'Gilroy-SemiBold',
    marginTop: 56,
    marginBottom: 24,
    textAlign: 'center',
    color: '#0b1829',
    fontSize: 24
  },
  closeDrawer: {
    height: 24,
    width: 24,
    color: '#989EA4',
    cursor: 'pointer',
    '&:hover': {
      color: '#787E84'
    }
  },
  subtitle: {
    fontFamily: 'Gilroy',
    color: '#0b1829',
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 56
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'transparent',
    padding: 32
  },
  modalContent: {
    position: 'relative',
    padding: '32px 45px 36px 45px',
    borderRadius: 32,
    width: 520,
    backgroundColor: '#fafafa',
    outline: 'none',
    border: 'transparent'
  },
  deleteRedemptioModal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    border: 'transparent',
    padding: 0
  },
  deleteRedemptioModalContent: {
    position: 'relative',
    padding: 0,
    borderRadius: 8,
    width: 600,
    backgroundColor: '#ffffff',
    outline: 'none',
    border: 'transparent'
  },
  deleteRedemptioModalInfoSection: {
    padding: 24
  },
  deleteRedemptioModalActionSection: {
    padding: '12px 24px',
    borderTop: '1px solid #E5E7E8'
  },
  deleteRedemptioModalInfoText: { marginTop: 16 },
  shareModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    border: 'transparent',
    padding: 32,
    paddingTop: 212
  },
  shareModalContent: {
    position: 'relative',
    padding: 0,
    borderRadius: 8,
    width: 488,
    backgroundColor: '#fafafa',
    outline: 'none',
    border: 'transparent',
    paddingBottom: 12
  },
  copyLink: {
    color: '#3577d4',
    paddingLeft: 0,
    height: 40,
    '& p': {
      color: '#3577d4',
      marginTop: 2,
      marginLeft: 4
    },
    '&:hover': {
      '& p': {
        textDecoration: 'underline'
      }
    },
    '&:focus': {
      '& p': {
        textDecoration: 'underline'
      }
    },
    '&:active': {
      color: '#1C4783',
      '& p': {
        color: '#1C4783'
      }
    },
    '&:disabled': {
      color: '#B7BBBF',
      '& p': {
        color: '#B7BBBF'
      }
    }
  },
  shareModalInfo: {
    width: '100%',
    padding: 24,
    paddingBottom: 32,
    borderBottom: '1px solid #E8EEF4'
  },
  shareModalActions: {
    width: '100%',
    padding: '12px 24px',
    paddingBottom: 2
  },
  shareModalProjectName: {
    paddingBottom: 2
  },
  notification: {
    border: '1px solid #4CAF50',
    background: '#E8F5E9',
    borderRadius: 4,
    padding: '6px 12px',
    paddingBottom: 2
  },
  notificationIcon: {
    color: '#2E7D32'
  },
  notificationText: {
    marginBottom: 4,
    marginLeft: 4
  }
});

const RedeemPageDeleteModal = ({ open, onClose, onDelete }) => {
  const classes = useModalStyles();

  return (
    <Modal title="Redemption Delete Modal" open={open} onClose={onClose} className={classes.modal}>
      <Grid container direction="column" alignItems="center" className={classes.modalContent}>
        <Grid item>
          <p className={classes.text}>Are you sure you want to delete this SwagDrop?</p>
          <p className={classes.subtitle}>This action will remove also the associated landing page.</p>
        </Grid>
        <Grid item>
          <Button size="small" variant="alert" onClick={onDelete} style={{ width: 256 }}>
            Delete SwagDrop
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

const RedemptionDeleteModal = ({ open, onClose, onDelete, redemption = {} }) => {
  const classes = useModalStyles();
  const getRedemtionName = () => {
    const sampeRedemtion = redemption[0] || redemption;
    if (_.isArray(redemption) && redemption.length > 1) return 'submissions';
    return joinFields([sampeRedemtion.firstName, sampeRedemtion.lastName], ' ');
  };

  return (
    <Modal title="Redemption Delete Modal" open={open} onClose={onClose} className={classes.deleteRedemptioModal}>
      <Grid container className={classes.deleteRedemptioModalContent}>
        <Grid item xs={12} className={classes.deleteRedemptioModalInfoSection}>
          <Typography variant="body2SemiBoldInter">{`Delete ${getRedemtionName()}?`}</Typography>
          <Typography variant="body3RegularInter" className={classes.deleteRedemptioModalInfoText}>
            {_.isArray(redemption) && redemption.length > 1
              ? 'You cannot restore deleted submissions'
              : 'You cannot restore a deleted submission'}
            .
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end" className={classes.deleteRedemptioModalActionSection}>
          <Grid item>
            <Button size="small" variant="text" onClick={onClose} style={{ width: 84, marginRight: 8 }}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button size="small" variant="primary" onClick={onDelete} style={{ width: 84 }}>
              Delete
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

const RedemptionQuestionnaire = ({ open, onClose, redemption = {} }) => {
  const classes = useModalStyles();
  return (
    <Modal title="Redemption Questionnaire" open={open} onClose={onClose} className={classes.deleteRedemptioModal}>
      <Grid container className={classes.deleteRedemptioModalContent}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Close
              className={classes.closeDrawer}
              style={{ position: 'absolute', right: 8, top: 8 }}
              onClick={onClose}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.deleteRedemptioModalInfoSection}>
          <Typography variant="body2SemiBoldInter">{`Questionnaire Answer${
            redemption.customQuestions?.length > 0 ? 's' : ''
          }`}</Typography>
          <Box style={{ paddingBottom: 12 }}>
            {redemption.customQuestions?.map(q => (
              <Typography
                key={`${q.question}: ${q.answer}`}
                variant="body3RegularInter"
                className={classes.deleteRedemptioModalInfoText}
              >
                <strong>{q.question}:</strong> {q.answer}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

const Notification = ({ show, message, classes }) =>
  show && (
    <Grid container alignItems="center" className={classes.notification}>
      <Grid item>
        <CheckCircle className={classes.notificationIcon} />
      </Grid>
      <Grid item>
        <Typography variant="body3MediumInter" className={classes.notificationText}>
          {message}
        </Typography>
      </Grid>
    </Grid>
  );

const getFileName = src => src?.substring(src.lastIndexOf('/') + 1);

const ImageSelector = ({ imageName, selected, onSelect, url, artworkLoader, property, handleFileUpload }) => {
  const classes = useStyles({ selectedDefaultLogo: selected });
  return (
    <>
      {selected && (
        <Grid container alignItems="center" className={classes.logoSelector} onClick={() => onSelect(selected)}>
          <Grid item xs>
            <Typography variant="body3RegularInter" className={classes.lsImageName}>
              {truncateFileName(getFileName(imageName), 40)}
            </Typography>
          </Grid>
          <Grid item style={{ height: 20 }}>
            <CloseRounded className={classes.closeIcon} />
          </Grid>
        </Grid>
      )}
      <div>
        <ImageUploadSection
          classes={classes}
          artworkLoader={artworkLoader}
          url={url}
          property={property}
          handleFileUpload={handleFileUpload}
          selected={selected}
        />
      </div>
    </>
  );
};

const SimpleTooltip = withStyles(() => ({
  tooltip: {
    padding: 0,
    pointerEvents: 'all',
    boxShadow: 'none',
    margin: '0px 0px 2px 0px'
  }
}))(Tooltip);

const RedeemCustomSelectField = withStyles({
  root: {
    '& fieldset': { borderRadius: 12 }
  }
})(props => <Select {...props} />);

const ColorInput = ({ value, onChange, inputClass }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(prev => !prev);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <SimpleTooltip
      placement="top-start"
      open={displayColorPicker}
      title={
        <ClickAwayListener onClickAway={handleClose}>
          <ChromePicker color={value} onChange={color => onChange(color.hex)} />
        </ClickAwayListener>
      }
    >
      <div>
        <CustomTextField
          size="small"
          value={value}
          onChange={({ target }) => onChange(target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" onClick={handleClick} style={{ marginRight: '0px' }}>
                <div
                  style={{
                    height: 16,
                    width: 16,
                    borderRadius: 8,
                    border: '1px solid #E5E7E8',
                    cursor: 'pointer',
                    background: value
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Edit onClick={handleClick} style={{ height: 16, width: 16, cursor: 'pointer' }} />
              </InputAdornment>
            )
          }}
          className={inputClass}
        />
      </div>
    </SimpleTooltip>
  );
};

const FeaturedLink = ({ to, disabled, children, className }) =>
  disabled ? (
    <div>{children}</div>
  ) : (
    <Link to={to} className={className}>
      {children}
    </Link>
  );

const CreateRedeemOption = ({ image, header, text, classes, option, disabled, onClose }) => (
  <FeaturedLink to={`/redeem-pages-create?option=${option}`} disabled={disabled} className={classes.linkCreate}>
    <Grid
      container
      alignItems="center"
      className={disabled ? classes.createRedeemOptionDisabled : classes.createRedeemOption}
      onClick={onClose}
    >
      <Grid item className={classes.croImageContainer}>
        <img src={image} alt={header} className={classes.createRedeemOptionImage} />
      </Grid>
      <Grid item xs className={classes.croContent}>
        <Typography variant="h6SemiBoldInter" style={{ color: disabled ? '#989EA4' : undefined }}>
          {header}
        </Typography>
        <Typography
          variant="body2RegularInter"
          className={classes.croContentText}
          style={{ color: disabled ? '#B7BBBF' : undefined }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  </FeaturedLink>
);

const CreateRedeemOptionsDrawer = ({ open, onClose, classes }) => (
  <Drawer anchor="right" open={open} onClose={onClose} classes={{ paper: classes.createRedeemBar }}>
    <Grid container justifyContent="flex-start">
      <Grid item>
        <Close className={classes.closeDrawer} onClick={onClose} />
      </Grid>
    </Grid>
    <Grid container className={classes.crDrawerContent}>
      <Grid item>
        <Typography variant="h5SemiBoldInter" className={classes.createRedeemOptionTitle}>
          Select a redeem page
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CreateRedeemOption
          option="collection"
          image="/images/redeem/collection.png"
          header="Collection"
          text="Gather contact and sizing information about your recipients. Often used before placing your swag order."
          classes={classes}
          onClose={onClose}
        />
        <CreateRedeemOption
          option="auto-ship"
          image="/images/redeem/auto-ship-on.png"
          header="Autoship"
          text="Automatically ship products from your inventory to recipients once they fill out their information. Often used after you have placed an order."
          classes={classes}
          onClose={onClose}
        />
      </Grid>
      <Grid item>
        <Typography variant="body3RegularInter" className={classes.disclaimerTitleText}>
          *Must have product(s) in your inventory in order to launch autoship.
        </Typography>
      </Grid>
    </Grid>
  </Drawer>
);

const ProductSquare = ({ product, onDelete, onAdd, classes }) => {
  const customAdd = ({ target }) => target.id === 'square-container' && onAdd();
  return (
    <Grid item xs={3}>
      <Grid container justifyContent="center">
        <Grid item>
          <StylessButton onClick={customAdd}>
            <div className={classes.productSquareImageContainer}>
              <div id="square-container" className="product-square-image-container-hover">
                <StylessButton onClick={() => onDelete(product)} className={classes.deleteProduct}>
                  <div className={classes.deleteProductIcon}>
                    <DeleteOutlineRounded />
                  </div>
                </StylessButton>
              </div>

              <ImgWithHandler
                src={product.imageUrl}
                width={256}
                height={256}
                alt={product.name}
                className={classes.productSquareImage}
              />
            </div>
          </StylessButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AddProductSquare = ({ classes, onAdd }) => (
  <Grid item xs={3}>
    <Grid container justifyContent="center">
      <Grid item>
        <StylessButton onClick={onAdd}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            onAdd={onAdd}
            className={classes.addProductSquare}
          >
            <Grid item>
              <Add />
            </Grid>
          </Grid>
        </StylessButton>
      </Grid>
    </Grid>
  </Grid>
);

const ProductsSelectionPanel = ({ products, onAdd, onDelete, limit, classes }) => (
  <Grid container justifyContent="center" className={classes.productsSelectionPanel}>
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {products.map(product => (
          <ProductSquare key={product.id} product={product} onDelete={onDelete} onAdd={onAdd} classes={classes} />
        ))}
        {products.length < limit && <AddProductSquare classes={classes} onAdd={onAdd} />}
      </Grid>
    </Grid>
  </Grid>
);

const FileUploadZone = ({ handleFileUpload, children, disabled, renderComponent }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileUpload,
    accept: 'image/gif, image/jpg, image/jpeg, image/png, .svg',
    maxSize: 5000000,
    multiple: false
  });
  return (
    <div {...getRootProps()} style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
      <input {...getInputProps()} disabled={disabled} />
      {renderComponent ? renderComponent({ isDragActive }) : children}
    </div>
  );
};

const ImageUploadSection = ({ classes, url, handleFileUpload, property, artworkLoader, selected }) => {
  const [error, setError] = useState(false);
  const handleBorder = () => {
    if (error) return '1px dashed #F44336';
    if (selected) return '1px dashed #cdcfd5 &:hover';
    return undefined;
  };
  const RenderedImage = () =>
    url ? (
      <div style={{ width: 56, height: 48, position: 'relative  ' }}>
        <img src={url} alt={property} style={{ objectFit: 'contain', height: '100%', maxWidth: '100%' }} />
      </div>
    ) : (
      <CloudUpload style={{ width: 48, height: 32, color: '#3577d4' }} />
    );

  return (
    <FileUploadZone handleFileUpload={async files => setError(await handleFileUpload(files, property))}>
      <div className={classes.uploadRegion} style={{ border: handleBorder() }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={3}>
            <Grid container justifyContent="center">
              <Grid item>
                {artworkLoader.includes(property) ? (
                  <CircularProgress style={{ float: 'left', width: 32, height: 32, marginLeft: 16 }} />
                ) : (
                  <RenderedImage />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography variant="subtitle3SemiBoldInter" className={classes.dragDrop}>
                  Drag and drop to upload or <span>choose file</span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body4RegularInter" className={classes.preferedImageTypes}>
                  Accepted file types: .PNG, .GIF, .JPEG, .JPG, .SVG
                </Typography>
                <Typography variant="body4RegularInter" className={classes.preferedImageTypes}>
                  Max file size: 5MB
                </Typography>
                {error && (
                  <Typography variant="body4RegularInter" className={classes.preferedImageError}>
                    Your file could not be uploaded. Please check the accepted file types and file size and try again
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </FileUploadZone>
  );
};

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 0,
      padding: 0
    }
  },
  content: {
    display: 'block',
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {
    margin: 0,
    padding: 0
  }
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 56,
    margin: 0,
    padding: 0,
    '&$expanded': {
      minHeight: 56,
      margin: 0,
      padding: 0
    }
  },
  content: {
    display: 'block',
    margin: 0,
    padding: 0,
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {
    margin: 0,
    padding: 0
  }
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    padding: 0
  }
})(MuiAccordionDetails);

const PageRedemptionsBlock = ({ classes, totalRedemptions }) => (
  <Grid container className={classes.pageRedemptionsBlock} alignItems="center">
    <Grid item>
      <Typography variant="body4RegularInter" className={classes.recipientsRedeemedTotal}>
        Recipients Redeemed
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body4RegularInter" className={classes.recipientsRedeemedTotalSummary}>
        <span>{totalRedemptions}</span>
      </Typography>
    </Grid>
  </Grid>
);

const PageHistoryBlock = ({ classes, title, value }) => (
  <Grid container className={classes.pageRedemptionsBlock} alignItems="center">
    <Grid item>
      <Typography variant="body4RegularInter" className={classes.recipientsRedeemedTotal}>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body4RegularInter" className={classes.recipientsRedeemedTotalSummary}>
        <span>{value}</span>
      </Typography>
    </Grid>
  </Grid>
);

const PageInventoryBlock = ({ classes, totalProducts, onViewDetails }) => (
  <Grid container className={classes.pageInventoryBlock} alignItems="center">
    <Grid item xs style={{ paddingRight: 16 }}>
      <Typography variant="body4RegularInter" className={classes.recipientsRedeemedTotal}>
        Inventory
      </Typography>
    </Grid>
    <Grid item style={{ paddingLeft: 16 }}>
      <StylessButton onClick={onViewDetails}>
        <Typography variant="body4MediumInter" className={classes.historyLink}>
          View Details
        </Typography>
      </StylessButton>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body4RegularInter" className={classes.recipientsRedeemedTotalSummary}>
        <span>{totalProducts}</span> Products
      </Typography>
    </Grid>
  </Grid>
);

const AvailableCreditBlock = ({ classes, creditBalance, onReload }) => (
  <Grid container className={classes.availableCreditBlock} alignItems="center">
    {/* <Grid item>
      <div className={classes.cardImgContainer}>
        <img src="/images/redeem/card.png" alt="card" className={classes.cardImage} />
      </div>
    </Grid> */}
    <Grid item xs={12}>
      <Grid container justifyContent="center">
        <Grid item xs>
          <Typography variant="body4RegularInter" className={classes.recipientsRedeemedTotal}>
            Credit Balance
          </Typography>
        </Grid>
        <Grid item>
          <StylessButton onClick={onReload}>
            <Typography variant="body4RegularInter" className={classes.historyLink}>
              Reload
            </Typography>
          </StylessButton>
        </Grid>
      </Grid>
    </Grid>
    {/* <Divider orientation="vertical" style={{ height: 52 }} /> */}
    <Grid item xs={12}>
      <Typography variant="body2SemiBoldInter" className={classes.recipientsRedeemedTotalSummary}>
        <span>{moneyStr(creditBalance)}</span>
      </Typography>
    </Grid>
  </Grid>
);

const ShareStatusBlock = ({ classes, isActive }) => (
  <Grid container className={classes.shareStatusBlock} alignItems="center">
    <Grid item>
      <Typography variant="body4RegularInter" className={classes.recipientsRedeemedTotal}>
        Status
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body1SemiBoldInter" style={{ color: isActive ? '#4CAF50' : '#989EA4' }}>
        {getStatus(isActive)}
      </Typography>
    </Grid>
  </Grid>
);

const prepareArtworksOnS3 = async artwork => {
  const s3Data = await s3
    .upload({
      Key: `${Date.now()}-${artwork.name.replaceAll(' ', '-').replaceAll('_', '-')}`,
      Body: artwork,
      ContentType: artwork.type
    })
    .promise();
  return { name: artwork.name, url: s3Data.Location };
};

const EXPRESS_AVERAGE = 28;
const STANDARD_AVERAGE = 18;
const MIN_CREDIT_BALANCE = 100;

const getRecommendedCredits = (products, isExpress) =>
  products.reduce((sum, p) => sum + p.stock.reduce((acc, s) => acc + s.quantity, 0), 0) *
  (isExpress ? EXPRESS_AVERAGE : STANDARD_AVERAGE);

const buildRecipientPayload = (contacts, settings) => {
  const payload = contacts.map(c => ({
    platformId: c.id,
    firstName: c.first_name || undefined,
    lastName: c.last_name || undefined,
    email: c.email?.toLowerCase() || undefined,
    ...settings
  }));
  return payload;
};

export {
  RedeemPageCard,
  PresetTemplate,
  ProductCard,
  getPageLink,
  prepare,
  prepareArtworksOnS3,
  FileUploadZone,
  RedemptionRow,
  TableEmptyState,
  RedeemPageDeleteModal,
  ColorInput,
  RedeemCustomSelectField,
  ImageUploadSection,
  getStatus,
  ImageSelector,
  CreateRedeemOptionsDrawer,
  ProductsSelectionPanel,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  CustomTextField,
  getRecommendedCredits,
  NumberFormatCustom,
  PageRedemptionsBlock,
  PageInventoryBlock,
  AvailableCreditBlock,
  ShareStatusBlock,
  MIN_CREDIT_BALANCE,
  TableHeaderCollection,
  TableHeaderAutoShip,
  getShippingStatus,
  shipmentStatuses,
  RedemptionDeleteModal,
  RedemptionQuestionnaire,
  useModalStyles,
  Notification,
  getStatusAllowed,
  PageHistoryBlock,
  buildRecipientPayload
};
