import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles, makeStyles, Grid, Button, TextField, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { intStr, moneyStr } from '../../helpers/utils';
import PaymentSection from '../paymentSection/PaymentSection';
import NumberFormatCustom from '../UI/Inputs/NumberFormatCustom';
import { SubmitButton } from '../shared';
import { defaultErrorMsg } from '../../utils/errors';
import Tooltip from '../shared/Tooltip';
import styles from './styles/credits';
import log from '../../logger';
import { useCreditSummary, useCreditActions } from '../../hooks';
import BenefitTab from '../global/BenefitTab';
import { useMembership } from '../../contexts/membershipContext';

const BalanceButton = withStyles(theme => ({
  root: ({ selected }) => ({
    width: 180,
    height: 57,
    borderRadius: 32,
    border: selected ? 'solid 2px #3577d4' : 'solid 1px #d4d9e2',
    backgroundColor: '#ffffff',
    color: selected ? '#3577d4' : '#434c5f',
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 400,
    '&:focus': { border: 'solid 2px #3577d4' },
    '&:hover': {
      border: 'solid 2px #3577d4',
      backgroundColor: 'rgba(211, 227, 247, 0.54)'
    },
    [theme.breakpoints.only('md')]: { width: 150 },
    [theme.breakpoints.only('xl')]: { width: 230 }
  })
}))(Button);

const CreditOptions = ({ credit, setCredit, classes }) => {
  const [selectedIndex, setSelectedIndex] = useState(3);
  const inputValue = useRef();

  const handleFocus = () => {
    setCredit(inputValue.current || 0);
    setSelectedIndex(3);
  };

  log.debug('CreditOptions credit:', credit, 'selectedIndex:', selectedIndex);
  return (
    <Grid container justifyContent="space-between" className={classes.buttonsContainer}>
      {[1000, 5000, 10000].map((value, index) => (
        <BalanceButton
          key={value}
          selected={selectedIndex === index}
          variant="outlined"
          onFocus={() => {
            setCredit(value);
            setSelectedIndex(index);
          }}
        >
          {moneyStr(value)}
        </BalanceButton>
      ))}
      <TextField
        autoFocus
        placeholder="Enter an amount"
        className={classes.amountInput}
        InputProps={{ inputComponent: NumberFormatCustom }}
        variant="outlined"
        onChange={e => {
          const newValue = parseFloat(e.target.value) || 0;
          setCredit(newValue);
          inputValue.current = newValue;
        }}
        onFocus={handleFocus}
      />
    </Grid>
  );
};

const useStyles = makeStyles(styles);

const AddCredits = () => {
  const [isValidForm, setIsValidForm] = useState(true);
  const [credit, setCredit] = useState(0);

  const creditRef = useRef(credit);
  const defaultMethodRef = useRef();

  const classes = useStyles();
  const history = useHistory();

  const { data: creditSummary } = useCreditSummary();
  const currentBalance = creditSummary.current_balance;
  const paymentProfiles = useSelector(state => state.paymentProfiles);

  const { addCredits } = useCreditActions();

  useEffect(() => {
    defaultMethodRef.current = paymentProfiles.find(p => p.default);
    creditRef.current = credit;
  }, [paymentProfiles, credit]);

  const handleAddCredit = async () => {
    const amount = creditRef.current;
    const method = defaultMethodRef.current;
    try {
      const response = await addCredits.mutateAsync({ amount });
      history.push({
        pathname: '/credit-confirmation',
        state: { amount, method, currentBalance: response.current_balance }
      });
      return undefined;
    } catch (e) {
      return e.message || defaultErrorMsg(e.status);
    }
  };

  const { currentMembership } = useMembership();
  const { earlyBirdPromotion, leftBarNavigation } = useFlags();

  return (
    <Grid container direction="column">
      <Grid item container justifyContent="space-between" className={classes.titleContainer}>
        <Grid item xs lg={6} container alignItems="center" style={leftBarNavigation ? { paddingTop: 10 } : {}}>
          <Typography variant="h3" className={classes.title}>
            Available Credits
          </Typography>
          <Tooltip
            title="Your available credits can be used when you shop online or to cover shipping fees"
            placement="right"
            width={220}
            color="blue"
          >
            <img src="/images/account/outline-info.svg" alt="Confirmed" className={classes.infoIcon} />
          </Tooltip>
        </Grid>
        <Typography variant="h3" className={classes.title}>
          <span style={{ color: '#45af5f', fontWeight: 500 }}>{moneyStr(currentBalance)}</span>
        </Typography>
      </Grid>
      <hr className={classes.line} />
      <Grid container alignItems="center">
        <Grid item xs>
          <Typography variant="h6" className={classes.tableTitle}>
            Reload Account Balance
          </Typography>
        </Grid>
        <Grid item>
          {currentMembership && (!currentMembership.isFreeTier || earlyBirdPromotion) && (
            <BenefitTab
              earlyBirdPromotion={earlyBirdPromotion ? currentMembership : undefined}
              icon="/images/membership/rewards.png"
              text={`Reward Points ${currentMembership.reward_points_rate}X`}
              value={Math.ceil(credit * parseFloat(currentMembership.reward_points_rate))}
              type="reward"
              alone
            />
          )}
        </Grid>
      </Grid>
      <CreditOptions credit={credit} setCredit={setCredit} classes={classes} />
      <PaymentSection
        simple
        handleAfterPayConfirmation={handleAddCredit}
        onValidityChange={isValid => setIsValidForm(isValid)}
      >
        <Grid container justifyContent="flex-end">
          <SubmitButton variant="primary" formName="paymentSectionForm" disabled={credit < 1 || !isValidForm}>
            RELOAD BALANCE
          </SubmitButton>
        </Grid>
      </PaymentSection>
    </Grid>
  );
};

export default AddCredits;
