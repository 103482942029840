import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SwipeableViews from 'react-swipeable-views';
import {
  onboardingNextStep,
  onboardingPreviousStep,
  onboardingUpdateInfo,
  onboardingTwoPage,
  onboardingOnePage,
  onboarding,
  onboardingResetStep,
  loadCountries,
  onboardingAuthorizeCreditCards
} from '../../actions';
import designsApi from '../../apis/swagup/designs';
import addressesApi from '../../apis/swagup/addresses';
import { sendPaymentDataToAnet } from '../../apis/authorizenet';
import FirstStep from './FirstStep';
import TwoColumnsContentPage from './TwoColumnsContentPage';
import BackgroundAdornments from './BackgroundAdornments';
import WizardForm from './WizardForm';
import Loader from '../global/Loader';
import { Helmet } from '../shared';
import tags from '../../apis/seoTags';
import AddressConfirmation from '../shared/AddressConfirmation';
import {
  SecondStep,
  ThirdStep,
  CompanyStep,
  FourthStep,
  FifthStep,
  SixthStep,
  SeventhStep,
  EigthStep,
  NinthStep,
  TenthStep,
  ElevenStep,
  CreditCardStep
} from './WizardSteps';
import StartBuilding from './StartBuilding';
import styles from './styles/onboarding';
import log from '../../logger';
import toErrorPage from '../../helpers/toErrorPage';
import { defaultErrorMsg } from '../../utils/errors';
import { useMetaData } from '../../hooks/globalQueries';
import apiPaths from '../../helpers/apiPaths';
import { getDomain } from '../pages/onboarding/mockups/common';
import { useChiliPiper } from '../../hooks';

const keyDownEventHandler = event => {
  const ctrlOrMetaKeys = event.ctrlKey || event.metaKey;
  const specialKeys = ['=', '-', '+', '0'].includes(event.key);
  if (ctrlOrMetaKeys && specialKeys) event.preventDefault();
};

const wheelEventHandler = event => {
  if (event.ctrlKey) event.preventDefault();
};

const OnboardingHome = props => {
  const { onboardingAiMockupsTemp070521 } = useFlags();
  const { paths, currentStep, currentInfo, onboardingResetStep: resetStep, classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [designId, setDesignId] = useState();
  const [addressVerification, setAddressVerification] = useState({ address: {} });

  const metaData = useMetaData();
  const queryClient = useQueryClient();
  const createDesign = useMutation(data => designsApi.request(data), {
    onSuccess: response => {
      queryClient.setQueryData([apiPaths.designs, response.data.id], response.data);
      setDesignId(response.data.id);
    }
  });

  const history = useHistory();
  const { pathname } = useLocation();

  const handleClose = () => setIsModalOpen(false);

  useEffect(() => {
    if (pathname.includes('/onboarding/welcome')) resetStep();
  }, [pathname, resetStep]);

  const goPreviousStep = () => {
    props.onboardingPreviousStep();
  };

  useEffect(() => {
    if (currentStep > 1) {
      const previous = `/onboarding/${paths[currentStep - 2]}`;
      if (previous === pathname) {
        props.onboardingPreviousStep();
        return;
      }
      const next = `/onboarding/${paths[currentStep]}`;
      if (next === pathname) props.onboardingNextStep();
    }
  }, [pathname]);

  useEffect(() => {
    const path = `/onboarding/${paths[currentStep - 1]}`;
    if (path !== pathname) history.push(path);
  }, [currentStep, history, paths]);

  useEffect(() => {
    document.addEventListener('keydown', keyDownEventHandler);
    window.addEventListener('wheel', wheelEventHandler);
    return () => {
      document.removeEventListener('keydown', keyDownEventHandler);
      window.addEventListener('wheel', wheelEventHandler);
      resetStep();
    };
  }, [resetStep]);

  const goNextStep = newInfo => {
    props.onboardingUpdateInfo(newInfo);
    props.onboardingNextStep();
  };

  const validateCard = async (cardInfo, updateState, reportError) => {
    setIsLoading(true);
    try {
      const cardData = {
        ccnumber: cardInfo.card_number,
        cvc: cardInfo.security_code,
        exdate: cardInfo.expiration_date,
        fullName: cardInfo.naccount
      };
      const anetResponse = await sendPaymentDataToAnet(cardData);
      if (anetResponse.dataDescriptor && anetResponse.dataValue) {
        const profile = {
          first_name: currentInfo.first_name,
          last_name: currentInfo.last_name,
          email: currentInfo.email,
          company_name: currentInfo.company_name,
          shipping_country: currentInfo.shipping_country,
          shipping_address1: currentInfo.shipping_address1,
          shipping_city: currentInfo.shipping_city,
          dataValue: anetResponse.dataValue,
          amount: 25
        };
        const { result, data } = await props.onboardingAuthorizeCreditCards(profile);
        if (result === 'ok') {
          if (data.is_valid) {
            goNextStep({ ...cardInfo, global_unique_id: data.global_unique_id });
          } else reportError(`${data.error_message}`);
        } else reportError(defaultErrorMsg);
      } else reportError(anetResponse.message[0]?.text || defaultErrorMsg);
    } catch (e) {
      log.debug('validateCard Error: ', e);
      toErrorPage(e, history);
    }
    setIsLoading(false);
  };

  const handleAddressConfirmed = (data, callbackAction) => {
    goNextStep(data);
    if (callbackAction) callbackAction(data);
  };

  const validateAddress = async (addressData, updateState) => {
    setIsLoading(true);

    const { result, status } = await addressesApi.validate(addressData);

    if (result === 'ok') {
      handleAddressConfirmed(addressData, updateState);
    } else {
      const hardFailure = status === 400;
      if (hardFailure)
        updateState({
          withforcedError: {
            id: 'shipping_zip',
            value: addressData.shipping_zip,
            innerError: `Invalid Zip Code for ${addressData.shipping_state || 'the state'}`
          }
        });
      else {
        setIsModalOpen(true);
        const address = {
          ...addressData,
          street: addressData.shipping_address1,
          secondary: addressData.shipping_address2,
          city: addressData.shipping_city,
          state: addressData.shipping_state,
          zipcode: addressData.shipping_zip,
          country: addressData.shipping_country || 'US'
        };
        setAddressVerification(address);
      }
    }

    setIsLoading(false);
  };

  const twoPageLayoutStep = newInfo => {
    props.onboardingTwoPage();
    goNextStep(newInfo);
  };

  const startMockupProcess = newInfo => {
    createDesign.mutate({
      ...newInfo,
      domain: getDomain(props.currentInfo.email),
      pack_template: metaData?.data?.CURRENT_SAMPLE_PACK
    });
  };

  const isInternational = currentInfo.shipping_country !== 'US';

  const submitLead = useChiliPiper();

  const sendPackRequest = async newInfo => {
    if (!isLoading) setIsLoading(true);

    const { data, result } = await props.onboarding({ ...currentInfo, ...newInfo });

    setIsLoading(false);
    if (result === 'ok') {
      const handlePackSubmitted = () => {
        if (designId) {
          props.onboardingUpdateInfo(newInfo);
          history.push(`/onboarding/mockups/${designId}`, {
            samplePack: data
          });
        } else {
          goNextStep(newInfo);
          props.onboardingOnePage();
        }
      };

      submitLead({
        lead: {
          FirstName: currentInfo.first_name,
          LastName: currentInfo.last_name,
          Email: currentInfo.email,
          Company: currentInfo.company_name,
          Phone: currentInfo.phone_number
        },
        onRouted: handlePackSubmitted,
        onError: handlePackSubmitted
      });
    } else toErrorPage(result, history);
  };

  const locationOnNext = async newInfo => {
    if (newInfo.shipping_country === 'International') {
      if (!isLoading) setIsLoading(true);
      const { result } = await props.loadCountries();
      if (result !== 'ok') toErrorPage(result, history);
      setIsLoading(false);
    }
    goNextStep(newInfo);
  };

  const creditCardForm = (
    <WizardForm
      nextStep={goNextStep}
      previousStep={goPreviousStep}
      Inputs={CreditCardStep}
      title="Enter your credit card details"
      maxWidthInput={545}
      maxWidthTitle={545}
      maxWidth={545}
      furtherValidations={validateCard}
    />
  );

  const phoneNumberForm = (
    <WizardForm
      nextStep={goNextStep}
      previousStep={goPreviousStep}
      Inputs={SeventhStep}
      title="What’s your phone number?"
      maxWidthInput={472}
    />
  );

  // We need to use this trick because SwipeableViews
  // does not support having a `null` child
  // so we cannot rely on rendering conditionally
  const specialSteps = isInternational ? [creditCardForm, phoneNumberForm] : phoneNumberForm;

  return (
    <div className={classes.root}>
      <Helmet tags={tags.onboarding} />
      <BackgroundAdornments />
      <TwoColumnsContentPage>
        <SwipeableViews axis="x" index={currentStep - 1} className={classes.swipeableViews} disabled>
          <FirstStep
            nextStep={() => {
              if (currentStep === 1) {
                props.onboardingTwoPage();
                props.onboardingNextStep();
              }
            }}
          />
          <WizardForm
            nextStep={twoPageLayoutStep}
            previousStep={() => {
              props.onboardingOnePage();
              goPreviousStep();
            }}
            Inputs={SecondStep}
            title="What’s your name?"
            behaviorText="Nice to meet you {0}!"
            field="first_name"
            optionalField="last_name"
            maxWidthInput={552}
            behaviorImageSrc="/images/onboarding/016-hands-up.svg"
            intermedium={() => props.onboardingOnePage()}
            validateAll
          />
          <WizardForm
            nextStep={twoPageLayoutStep}
            previousStep={goPreviousStep}
            Inputs={ThirdStep}
            title="What’s your company email?"
            maxWidthInput={472}
            intermedium={() => props.onboardingOnePage()}
          />
          <WizardForm
            nextStep={newInfo => {
              twoPageLayoutStep(newInfo);
              if (onboardingAiMockupsTemp070521) {
                startMockupProcess(newInfo);
              }
            }}
            previousStep={goPreviousStep}
            Inputs={CompanyStep}
            title="What’s your company name?"
            field="company_name"
            behaviorText="Awesome! We would love to work with {0}!"
            behaviorImageSrc="/images/onboarding/group-86.svg"
            maxWidthInput={472}
            intermedium={() => props.onboardingOnePage()}
          />
          <WizardForm
            nextStep={goNextStep}
            previousStep={goPreviousStep}
            Inputs={FourthStep}
            title={`What’s your position at ${currentInfo.company || 'your company'}?`}
            maxWidth="100%"
          />
          <WizardForm
            previousStep={goPreviousStep}
            nextStep={locationOnNext}
            Inputs={FifthStep}
            title="Where are you located?"
            maxWidth={542}
            maxWidthInput="100%"
          />
          <WizardForm
            nextStep={goNextStep}
            previousStep={goPreviousStep}
            Inputs={SixthStep}
            title="Where do you want the Swag Pack delivered?"
            smallTitle
            maxWidth={542}
            maxWidthTitle={542}
            maxWidthInput={542}
            furtherValidations={validateAddress}
          />
          {specialSteps}
          <WizardForm
            nextStep={goNextStep}
            previousStep={goPreviousStep}
            Inputs={EigthStep}
            title="Select a size"
            subTitle="We only show sizes currently available to ship"
            maxWidth="100%"
            trick
          />
          <WizardForm
            nextStep={goNextStep}
            previousStep={goPreviousStep}
            Inputs={NinthStep}
            title="What’s your goal with Swag Packs? "
            maxWidth="100%"
            maxWidthTitle={545}
          />
          <WizardForm
            nextStep={dataInfo => {
              goNextStep(dataInfo);
            }}
            previousStep={() => {
              props.onboardingTwoPage();
              goPreviousStep();
            }}
            Inputs={TenthStep}
            title="Cool, how many packs might you use each year?"
            maxWidth="100%"
          />
          <WizardForm
            nextStep={sendPackRequest}
            previousStep={goPreviousStep}
            Inputs={ElevenStep}
            title="By the way, 
            how’d you hear about us?"
            maxWidth="100%"
          />
          <StartBuilding />
        </SwipeableViews>
      </TwoColumnsContentPage>
      {isLoading && <Loader />}
      <AddressConfirmation
        open={isModalOpen}
        onClose={handleClose}
        address={addressVerification}
        callbackAction={handleAddressConfirmed}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  currentStep: state.onboardingInfo.currentStep,
  currentInfo: state.onboardingInfo.currentInfo,
  paths: state.onboardingInfo.paths
});

export default connect(mapStateToProps, {
  onboardingNextStep,
  onboardingPreviousStep,
  onboardingUpdateInfo,
  onboardingOnePage,
  onboardingTwoPage,
  onboarding,
  onboardingResetStep,
  loadCountries,
  onboardingAuthorizeCreditCards
})(withStyles(styles)(OnboardingHome));
