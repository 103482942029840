import React, { useState } from 'react';
import {
  IconButton,
  Grid,
  Table,
  Checkbox,
  FormControlLabel,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, Tooltip } from '@swagup-com/components';
import { deleteEmployee } from '../../../actions';
import EmployeePhoto from '../../directory/employeesList/EmployeePhoto';
import { getEmployeeShipAddress } from '../../../helpers/utils';
import styles from './styles/ContactsList';
import toErrorPage from '../../../helpers/toErrorPage';
import RemoveContactsModal from './RemoveContactsModal';
import { useSelection } from './SelectionContext';

const removeEmployeeMessage = (hasShipment, hasPendingShipment) =>
  hasPendingShipment
    ? "Can't remove contact with pending shipments"
    : `Remove contact${hasShipment ? ' & redact personal data in shipments' : ''}`;

const useStyles = makeStyles(styles);

const ContactsList = ({
  employees = [],
  onDelete: updateContactsList,
  selectedContacts,
  onSelectContacts,
  SelectionComponent
}) => {
  const [contactToRemove, setContactToRemove] = useState();
  const dispatch = useDispatch();
  const location = useLocation();

  const { contactsFeGroupingTemp011921: grouping } = useFlags();

  const handleChange = employee => (_, value) => {
    if (value) selectedContacts.set(employee.id, employee);
    else selectedContacts.delete(employee.id);
    onSelectContacts([selectedContacts]);
  };

  const closeRemoveModal = () => setContactToRemove(undefined);

  const classes = useStyles();
  const history = useHistory();

  const { excluded, updateExcluded, isSelected } = useSelection();

  const handleRemove = result => {
    if (result === 'ok') {
      if (excluded.has(contactToRemove.id)) updateExcluded(contactToRemove);
      closeRemoveModal();
      updateContactsList();
    } else {
      toErrorPage({ result }, history);
    }
  };

  const handleRemoveOld = async () => {
    const response = await dispatch(deleteEmployee(contactToRemove.id));
    if (response.result === 'ok') {
      selectedContacts.delete(contactToRemove.id); // update selected  contact
      onSelectContacts([selectedContacts]);
      closeRemoveModal();
      updateContactsList();
    } else {
      toErrorPage(response, history);
    }
  };

  const handleIsSelected = id => (grouping ? isSelected(id) : selectedContacts.has(id));

  return (
    <Grid container className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ paddingLeft: 0 }} className={classes.cellheader}>
              <SelectionComponent />
            </TableCell>
            <TableCell style={{ width: '50%' }} className={classes.cellheader}>
              Name
            </TableCell>
            <TableCell className={classes.cellheader} style={{ width: '30%' }}>
              {grouping ? 'Groups' : 'Address'}
            </TableCell>
            <TableCell className={classes.cellheader} style={{ width: '30%' }}>
              Email
            </TableCell>
            <TableCell size="small" />
          </TableRow>
        </TableHead>
        <TableBody>
          {employees.map(employee => (
            <TableRow key={employee.id}>
              <TableCell style={{ padding: 24, paddingRight: 0, paddingLeft: 0 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: handleIsSelected(employee.id) ? '#3577d4' : '#8d9299' }}
                      checked={handleIsSelected(employee.id)}
                      onChange={grouping ? () => updateExcluded(employee) : handleChange(employee)}
                    />
                  }
                  label={
                    <div style={{ marginLeft: 8 }}>
                      <Link
                        to={{
                          pathname: `/contact-details/${employee.id}`,
                          state: { employee }
                        }}
                      >
                        <EmployeePhoto employee={employee} />
                      </Link>
                    </div>
                  }
                />
              </TableCell>
              <TableCell className={classes.cell} style={{ verticalAlign: 'middle' }}>
                <Link
                  to={{
                    pathname: `/contact-details/${employee.id}`,
                    state: { employee }
                  }}
                >
                  <p className={classes.nameEmployee}>{`${employee.first_name || ''} ${employee.last_name || ''}`}</p>
                  {employee.title && <p className={classes.titleEmployee}>{employee.title}</p>}
                  {grouping && <p className={classes.tableText}>{getEmployeeShipAddress(employee)}</p>}
                </Link>
              </TableCell>
              <TableCell className={classes.cell}>
                {grouping ? (
                  <p className={classes.tableText}>{employee.groups.map(g => g.name).join(', ')}</p>
                ) : (
                  <p className={classes.tableText}>{getEmployeeShipAddress(employee)}</p>
                )}
              </TableCell>
              <TableCell className={classes.cell}>
                <p className={classes.tableText}>{employee.email}</p>
              </TableCell>
              <TableCell className={classes.actionsCell}>
                <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap" spacing={1}>
                  <Grid item>
                    <Button
                      component={Link}
                      to={{
                        pathname: `/send-swag/select-products`,
                        state: { contacts: [employee], from: location }
                      }}
                      size="small"
                      style={{ width: 173 }}
                    >
                      Send Swag
                      <img
                        src="/images/staff/icon-delivery.svg"
                        alt="ship"
                        style={{ marginLeft: 'auto', width: 20, height: 20 }}
                      />
                    </Button>
                  </Grid>
                  <Grid item>
                    <RemoveIcon
                      tooltipTitle={removeEmployeeMessage(
                        employee.has_orders,
                        employee.has_pending_orders || employee.is_in_shipment_groups
                      )}
                      disabled={employee.has_pending_orders || employee.is_in_shipment_groups}
                      onClick={() => setContactToRemove(employee)}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <RemoveContactsModal
        contacts={[contactToRemove]}
        onDelete={grouping ? handleRemove : handleRemoveOld}
        isOpen={Boolean(contactToRemove)}
        onClose={closeRemoveModal}
      />
    </Grid>
  );
};

const RemoveIcon = ({ tooltipTitle, disabled, onClick }) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltipTitle} classes={{ tooltip: classes.tooltip }}>
      <div>
        <IconButton disabled={disabled} onClick={onClick} data-testid="delete-contact-btn">
          <DeleteOutlineIcon color={disabled ? 'disabled' : 'error'} style={{ fontSize: 20 }} />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export { removeEmployeeMessage };
export default ContactsList;
