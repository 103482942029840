/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useHistory, Prompt, useLocation, useParams } from 'react-router-dom';
import { makeStyles, Grid, Typography, Select, FormControl, MenuItem } from '@material-ui/core';
import { Typography as SwagupTypography, Button } from '@swagup-com/components';
import InputBase from '@material-ui/core/InputBase';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useDispatch } from 'react-redux';
import { useQueryClient, useMutation, useIsMutating } from 'react-query';
import Confetti from 'react-canvas-confetti';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isEqual from 'lodash/isEqual';
import round from 'lodash/round';
import debounce from 'lodash/debounce';
import clsx from 'clsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EditIcon from '@material-ui/icons/Edit';
import ProofDetailsModal from './ProofDetailsModal';
import { setAccountProductStatus, getOpportunityById } from '../../../actions';
import Breadcrumbs from '../../shared/Breadcrumbs';
import { Img } from '../../global/ImgUtils';
import { productImageBasedOnStatus } from '../../global/proofsCommon';
import FeedbackSection from './FeedbackSection';
import { Alert } from '../../shared';
import { StylessButton } from '../../buttons';
import {
  UnsavedChangesModal,
  DownloadLink,
  Skeleton,
  getNewProof,
  ItemCard,
  ImageDialog,
  ZoomIconSearch,
  MockupDownloadLink
} from './ProofDetailsSectionsExtension';
import styles from './styles/ProofDetails';
import { productionTime, productStatus } from '../../../apis/constants';
import accountProductsApi from '../../../apis/swagup/accountProducts';
import proofsApi from '../../../apis/swagup/proofs';
import apiPaths from '../../../helpers/apiPaths';
import { decorationName, getVisibleDecorationsKeys, decorationStatusText, ApprovalModal } from './common';
import { canApproveProduct, approveProductText } from '../../../helpers/productUtils';
import { moneyStr, isPack, s3 } from '../../../helpers/utils';
import { PRODUCT_STATUS } from '../../../utils/constants';
import ProgressBar from '../../shared/ProgressBar';
import { orderApi } from '../../../apis/swagup';
import Loader from '../../global/Loader';

const useStyles = makeStyles(styles);

const prepareForApproveDisplay = item => ({
  ...item,
  itemColor: { theme_color_hex: item.theme_color_hex, theme_color_name: item.theme_color }
});

const generateChangesRequest = ({ itemColor, decorations }, getName) => {
  let payload = itemColor ? { item_color: itemColor.name, item_color_hex: itemColor.hex_color } : {};

  if (decorations)
    payload = {
      ...payload,
      decorations: decorations.map((decoration, index) => {
        let extendedlDecorationData = {
          location: decoration.Location,
          dimension: decoration.Dimensions,
          imprint_type: decoration.Imprint_Type,
          additional_notes: decoration.Notes,
          artwork_path: decoration.artwork?.url,
          artwork_name: decoration.artwork?.fileName
        };
        const minimalDecorationData = {
          operation: 'Created',
          name: `New Decoration ${index + 1}`
        };
        extendedlDecorationData = JSON.parse(JSON.stringify(extendedlDecorationData));
        return decoration.deleted ? minimalDecorationData : { ...minimalDecorationData, ...extendedlDecorationData };
      })
    };
  return payload;
};

const isDeepEmpty = obj =>
  isEmpty(obj) ||
  (isArray(obj) && obj.every(o => isDeepEmpty(o))) ||
  Object.keys(obj).every(key => isPlainObject(obj) && isDeepEmpty(obj[key])) ||
  (obj.created && obj.deleted);

const prepareArtworksOnS3 = async obj => {
  const prepared = await Object.keys(obj).reduce(async (loaded, key) => {
    let value = obj[key];
    if (key === 'artwork') {
      const s3Data = await s3
        .upload({
          Key: `${Date.now()}-${value.fileName.replace(' ', '-').replace('_', '-')}`,
          Body: value.image,
          ContentType: value.image.type
        })
        .promise();
      value = { fileName: value.fileName, url: s3Data.Location };
    }
    return { ...(await loaded), [key]: value };
  }, '');
  return prepared;
};

const decorationsForSend = async (decorations, s3upload) => {
  const toSendKeys = getVisibleDecorationsKeys(decorations);
  const ready = toSendKeys.reduce(
    async (loaded, key) => ({
      ...(await loaded),
      [key]: s3upload ? await prepareArtworksOnS3(decorations[key]) : decorations[key]
    }),
    {}
  );
  const rslt = await Promise.resolve(ready);
  return rslt;
};

const HeaderSection = ({ proof, fromProducts, inProductDetails, classes }) => {
  const history = useHistory();

  const { product } = proof;
  const price = isPack(product.record_type)
    ? product.items.reduce((sum, item) => sum + round(item.units_per_pack * item.product.price, 2), 0)
    : +proof.price;

  const getBreadcrumbLinks = () =>
    inProductDetails
      ? [{ title: 'Products', to: '/products' }, { title: product.name }]
      : [
          { title: 'Orders', to: '/orders' },
          {
            title: `Order Request #${proof.opportunity}`,
            to: { pathname: `/orders-requested/${proof.opportunity}`, state: { fromProducts } }
          },
          { title: product.name }
        ];

  return (
    <Grid container className={classes.headerContainer}>
      <Grid item container alignItems="center" className={classes.breadcrumbContainer} xs={12}>
        <KeyboardBackspaceIcon
          onClick={() => history.push(inProductDetails ? '/products' : `/orders-requested/${proof.opportunity}`)}
          className={classes.backIcon}
        />
        <Breadcrumbs links={getBreadcrumbLinks()} separator="/" />
      </Grid>
      <Grid item container className={classes.subDetails} xs={12} sm={6}>
        <p className={classes.productName}>{product.name}</p>
      </Grid>
      <Grid item container xs={12} sm={6}>
        <Grid container className={classes.subHeaderContainer} justifyContent="flex-end">
          <Grid item className={classes.subHeader}>
            <Grid container>
              <Grid item>
                <p className={classes.subDetailsText} style={{ marginRight: 6 }}>
                  {isPack(product.record_type) ? 'Price per pack: ' : 'Price: '}{' '}
                </p>
              </Grid>
              <Grid item>
                <p className={classes.subDetailsText}>
                  {!Number.isFinite(price) ? (
                    <Skeleton style={{ width: 60 }} />
                  ) : (
                    <span className={classes.priceXPack}>{moneyStr(price)}</span>
                  )}
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={clsx(classes.subHeader, classes.lastSubHeader)}>
            <p className={classes.subDetailsText}>Mockup presentation file</p>
            <div>
              <DownloadLink
                disabled={!product?.image}
                link={product.image?.replace('.png', '.pdf')}
                classes={classes}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const getChanges = (originalProof, modifiedProof) => {
  const dif = [];

  if (originalProof)
    originalProof.product.items.forEach(item => {
      const packItem = modifiedProof?.product.items?.find(i => i.product.id === item.product.id);

      if (!packItem) {
        dif.push({
          type: 'removed_item',
          itemId: item.product.id
        });
      } else if (packItem.units_per_pack !== item.units_per_pack) {
        dif.push({
          type: 'changed_quantity',
          itemId: item.product.id,
          newQuantity: packItem.units_per_pack
        });
      }
    });

  return dif;
};

const ProductCardSection = ({
  onDeleteProduct,
  onSelectProduct,
  setLocalProof,
  proof,
  localProof,
  selected,
  isLoadingPrices,
  inProductDetails,
  onChangePrice,
  packOrBulkItem,
  onModifyQuantities = () => {}
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [showUpdateButtom, setShowUpdateButton] = useState(false);
  const refs = React.useRef(new Map());

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const proofId = +proof.id;
  const isMutatingChanges = useIsMutating([apiPaths.proofs, proofId]);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (selected && isPack(proof.product.record_type) && refs.current.get(selected.id))
      refs.current.get(selected.id).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [proof, selected]);

  const checkIfOpportunityIsActive = async () => {
    const response = await dispatch(getOpportunityById(proof.opportunity));
    if (response.result === 'ok') {
      history.push(`/orders-requested/${proof.opportunity}`);
    } else {
      history.push(`/orders`);
    }
  };

  const redirect = () => {
    if (inProductDetails) history.push('/products');
    else checkIfOpportunityIsActive();
  };

  const updatePackMutation = useMutation(
    [apiPaths.proofs, proofId],
    ({ packItems }) => {
      const api = inProductDetails ? accountProductsApi : proofsApi;
      return api.updatePack({ id: proofId, packItems });
    },
    {
      onMutate: () => {
        const currentProof = queryClient.getQueryData([apiPaths.proofs, proofId]);
        return { currentProof };
      },
      onSuccess: (data, variables, context) => {
        setLocalProof(context.currentProof);
        setShowAlert(true);
        if (proof.product.items.length === 0) {
          redirect();
        }
      },
      onError: redirect
    }
  );

  const discardChanges = () => queryClient.setQueryData([apiPaths.proofs, proof.id], localProof);

  const updatePack = () => updatePackMutation.mutate({ packItems: proof.product.items });

  const itemsApproved = isPack(proof.product.record_type)
    ? proof.product.items.reduce((sum, i) => ([productStatus.approved].includes(i.product.status) ? sum + 1 : sum), 0)
    : 0;
  const itemsTotal = proof.product.items?.length || 0;

  const debouncedChangeUnitsXPack = debounce((units, id) => {
    queryClient.setQueryData([apiPaths.proofs, proofId], currentProof => ({
      ...currentProof,
      product: {
        ...currentProof.product,
        items: currentProof.product.items.map(item =>
          item.product.id === id
            ? {
                ...item,
                units_per_pack: units
              }
            : item
        )
      }
    }));
    onModifyQuantities(!inProductDetails);
  }, 750);

  useEffect(() => {
    setShowUpdateButton(!isEmpty(getChanges(localProof, proof)));
  }, [localProof, proof]);

  return (
    <>
      {isPack(proof.product.record_type) && (
        <Grid>
          {itemsTotal > 0 && (
            <Grid item xs={12} className={classes.itemsApproved}>
              Items approved {itemsApproved}/{itemsTotal}
            </Grid>
          )}
          {showAlert && (
            <Grid className={classes.alertContainer}>
              <Alert
                onClose={() => setShowAlert(false)}
                delayTime={6000}
                width={310}
                style={{ margingBottom: 20, justifyContent: 'left' }}
              >
                Your pack is updated!
              </Alert>
            </Grid>
          )}

          <Grid
            item
            container
            align="center"
            justifyContent="space-between"
            className={classes.containerButtons}
            style={{ ...(!showUpdateButtom ? { opacity: 0, visibility: 'hidden' } : {}) }}
          >
            <Button
              variant="text"
              size="small"
              disabled={isLoadingPrices || isMutatingChanges > 0}
              onClick={discardChanges}
              loading={false}
              className={classes.discardButton}
            >
              Discard Changes
            </Button>
            <Button
              variant="primary"
              size="small"
              disabled={isLoadingPrices || isMutatingChanges > 0}
              onClick={updatePack}
              loading={isLoadingPrices || isMutatingChanges > 0}
              className={classes.updateButton}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        container
        xs
        className={classes.productList}
        style={{ marginTop: isPack(proof.product.record_type) ? 0 : 20 }}
      >
        <Grid container direction="column" style={{ width: 330 }}>
          {isPack(proof.product.record_type) ? (
            proof.product.items.map(item => {
              return (
                <div
                  role="button"
                  key={item.product.id}
                  tabIndex={0}
                  onKeyDown={() => onSelectProduct(item)}
                  onClick={() => onSelectProduct(item)}
                  style={{ cursor: 'pointer' }}
                  ref={ref => refs.current.set(item.product.id, ref)}
                >
                  <ItemCard
                    product={item.product}
                    selected={item.product.id === selected?.id}
                    hideTrashCan={proof.is_in_invoice}
                    onDeleteProduct={onDeleteProduct}
                    isLoading={isLoadingPrices}
                    onChangePrice={onChangePrice}
                    inProductDetails={inProductDetails}
                    isPackType
                    packOrBulkItem={packOrBulkItem}
                    proof={proof}
                    debouncedChangeUnitsXPack={debouncedChangeUnitsXPack}
                    units_per_pack={item.units_per_pack}
                  />
                </div>
              );
            })
          ) : (
            <ItemCard
              product={{ ...proof.product, price: +proof.price }}
              selected
              hideTrashCan={proof.is_in_invoice}
              isLoading={isLoadingPrices}
              onChangePrice={onChangePrice}
              inProductDetails={inProductDetails}
              isPackType={false}
              packOrBulkItem={packOrBulkItem}
              proof={proof}
              debouncedChangeUnitsXPack={debouncedChangeUnitsXPack}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

const ProductDetail = ({
  onChangePrice,
  onModifyQuantities,
  onSelectProduct,
  setLocalProof,
  setNextItem,
  proof,
  localProof,
  selected,
  packOrBulkItem,
  nextItem,
  changesHistory,
  isRemoveItem,
  inProductDetails,
  refetch
}) => {
  const location = useLocation();
  const params = useParams();

  const [openDialog, setOpenDialog] = useState(false);
  const [fireConfetti, setFireConfetti] = useState(false);
  const [isDecorationUpdated, setIsDecorationupdated] = useState(false);
  const [busy, setBusy] = useState(false);
  const [changesRequested, setChangesRequested] = useState({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [tabCount, setTabCount] = React.useState(0);
  const [itemFeedBack, setItemFeedBack] = useState({ id: 0 });
  const [selectedStatusIndex, setSelectedStatusIndex] = useState(0);
  const [openProofDetailsModal, setOpenProofDetailsModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [deletingDecorationId, setDeletingDecorationId] = React.useState('');
  const [approvalCount, setApprovalCount] = React.useState(0);
  const unmount = useRef(false);
  const action = useRef(null);
  const queryClient = useQueryClient();
  const [updateDecoration, setUpdateDecoration] = useState([]);
  const [recentDecorations, setRecentDecoration] = useState([]);
  const originalDecorationsRef = useRef([]);
  const history = useHistory();
  const classes = useStyles();

  const proofId = +proof.id;
  const { id } = packOrBulkItem.product;
  const newStatus =
    packOrBulkItem.product.new_status === productStatus.mockupReview
      ? productStatus.productionRequest
      : productStatus.approved;

  const statusMutation = useMutation(
    [apiPaths.accountProducts, id, newStatus],
    newIdWithStatus =>
      setAccountProductStatus({
        id: newIdWithStatus.id,
        newStatus: newIdWithStatus.newStatus
      }),
    {
      onMutate: newIdWithStatus => {
        queryClient.cancelQueries([apiPaths.accountProducts, id, newStatus]);
        const previousProof = queryClient.getQueryData([apiPaths.proofs, proofId]);
        const localPreviousProof = localProof;
        queryClient.setQueryData([apiPaths.proofs, proofId], getNewProof(proof, newIdWithStatus));
        setLocalProof(getNewProof(localProof, newIdWithStatus));

        return { previousProof, localPreviousProof };
      },
      onSuccess: () => {
        setFireConfetti({});
      },
      onError: (error, newIdWithStatus, context) => {
        queryClient.setQueryData([apiPaths.proofs, proofId], context.previousProof);
        setLocalProof(context.localPreviousProof);
      }
    }
  );

  const sendCommentMutation = useMutation(
    [apiPaths.accountProducts, proofId, id],
    info => accountProductsApi.sendComment(id, info),
    {
      onSuccess: comment => {
        queryClient.setQueryData(
          [apiPaths.proofs, proofId],
          getNewProof(proof, { id, newStatus: productStatus.changesRequested })
        );
        setLocalProof(getNewProof(localProof, { id, newStatus: productStatus.changesRequested }));
        queryClient.setQueryData([apiPaths.accountProducts, 'comments', +id], old => ({
          ...old,
          results: [comment, ...old.results]
        }));
      },
      onSettled: () => {
        setChangesRequested({});
        setBusy(false);
      }
    }
  );

  const isPackType = isPack(proof.product.record_type);

  const { areAllItemsApproved, numberOfApprovedItems } = useMemo(() => {
    let noOfApprovedItems = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of proof.product.items) {
      if (item?.product?.status?.toLowerCase() === 'approved') {
        noOfApprovedItems += 1;
      }
    }

    return {
      areAllItemsApproved: noOfApprovedItems === proof?.product?.items?.length,
      numberOfApprovedItems: noOfApprovedItems
    };
  }, [proof]);

  const setDefaultProductColor = () => {
    setProductColor(
      itemFeedBack?.available_colors?.find(i => i.hex_color === packOrBulkItem?.product?.theme_color_hex) ||
        (itemFeedBack?.available_colors && itemFeedBack?.available_colors[0])
    );
  };

  useEffect(() => {
    setDefaultProductColor();
    if (isRemoveItem || isEqual(packOrBulkItem?.product.id, nextItem?.product.id)) return;

    if (!isDeepEmpty(changesRequested) && !changesRequested.id) {
      setOpenConfirmationDialog(true);
      action.current = null;
    } else if (isPack(proof.product.record_type)) {
      onSelectProduct(nextItem.product.id);
    }
  }, [changesRequested, nextItem, packOrBulkItem, proof, onSelectProduct, isRemoveItem]);

  useEffect(() => {
    if (isPack(packOrBulkItem.product.record_type))
      setApprovalCount(selected?.map(item => item.new_status !== productStatus.proofReview).length);
    if (packOrBulkItem.product.id !== itemFeedBack.id || packOrBulkItem.product.status !== itemFeedBack.status) {
      setItemFeedBack(packOrBulkItem.product);
      originalDecorationsRef.current = packOrBulkItem?.product?.decorations || [];
    }
    if (packOrBulkItem) {
      const _selectedStatusIndex = PRODUCT_STATUS.findIndex(
        status => packOrBulkItem.product?.new_status?.toLowerCase() === status.toLowerCase()
      );
      setSelectedStatusIndex(_selectedStatusIndex);
    }
  }, [itemFeedBack, packOrBulkItem]);

  const requestChanges = async () => {
    setBusy(true);
    const newDecorations = await decorationsForSend(updateDecoration.filter(i => i.Name === undefined) || {}, true);
    let oldDecoration = await decorationsForSend(updateDecoration.filter(i => i.Name) || {}, true);
    oldDecoration = Object.values(oldDecoration).filter(
      filterdObj => !originalDecorationsRef.current.some(orginalFilteredObj => isEqual(orginalFilteredObj, filterdObj))
    );
    const decorations = [...oldDecoration, ...Object.values(newDecorations)];
    setRecentDecoration(decorations); // Using to show new recent decoration on the modal
    const payload = generateChangesRequest(
      {
        ...changesRequested,
        itemColor: isProductColorChange && productColor,
        decorations: Object.keys(decorations).map(key => decorations[key])
      },
      decoration => decorationName(decoration)
    );
    await sendCommentMutation.mutateAsync({ product: proof.product.id, ...payload });
    setTabValue(0);
    setTabCount(0);
  };

  const selectNextItem = (selectForApprove = false) => {
    const nextForApproval = selectForApprove
      ? proof.product.items.find(i => i.product.id !== id && i.product.status?.toLowerCase() !== 'approved')
      : proof.product.items.find(i => i.product.id !== id);
    if (nextForApproval) {
      onSelectProduct(nextForApproval.product.id);
    }
  };

  const requestDelete = async () => {
    const payload = { decorations: [{ operation: 'Deleted', name: `Decoration ${deletingDecorationId}` }] };
    await sendCommentMutation.mutateAsync({ product: +proof.product.id, ...payload });
    refetch();
    setDeletingDecorationId('');
    selectNextItem();
  };

  const handleRequestChanges = async () => {
    setOpenConfirmationDialog(false);
    await requestChanges();
    refetch();
    setModalType('design-request');
    setOpenProofDetailsModal(true);
  };

  const handleMockupApprove = async () => {
    await statusMutation.mutateAsync({ id: packOrBulkItem.product.id, newStatus });
    refetch();
    selectNextItem();
  };

  const handleApprove = async () => {
    await statusMutation.mutateAsync({ id: packOrBulkItem.product.id, newStatus });
    refetch();
  };

  const approveNextItem = () => {
    const nextForApproval = proof.product.items.find(
      i => i.product.id !== id && i.product.new_status !== productStatus.readyToOrder
    );
    if (nextForApproval) {
      onSelectProduct(nextForApproval.product.id);
    } else setModalType('ready-to-order');
    setFireConfetti(false);
  };

  const handleFinishedAnimation = () => {
    const nextForApproval = proof.product.items.find(
      i => i.product.id !== id && canApproveProduct(i.product.new_status)
    );
    if (nextForApproval) {
      onSelectProduct(nextForApproval.product.id);
    }
    setFireConfetti(false);
  };

  const canBeApproved = [productStatus.mockupReview, productStatus.proofReview].includes(
    packOrBulkItem.product.new_status
  );
  const canApproveProd = canApproveProduct(packOrBulkItem.product.new_status);
  const hideIconSearch = [productStatus.pendingMockup, productStatus.inProgress].includes(
    packOrBulkItem.product.status
  );
  const productImg = productImageBasedOnStatus(packOrBulkItem.product, 690, 690);

  const [productColor, setProductColor] = React.useState('');
  const handleChange = event => {
    setProductColor(event.target.value);
  };
  const getColorHexValue = value => (value ? `#${value?.replace('#', '')}` : undefined);
  const isProductColorChange =
    productColor?.hex_color && productColor.hex_color !== packOrBulkItem?.product?.theme_color_hex;

  useEffect(() => {
    if (itemFeedBack) {
      setIsDecorationupdated(
        isEqual(
          updateDecoration.filter(i => i !== undefined),
          itemFeedBack?.decorations
        )
      );
    }
  }, [updateDecoration, itemFeedBack]);

  const { mutate: createOpportunity, isLoading: isCreatingOpportunity } = useMutation(orderApi.createOpportunity, {
    onSuccess: opportunity => {
      queryClient.fetchQuery(apiPaths.opportunity(opportunity.id), () => opportunity);
      history.push({ pathname: `/orders-requested/${opportunity.id}`, state: { sendProductsToWarehouse: true } });
    }
  });

  const buildOpportunityPayload = accountProducts => ({
    account_products: accountProducts.map(ap => {
      return {
        account_product: ap.id,
        production_time: productionTime.standard,
        quantities_per_size: ap.sizes.map(({ size, quantity }) => ({ size: size.id, quantity }))
      };
    }),
    lead_source: 'Products Tab'
  });

  const handlePlaceNewOrder = async () => {
    createOpportunity(buildOpportunityPayload([{ ...proof.product, sizes: [...proof.sizes] }]));
  };

  const redirectedFromOrdersPage = useMemo(() => {
    return location.pathname.includes('proof-details');
  }, [location.pathname]);

  const handleRedirectUserToOrdersPage = () => {
    if (params?.proofId) {
      history.push({ pathname: `/orders-requested/${params.proofId}`, state: { sendProductsToWarehouse: true } });
    }
  };
  const [isDisabled, setIsDisabled] = useState(true);
  const editOnMockupReview = packOrBulkItem?.product.new_status === productStatus.mockupReview;
  const [approvalModalOpen, setapprovalModalOpen] = useState(false);

  const handleApprovalModalOpen = () => {
    setapprovalModalOpen(true);
  };

  const handleApprovalModalClose = () => {
    setapprovalModalOpen(false);
  };

  const handleFinalApproval = () => {
    setOpenProofDetailsModal(true);
    setModalType('proof-review');
    handleApprovalModalClose();
    handleApprove();
  };

  return (
    <Grid container className={classes.detailsSectionContainer}>
      {isCreatingOpportunity && <Loader />}
      <ProgressBar selectedStatusIndex={selectedStatusIndex} />
      <Grid item xs={12}>
        <Grid container alignItems="center" className={classes.topSection}>
          {packOrBulkItem.has_multiple_packs && (
            <Grid item xs={12} className={classes.alert}>
              <Alert
                showIcon={<ErrorOutlineIcon fontSize="small" style={{ alignSelf: 'center' }} />}
                fontStyles={classes.alertStyles}
                style={{ marginBottom: 10, width: '100%' }}
              >
                This item is in multiple packs, any design changes will affect all packs this item is part of.
              </Alert>
            </Grid>
          )}
          <Grid item xs={7}>
            <Typography variant="body2" className={classes.selectedProductName}>
              {isPackType ? packOrBulkItem.product.name : ''}
            </Typography>
          </Grid>
          <Grid item xs={5} style={{ margin: '24px 0px' }}>
            <Grid container justifyContent="flex-end">
              {!isProductColorChange &&
              ((canBeApproved && (isDecorationUpdated || itemFeedBack?.decorations == null)) || canApproveProd) ? (
                <Button
                  variant="primary"
                  size="small"
                  disabled={statusMutation.isLoading || packOrBulkItem.product.new_status === 'Mockup Design'}
                  onClick={() => {
                    if (packOrBulkItem.product.new_status === productStatus.mockupReview) {
                      handleMockupApprove();
                      setOpenProofDetailsModal(true);
                      setModalType('mockup-approval');
                    } else if (packOrBulkItem.product.new_status === productStatus.proofReview) {
                      handleApprovalModalOpen();
                    } else if (
                      packOrBulkItem.product.new_status === productStatus.readyToOrder &&
                      areAllItemsApproved
                    ) {
                      const callToFunction = redirectedFromOrdersPage
                        ? handleRedirectUserToOrdersPage
                        : handlePlaceNewOrder;

                      callToFunction();
                    } else {
                      approveNextItem();
                    }
                  }}
                  style={
                    packOrBulkItem.product.new_status === productStatus.mockupDesign
                      ? {
                          backgroundColor: '#125CFF',
                          color: '#8D9299'
                        }
                      : packOrBulkItem.product.new_status === productStatus.readyToOrder && !areAllItemsApproved
                      ? {
                          border: '1px solid #125CFF',
                          backgroundColor: '#FFFFFF',
                          color: '#125CFF'
                        }
                      : {
                          backgroundColor: '#125CFF',
                          color: '#FFFFFF',
                          minWidth: 164
                        }
                  }
                  loading={statusMutation.isLoading}
                >
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {approveProductText(
                      packOrBulkItem.product.new_status,
                      areAllItemsApproved,
                      redirectedFromOrdersPage
                    )}
                    {packOrBulkItem.product.new_status === productStatus.readyToOrder && !areAllItemsApproved ? (
                      <ArrowForwardIcon style={{ color: '#125CFF', marginLeft: 8 }} />
                    ) : null}
                  </div>
                </Button>
              ) : [productStatus.mockupReview, productStatus.proofReview].includes(
                  packOrBulkItem.product.new_status
                ) ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    variant="text"
                    color="error"
                    size="small"
                    style={{
                      color: '#C62828'
                    }}
                    onClick={() => {
                      setTabCount(0);
                      setTabValue(0);
                      setDefaultProductColor();
                      setUpdateDecoration([...itemFeedBack?.decorations]);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    size="small"
                    disabled={busy}
                    onClick={() => {
                      action.current = null;
                      handleRequestChanges();
                    }}
                    style={{
                      backgroundColor: '#125CFF',
                      color: '#FFFFFF',
                      minWidth: 164
                    }}
                    loading={busy}
                  >
                    Submit Changes
                  </Button>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="stretch" spacing={6}>
          <Grid item xs={5}>
            <Grid container>
              <StylessButton
                onClick={() => setOpenDialog(true)}
                disabled={hideIconSearch}
                width="100%"
                style={{ cursor: productImg === '/images/proofs/mockup.png' ? 'arrow' : 'zoom-in' }}
              >
                <div className={classes.container384x284}>
                  <Img src={productImg} alt="product" style={{ width: '100%', objectFit: 'contain' }} />
                  {!hideIconSearch && <ZoomIconSearch classes={classes} />}
                  <Confetti
                    fire={fireConfetti}
                    particleCount={250}
                    origin={{ x: 0.5, y: 1 }}
                    colors={['#3577d4', '#9846dd', '#ffffff', '#0066ff', '#F0E8F7', '#F7A9FA']}
                    style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}
                    onDecay={handleFinishedAnimation}
                  />
                </div>
              </StylessButton>
              <Grid container style={{ padding: '0px 0px 0px 0px' }}>
                <Grid item xs={12} style={{ marginTop: '8px' }}>
                  <div className={classes.counter}>
                    <Typography className={classes.proofSectionLabel}>Product Color</Typography>
                  </div>
                  {['Mockup Review', 'Mockup Design'].includes(packOrBulkItem.product.new_status) ? (
                    <FormControl fullWidth variant="standard">
                      <Select
                        value={productColor}
                        input={<InputBase className={classes.input} />}
                        onChange={handleChange}
                        disabled={!editOnMockupReview && isDisabled}
                        classes={{
                          root: classes.selectRoot,
                          select: classes.select
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                          },
                          getContentAnchorEl: null
                        }}
                      >
                        {itemFeedBack?.available_colors?.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                              <div
                                style={{
                                  width: '24px',
                                  height: '24px',
                                  borderRadius: '50%',
                                  background: getColorHexValue(option?.hex_color),
                                  marginRight: '8px'
                                }}
                              />
                              <Typography style={{ fontSize: 16, color: '#131415' }}>{option?.name}</Typography>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <div
                        style={{
                          width: '24px',
                          height: '24px',
                          borderRadius: '50%',
                          background: getColorHexValue(productColor?.hex_color),
                          marginRight: '8px'
                        }}
                      />
                      <Typography style={{ fontSize: 16, color: '#131415' }}>{productColor?.name}</Typography>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} style={{ display: 'inline-flex', marginTop: 16 }}>
                  {/* <Grid item xs={6} style={{ display: 'inline-flex', cursor: 'pointer' }}>
                    <GetAppIcon className={classes.downloadIcon} />
                    <Typography className={classes.proofSectionLabel}>Artwork Template</Typography>
                  </Grid> */}
                  <Grid item xs={6} style={{ display: 'inline-flex', cursor: 'pointer' }}>
                    <MockupDownloadLink
                      classes={classes}
                      link={packOrBulkItem.product.image?.replace('.png', '.pdf')}
                    />
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} style={{ display: 'inline-flex', marginTop: 16 }}>
                  <Typography style={{ color: '#9846DD', fontWeight: 'bold' }}>See mockup history</Typography>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            {itemFeedBack?.decorations?.length ? (
              <FeedbackSection
                item={itemFeedBack}
                tabCount={tabCount}
                setTabCount={setTabCount}
                tabValue={tabValue}
                setTabValue={setTabValue}
                onChangesRequested={setChangesRequested}
                changesHistory={changesHistory}
                setModalType={setModalType}
                modalType={modalType}
                setOpenProofDetailsModal={setOpenProofDetailsModal}
                openProofDetailsModal={openProofDetailsModal}
                updateDecoration={updateDecoration}
                setUpdateDecoration={setUpdateDecoration}
                setDeletingDecorationId={setDeletingDecorationId}
                status={packOrBulkItem?.product?.new_status}
              />
            ) : (
              <Grid item style={{ textAlign: 'center' }}>
                <img src="/images/public/nothing-found.svg" alt="nothing-found" />
                <SwagupTypography variant="subtitle3SemiBoldInter">
                  This item doesn't include decoration options
                </SwagupTypography>
                <SwagupTypography variant="body4RegularInter" style={{ padding: '12px 0px' }}>
                  You can still change the item color
                </SwagupTypography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ImageDialog
        img={productImg}
        largeImageSrc={packOrBulkItem.product.image}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
      <UnsavedChangesModal
        product={packOrBulkItem.product}
        changes={canBeApproved ? prepareForApproveDisplay(itemFeedBack) : changesRequested}
        onContinue={canBeApproved ? handleMockupApprove : handleRequestChanges}
        isOpen={openConfirmationDialog}
        canBeApproved={canBeApproved}
        forConfirmation={
          isEqual(packOrBulkItem?.product.id, nextItem?.product?.id) &&
          (!action.current || action.current.includes('dashboard/proof-details'))
        }
        onClose={() => {
          setOpenConfirmationDialog(false);
          if (!isEqual(packOrBulkItem?.product.id, nextItem?.product?.id)) setNextItem(packOrBulkItem);
        }}
        discardChanges={() => {
          if (action.current) {
            unmount.current = true;
            history.push(action.current);
          } else {
            onSelectProduct(nextItem.product.id);
            setOpenConfirmationDialog(false);
          }
        }}
      />
      <Prompt
        when={!isDeepEmpty(changesRequested) && !changesRequested.id && !busy}
        message={(location, operation) => {
          if (operation === 'REPLACE') {
            return true;
          }
          setOpenConfirmationDialog(true);
          action.current = location.pathname;
          return unmount.current;
        }}
      />
      <ProofDetailsModal
        open={openProofDetailsModal}
        onToggleOpen={() => {
          setOpenProofDetailsModal(false);
          setRecentDecoration([]);
        }}
        type={modalType}
        selectedStatusIndex={selectedStatusIndex}
        selected={selected}
        setOpenDialog={setOpenDialog}
        hideIconSearch={hideIconSearch}
        fireConfetti={fireConfetti}
        handleFinishedAnimation={handleFinishedAnimation}
        recentDecorations={recentDecorations}
        requestDelete={requestDelete}
        refetch={refetch}
        isPack={isPackType}
        approvalCount={approvalCount}
        productColor={productColor}
        numberOfApprovedItems={numberOfApprovedItems}
        areAllItemsApproved={areAllItemsApproved}
        totalProducts={proof?.product?.items?.length}
        handlePlaceNewOrder={handlePlaceNewOrder}
        selectNextItem={selectNextItem}
      />
      <ApprovalModal
        open={approvalModalOpen}
        handleClose={handleApprovalModalClose}
        handleFinalApproval={handleFinalApproval}
      />
    </Grid>
  );
};

export { HeaderSection, ProductCardSection, ProductDetail };
