import { useState } from 'react';
import truncate from 'lodash/truncate';
import round from 'lodash/round';
import sumBy from 'lodash/sumBy';
import partition from 'lodash/partition';
import sum from 'lodash/sum';
import isArray from 'lodash/isArray';

import AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';
import { getCountryCallingCode, isSupportedCountry, isValidPhoneNumber } from 'react-phone-number-input';
import { isEmpty } from 'lodash';
import { dayjs } from './dayjs';
import { productionTime, paymentMethods } from '../apis/constants';
import { CATALOG_PRODUCT_TYPE } from '../utils/constants';
import log from '../logger';
import {
  getQuantitiesBreakdown,
  substractQuantitiesBreakdowns
} from '../components/pages/orders/requested/common/utilsOrder';
import { SORDStatuses, orderStatuses } from '../components/pages/orders/requested/details/orderReview/common';

const addThousandsSeparator = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const thousandsSeparatedAndFixed = (value, decimals = 2) => addThousandsSeparator(parseFloat(value).toFixed(decimals));

const arrowGenerator = color => ({
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: 0,
    marginTop: '-0.95em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${color} transparent`
    }
  },
  '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: 0,
    marginBottom: '-0.95em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '1em 1em 0 1em',
      borderColor: `${color} transparent transparent transparent`
    }
  },
  '&[x-placement*="right"] $arrow': {
    left: 0,
    marginLeft: '-0.95em',
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 1em 1em 0',
      borderColor: `transparent ${color} transparent transparent`
    }
  },
  '&[x-placement*="left"] $arrow': {
    right: 0,
    marginRight: '-0.95em',
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 0 1em 1em',
      borderColor: `transparent transparent transparent ${color}`
    }
  }
});

const normalizeExDate = (value, prevValue) => {
  if (value) {
    const valueOnlyNumbers = value.replace(/[^\d]/g, '');
    const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '');

    if (valueOnlyNumbers !== prevValueOnlyNumbers) {
      const len = valueOnlyNumbers.length;
      const month = valueOnlyNumbers.slice(0, 2);

      if (len < 2) return `${month}`;
      if (len === 2) return `${month}/`;

      const year = valueOnlyNumbers.slice(2, 4);
      if (len <= 4) return `${month}/${year}`;
    }
  }

  return value === '' ? value : prevValue;
};

const fullFormatDate = currentdate => {
  const date = new Date(currentdate);
  return `${date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${
    date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
  }/${date.getFullYear()}`;
};

const formatDate = (dateString, format = 'MM/DD/YYYY') => dayjs(dateString).format(format);

const reorderDateWithSlicing = (date, newSeparator = '-', yearSliceIndex = 0) => {
  const [year, month, day] = date.split('-');
  return [month, day, year.slice(yearSliceIndex)].join(newSeparator);
};

const getProductionTimeText = pack => {
  return `${pack.production_time}
   ${
     pack.production_time === productionTime.standard
       ? pack.product.standard_turnaround_time
       : pack.product.rush_turnaround_time
   } days`;
};

const moneyStr = (value, decimals = 2) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals
  }).format(value ?? 0);

const intStr = value => Intl.NumberFormat('en-US').format(value ?? 0);

// ! Taxes are 0 for now (to be changed)
const getTaxes = () => 0; // (usState, totalCost) => (usState === 'NJ' ? (totalCost * 6.625) / 100 : 0);

const isPack = type => type === CATALOG_PRODUCT_TYPE.PACK;

const sumByProperty = (arr, property) => arr.reduce((sum, e) => sum + (Number(e[property]) || 0), 0);

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const sumByQuantity = arr => sumByProperty(arr, 'quantity');

const sumByPropertyParseInt = (arr, property) => arr.reduce((sum, e) => sum + parseInt(e[property], 10), 0);

const sumByQuantityParseInt = arr => sumByPropertyParseInt(arr, 'quantity');

const addBusinessDays = (date, days) => dayjs(date).addBusinessDays(days);

const addBusinessDaysToDate = (date, days) => addBusinessDays(date, days).toDate();

const dateAfterBusinessDays = (date, days, format = 'MM-DD-YYYY') => addBusinessDays(date, days).format(format);

const normalizeUSZip = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 5) {
    return onlyNums;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5)}`;
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 9)}`;
};

const zipCodeText = isInternational => (isInternational ? 'Postal Code' : 'Zip code');

const thisYear = () => new Date().getFullYear();

const getDefaultQuantities = () => [50, 100, 150, 250, 500, 1000].map(q => ({ quantity: q }));

const contentAppJSON = { 'Content-Type': 'application/json' };

const okAndLog = (actionName, okStatus, data) => {
  log.debug(`${actionName} Action - Exiting`);
  return { result: 'ok', status: okStatus, data };
};

const errorAndLog = (actionName, errorStatus, data) => {
  log.debug(`${actionName} Action - error: ${errorStatus}, data:`, data);
  return { result: 'error', status: errorStatus, data };
};

const addEllipsisToString = (string, length = 40) => truncate(string, { length, separator: ' ' });

// fix for React 16 no firing change events on inputs
// see https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
const changeInputValue = (inputElement, value) => {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
  nativeInputValueSetter.call(inputElement, value);
  const event = new Event('input', { bubbles: true });
  inputElement.dispatchEvent(event);
};
const areDatesOfSameDay = (date1, date2) =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

const isServer = typeof window === 'undefined';
const totalStorageShippingPrice = (qty, storagePrice, shippingPrice) => qty * (storagePrice + shippingPrice);

const conversionDefaults = {
  standardOrderAmount: 4500,
  standardOrderClosingPercent: 50 / 100,
  stardardOrderMargin: 33 / 100,
  samplePackClosingPercent: 10 / 100,
  contactFormClosingPercent: 5 / 100,
  getConversionValue() {
    return this.standardOrderAmount * this.stardardOrderMargin;
  },
  getcontactFormConversionValue() {
    return (this.getConversionValue() * this.contactFormClosingPercent).toFixed(2);
  },
  getRequestSampleConversionValue() {
    return (this.getConversionValue() * this.samplePackClosingPercent).toFixed(2);
  }
};

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
  })
});

const s3 = new S3({
  params: {
    Bucket: process.env.REACT_APP_AWS_UPLOAD_RESOURCES_BUCKET
  }
});

const sizesWithQuantities = sizes =>
  sizes
    .filter(size => size.quantity > 0)
    .sort((a, b) => a.size.sort_order - b.size.sort_order)
    .map(({ size: { name } }) => name)
    .join(', ');

const onlyNaturalNumberOrEmpty = numStr => numStr.replace(/[^\d]|^0(?=\d+)/g, '');

const getShippingPrice = (isInternational, individualPackPrices) =>
  parseFloat(isInternational ? individualPackPrices.international_price : individualPackPrices.us_price);

const getProductPrice = (productOrProof, priceProperty = 'price') =>
  round(parseFloat(productOrProof[priceProperty] ?? 0), 2);

const creditCardTypes = {
  MasterCard: 'MasterCard',
  Visa: 'Visa',
  AmericanExpress: 'AmericanExpress',
  Discover: 'Discover',
  DinersClub: 'DinersClub'
};

const cardImg = cardType => {
  switch (cardType) {
    case creditCardTypes.MasterCard:
      return '/images/account/mastercard.svg';
    case creditCardTypes.Visa:
      return '/images/orders/visa_credit_card@3x.png';
    case creditCardTypes.AmericanExpress:
      return '/images/account/amex.svg';
    case creditCardTypes.Discover:
      return '/images/account/discover.svg';
    case creditCardTypes.DinersClub:
      return '/images/account/diners-club.svg';
    default:
      return '/images/account/new-card.svg';
  }
};

const isOneSize = sizes => sizes.length === 1 && sizes[0].size.name === 'One Size';

const totalShippingPrice = prices => sumByProperty(prices, 'total_price');

const getSelected = arr => arr && arr.find(e => e.selected);

const lowerWithoutSpace = (text, divider = '-') => text.toLowerCase().replace(/ /g, divider);

const imageSrcSet = (imagePath, imageName, extension) => {
  const imgName = imageName
    ? imagePath + lowerWithoutSpace(imageName)
    : imagePath.substring(0, imagePath.lastIndexOf('/')) +
      lowerWithoutSpace(imagePath.substring(imagePath.lastIndexOf('/'), imagePath.lastIndexOf('.')));

  const extFromPath = imagePath.lastIndexOf('.') > 0 ? imagePath.substring(imagePath.lastIndexOf('.') + 1) : undefined;
  const extFromName =
    imageName && imageName.lastIndexOf('.') > 0 ? imageName.substring(imageName.lastIndexOf('.') + 1) : undefined;
  const ext = extension || extFromName || extFromPath || 'png';
  return `${imgName}.${ext} 1x, ${imgName}@2x.${ext} 2x, ${imgName}@3x.${ext} 3x`; // images can also be jpg: TODO
};

const imageSrc3x = (imagePath, imageName, extension) => {
  const imgName = imageName
    ? imagePath + lowerWithoutSpace(imageName)
    : imagePath.substring(0, imagePath.lastIndexOf('/')) +
      lowerWithoutSpace(imagePath.substring(imagePath.lastIndexOf('/'), imagePath.lastIndexOf('.')));

  const extFromPath = imagePath.lastIndexOf('.') > 0 ? imagePath.substring(imagePath.lastIndexOf('.') + 1) : undefined;
  const extFromName =
    imageName && imageName.lastIndexOf('.') > 0 ? imageName.substring(imageName.lastIndexOf('.') + 1) : undefined;
  const ext = extension || extFromName || extFromPath || 'png';
  return `${imgName}@3x.${ext}`;
};

const maxTurnaround = products => {
  const turnarounds = products.map(p =>
    p.production_time === productionTime.standard ? p.product.standard_turnaround_time : p.product.rush_turnaround_time
  );
  log.debug('turnarounds:', turnarounds, 'products:', products);
  return Math.max(...turnarounds);
};

const joinFields = (fields, separator) => fields.filter(Boolean).join(separator);

const getEmployeeShipAddress = (e, includeName) =>
  joinFields(
    [
      includeName && joinFields([e.first_name, e.last_name], ' '),
      joinFields([e.shipping_address1, e.shipping_address2], ' '),
      e.shipping_city,
      joinFields([e.shipping_state, e.shipping_zip], ' '),
      e.shipping_country
    ],
    ', '
  );

const getFirstLastName = fullName => {
  const [first, ...last] = fullName?.split(' ').filter(e => e) || ['', ''];
  return [first, last.join(' ')];
};

const getUsedQuantitiesBySize = (productId, shipmentGroups) => {
  const usedQuantities = {};

  const setUsedQuantities = dOrder =>
    dOrder.proofs.forEach(
      proof =>
        (proof.proof.id ?? proof.proof) === productId &&
        proof.sizes.forEach(sz => (usedQuantities[sz.size] = (usedQuantities[sz.size] || 0) + sz.quantity))
    );

  shipmentGroups.forEach(shipment => (shipment?.directory_orders ?? shipment.directoryOrders).map(setUsedQuantities));

  return usedQuantities;
};

const getShipmentGroupsInfo = shipmentGroups => {
  const domesticPrice = sumBy(shipmentGroups, sg => Number(sg.domestic_price));
  const internationalPrice = sumBy(shipmentGroups, sg => Number(sg.international_price));
  const domesticPriceBeforeDiscount = sumBy(shipmentGroups, sg => Number(sg.domestic_price_without_discount || 0));
  const internationalPriceBeforeDiscount = sumBy(shipmentGroups, sg =>
    Number(sg.international_price_without_discount || 0)
  );

  const orders = shipmentGroups.reduce((ords, sg) => ords.concat(sg.directory_orders), []);
  const [domestic, international] = partition(orders, o => o.directory.shipping_country === 'US');
  const domesticRecipients = new Set(domestic.map(o => o.directory.id)).size;
  const internationalRecipients = new Set(international.map(o => o.directory.id)).size;

  return {
    domestic: {
      price: domesticPrice,
      price_without_discount: domesticPriceBeforeDiscount,
      recipients: domesticRecipients
    },
    international: {
      price: internationalPrice,
      price_without_discount: internationalPriceBeforeDiscount,
      recipients: internationalRecipients
    }
  };
};

const isProductAvailable = recipient => product => {
  const canBeShipped = recipient.shipping_country === 'US' || product.product.can_ship_international;
  return canBeShipped && (isOneSize(product.sizes) || product.sizes.some(s => s.size.id === recipient.size?.id));
};

const commonSearchFields = [
  'first_name',
  'last_name',
  'shipping_address1',
  'shipping_address2',
  'shipping_city',
  'shipping_state',
  'shipping_zip',
  'shipping_country',
  'email',
  'company_name',
  'phone_number',
  'title'
];

const buildSearchQuery = (search = '', searchFields = []) =>
  search ? `search=${encodeURIComponent(search)}&search_fields=${searchFields.join()}` : '';

const getMatchAddress = (address, recipient) => {
  switch (address) {
    case 'domestic':
      return recipient.shipping_country === 'US';
    case 'international':
      return recipient.shipping_country !== 'US';
    default:
      return true;
  }
};

const getSortedBySize = products => products.slice().sort((a, b) => a.size.sort_order - b.size.sort_order);

const getSummaryFromOrders = orders => ({
  numberOfRecipients: orders.length,
  numberOfItems: sumBy(orders, 'quantity'),
  total: sumBy(orders, 'price'),
  totalBeforeDiscount: sumBy(orders, 'priceBeforeDiscount')
});

const getShipmentSummary = shipment => {
  const directoryOrders = shipment.directoryOrders.map(order => {
    const quantity = sum(order.proofs.map(p => sumBy(p.sizes, 'quantity')));
    const recipient = shipment.recipients.find(r => r.id === order.directory);
    const deliveryMethod = order.deliveryMethods.find(dm => dm.selected) || order.deliveryMethods[0];
    const price = sumBy(deliveryMethod.prices?.breakdown, 'total_price');
    const priceBeforeDiscount = sumBy(deliveryMethod.prices?.breakdown, 'total_price_without_discount');
    return {
      quantity,
      recipient,
      price,
      priceBeforeDiscount
    };
  });

  const [domesticOrders, internationalOrders] = partition(
    directoryOrders,
    order => order.recipient.shipping_country === 'US'
  );

  return {
    domestic: getSummaryFromOrders(domesticOrders),
    international: getSummaryFromOrders(internationalOrders)
  };
};

const canSendSwag = (company, totalAmount, paymentProfiles, useCreditsFirst, isAchPrepaid, inputValue) => {
  const { allow_negative_credit_balance: allowNegativeCreditBalance } = company;
  const balance = Number(company.balance);
  const defaultProfile = paymentProfiles.find(p => p.default);
  const hasDefault = defaultProfile && (isAchPrepaid || defaultProfile.payment_method !== paymentMethods.ach);
  console.log(
    'canSendSwag: ',
    hasDefault,
    company,
    totalAmount,
    paymentProfiles,
    useCreditsFirst,
    isAchPrepaid,
    inputValue
  );
  console.log(
    'canSendSwag Eval: ',
    inputValue < totalAmount,
    'original-value:',
    inputValue,
    totalAmount,
    'rounded: ',
    round(inputValue, 2),
    round(totalAmount, 2)
  );
  console.log(
    'typeof-inputValue:',
    typeof inputValue,
    'inputValue:',
    inputValue,
    'typeof-Balance:',
    typeof balance,
    'balance:',
    balance
  );
  console.log(
    'New-condn (Balance >= totalAmount):',
    round(balance, 2) >= round(totalAmount, 2),
    'input < totalAmount condn:',
    round(inputValue, 2) < round(totalAmount, 2),
    'rounded-balance:',
    round(balance, 2),
    'rounded-totalAmount:',
    round(totalAmount, 2)
  );

  console.log(
    'final-condn-no-default:',
    (useCreditsFirst &&
      !(round(inputValue, 2) < round(totalAmount, 2)) &&
      round(balance, 2) >= round(totalAmount, 2)) ||
      (allowNegativeCreditBalance && paymentProfiles.length === 0)
  );

  return (
    hasDefault ||
    (useCreditsFirst &&
      !(round(inputValue, 2) < round(totalAmount, 2)) &&
      round(balance, 2) >= round(totalAmount, 2)) ||
    (allowNegativeCreditBalance && paymentProfiles.length === 0)
  );
};

const colorHexRegex = /^#?([a-fA-F0-9]{6})$/;
const isValidColorHex = color => new RegExp(colorHexRegex).test(color);

const buildUrlWithParam = (location, param, value) => {
  const query = new URLSearchParams(location.search);
  query.set(param, value);
  return { pathname: location.pathname, state: location.state, search: query.toString() };
};

const getFormattedPhoneNumber = (phoneNumber, country) => {
  if (!/^[0-9 ()+-]*$/g.test(phoneNumber)) return '';

  if (phoneNumber[0] === '+') {
    return phoneNumber;
  }

  if (isSupportedCountry(country)) {
    const countryCode = getCountryCallingCode(country);
    const phoneWithCountry = `+${countryCode}${phoneNumber}`;

    if (isValidPhoneNumber(phoneWithCountry)) {
      return phoneWithCountry;
    }
  }

  if (isValidPhoneNumber(`+${phoneNumber}`)) {
    return `+${phoneNumber}`;
  }

  return phoneNumber;
};

const getFilters = ({ selectedOptions, type, compareBy = 'text', returnField = 'value', options }) =>
  options
    .filter(o => [o.type, 'all'].includes(type) && selectedOptions.some(so => so === o[compareBy]))
    .map(o => o[returnField]);

const timeDiff = (date1, date2 = new Date()) => Math.abs(date2 - date1);

const getQtyLeftPerProduct = (products, directoryOrders) => {
  const qtyLeft = {};
  products.forEach(p => {
    qtyLeft[p.id] = substractQuantitiesBreakdowns(
      getQuantitiesBreakdown(p.sizes),
      getUsedQuantitiesBySize(p.id, [{ directoryOrders }])
    );
  });
  return qtyLeft;
};

const doesStrMatches = (str, regexArr) => regexArr.some(regex => RegExp(`^${regex}`, 'i').test(str));

const getIconButton = isChecked =>
  isChecked
    ? ['/images/custom/assets/icons/icons-active/check-icon.svg', 'remove-product']
    : ['/images/custom/assets/icons/add-icon.svg', 'add-product'];

// mapping or redeem pages services
const leyendResponse = { count: 'totalCount', pageIndex: 'page', perPage: 'pageSize', results: 'items' };
const leyendRequest = { limit: 'PageSize', offset: 'Page' };
const paginationResponseConverter = (data, leyend = leyendResponse) =>
  isArray(data)
    ? {
        count: 1000,
        pageIndex: 0,
        perPage: 100,
        results: data
      }
    : {
        count: data[leyend.count],
        pageIndex: data[leyend.pageIndex],
        perPage: data[leyend.perPage],
        results: data[leyend.results]
      };

const paginationRequestConverter = (params, pageBased = true, leyend = leyendRequest) =>
  params
    ? {
        ...params,
        [leyend.limit]: params.limit,
        [leyend.offset]: pageBased ? params.offset / params.limit + 1 : params.offset,
        limit: undefined,
        offset: undefined
      }
    : undefined;

const downloadData = (filename, data) => {
  const blob = new Blob([data], { type: 'text/csv' });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
};

const truncateFileName = (name, length, noExt) => {
  if (!name) return undefined;
  if (name.includes('[...]')) return name;
  if (noExt) return length > 5 && name.length > length ? `${name.substr(0, length - 4)}[...]` : name;

  const ext = name.substring(name.lastIndexOf('.') + 1, name.length).toLowerCase();
  let truncated = name.replace(`.${ext}`, '');
  if (truncated.length <= length) return name;

  truncated = truncated.substr(0, length) + (name.length > length ? '[...]' : '');
  return `${truncated}.${ext}`;
};

const creditColor = credit => {
  switch (true) {
    case credit < 0:
      return 'red';
    case credit > 0:
      return 'green';
    default:
      return 'black';
  }
};
const useScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const onScroll = event => setScrollTop(event.target.scrollTop);
  return [scrollTop, { onScroll }];
};

// Setting a cookie with a shared domain (e.g., .example.com)
const setAuthToken = (name, email, intercomHastag, account, userInitial, backgroundColor, membershipImage) => {
  // Set the cookie with a shared domain and path
  document.cookie = `name=${name};domain=.swagup.com;path=/`;
  document.cookie = `email=${email};domain=.swagup.com;path=/`;
  document.cookie = `intercomHastag=${intercomHastag};domain=.swagup.com;path=/`;
  document.cookie = `account=${account};domain=.swagup.com;path=/`;
  document.cookie = `userInitial=${userInitial};domain=.swagup.com;path=/`;
  document.cookie = `backgroundColor=${backgroundColor};domain=.swagup.com;path=/`;
  document.cookie = `membershipImage=${membershipImage};domain=.swagup.com;path=/`;
};

const clearAuthCookies = () => {
  if (typeof window !== 'undefined') {
    setAuthToken('', '', '', '', '', '', '');
    document.cookie = 'sessionCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
};

const isVideoUrl = url => {
  return !isEmpty(url) && /\.(mp4|mov|avi|mkv)$/.test(url.toLowerCase());
};

const isImageUrl = url => {
  return !isEmpty(url) && /\.(jpeg|jpg|gif|png)$/.test(url.toLowerCase());
};

const isVideoType = fileType => {
  return fileType === 'Video';
};

const isImageType = fileType => {
  return fileType === 'Image';
};

const sortByType = (a, b) => {
  const secondCriteria = Number(a.order) < Number(b.order) && !isVideoType(b.file_type) ? -1 : 1;
  return isVideoType(a.file_type) ? -1 : secondCriteria;
};

const generateUniqueId = () => {
  return (
    Date.now().toString(36) +
    Math.random()
      .toString(36)
      .substr(2, 5)
  );
};

const convertArrToObject = (arr, key1, key2) => {
  const obj = {};
  arr.forEach(x => {
    obj[x[key1]] = x[key2];
  });
  return obj;
};

const generateFilterOptions = arr => {
  const obj = arr?.reduce((acc, curr) => {
    acc[curr] = curr;
    return acc;
  }, {});
  return obj || {};
};

const getSearchParamsObject = search => {
  // Get the search parameters from the URL
  const searchParams = new URLSearchParams(search);

  // Convert the search parameters to an object
  const paramsObj = {};
  searchParams.forEach((value, key) => {
    paramsObj[key] = value;
  });

  return paramsObj;
};

const calculateTimeRemaining = endDate => {
  const start = dayjs();
  const end = dayjs(endDate, 'MM/DD/YYYY').add(1, 'day');
  const differenceSeconds = end.diff(start, 'second');
  return differenceSeconds;
};

const getStatusDescription = order => {
  // , BUSINESS_DAYS_TO_START_PRODUCTION, TURNAROUND_TIME_PACKING, directoryOrders) => {
  const { sales_order } = order;
  if (!isEmpty(sales_order)) {
    switch (sales_order.status) {
      case SORDStatuses.pendingFinalCheck:
      case SORDStatuses.pendingPO:
        return orderStatuses.orderPlaced;
      case SORDStatuses.partialPO:
      case SORDStatuses.POComplete:
      case SORDStatuses.clientApprovedDelay:
        return orderStatuses.inProduction;
      case SORDStatuses.QAComplete:
      case SORDStatuses.readyToPack:
        return orderStatuses.qualityCheck;
      case SORDStatuses.missionComplete:
        return orderStatuses.completed;
      default:
        return orderStatuses.canceled;
    }
  }
  return orderStatuses.orderPlaced;
  // const today = dayjs();
  // const maxTurnaroundTime = maxTurnaround(order.products);
  // const { invoice } = order;
  // const notificationDate = dayjs(invoice.paid_date || invoice.due_date || invoice.created_at || undefined);

  // const startProductionDate = dayjs(addBusinessDaysToDate(notificationDate, BUSINESS_DAYS_TO_START_PRODUCTION));
  // const qualityCheckDate = dayjs(addBusinessDaysToDate(notificationDate, maxTurnaroundTime - TURNAROUND_TIME_PACKING));
  // const readyShippingDate = dayjs(addBusinessDaysToDate(notificationDate, maxTurnaroundTime));

  // switch (true) {
  //   case isReachedDate(today, readyShippingDate): {
  //     if (itsAllShipped(directoryOrders, order.products)) return orderStatuses.completed;
  //     return orderStatuses.shippingSwag;
  //   }
  //   case isReachedDate(today, qualityCheckDate):
  //     return orderStatuses.qualityCheck;
  //   case isReachedDate(today, startProductionDate):
  //     return orderStatuses.inProduction;
  //   default:
  //     return orderStatuses.orderPlaced;
  // }
};

export {
  addBusinessDaysToDate,
  addEllipsisToString,
  addThousandsSeparator,
  areDatesOfSameDay,
  arrowGenerator,
  buildSearchQuery,
  buildUrlWithParam,
  canSendSwag,
  cardImg,
  changeInputValue,
  contentAppJSON,
  conversionDefaults,
  commonSearchFields,
  creditCardTypes,
  dateAfterBusinessDays,
  errorAndLog,
  formatDate,
  fullFormatDate,
  getDefaultQuantities,
  getEmployeeShipAddress,
  getFilters,
  getFirstLastName,
  getFormattedPhoneNumber,
  getMatchAddress,
  getProductionTimeText,
  getProductPrice,
  getQtyLeftPerProduct,
  getSelected,
  getShipmentGroupsInfo,
  getShipmentSummary,
  getShippingPrice,
  getTaxes,
  getUsedQuantitiesBySize,
  getIconButton,
  imageSrcSet,
  imageSrc3x,
  isOneSize,
  isPack,
  isProductAvailable,
  isServer,
  doesStrMatches,
  isValidColorHex,
  joinFields,
  lowerWithoutSpace,
  maxTurnaround,
  moneyStr,
  normalizeExDate,
  normalizeUSZip,
  onlyNaturalNumberOrEmpty,
  okAndLog,
  reorderDateWithSlicing,
  s3,
  sleep,
  sizesWithQuantities,
  getSortedBySize,
  sumByProperty,
  sumByPropertyParseInt,
  sumByQuantity,
  sumByQuantityParseInt,
  thisYear,
  thousandsSeparatedAndFixed,
  timeDiff,
  totalShippingPrice,
  totalStorageShippingPrice,
  zipCodeText,
  paginationResponseConverter,
  paginationRequestConverter,
  downloadData,
  truncateFileName,
  creditColor,
  useScrollTop,
  intStr,
  setAuthToken,
  clearAuthCookies,
  isImageUrl,
  isVideoUrl,
  isVideoType,
  isImageType,
  sortByType,
  generateUniqueId,
  convertArrToObject,
  generateFilterOptions,
  getSearchParamsObject,
  calculateTimeRemaining,
  getStatusDescription
};
