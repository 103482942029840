/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { Link } from 'react-router-dom';
import { ArrowDropDown, ArrowDropUp, Check } from '@material-ui/icons';
import { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { isEmpty, isNumber, round } from 'lodash';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Header, PreviousStep, recalculateRecommendedCredit, SkipStep } from './common';
import { imageSrcSet, moneyStr, sumByQuantity, totalShippingPrice } from '../../../../../../helpers/utils';
import Tooltip from '../../../../../shared/Tooltip';
import { useOrder } from '../../OrderContext';
import NumberFormatCustom from '../../../../../UI/Inputs/NumberFormatCustom';
import { useQueryParams } from '../../../../../../hooks';
import toErrorPage from '../../../../../../helpers/toErrorPage';
import { fetchIndividualPackPrices } from '../../../../../../actions';
import { StylessButton } from '../../../../../buttons';
import shippingPricesApi from '../../../../../../apis/swagup/shippingPrices';
import apiPaths from '../../../../../../helpers/apiPaths';

const commonText = { fontFamily: 'Gilroy-Medium', fontSize: 12 };

const useStyles = makeStyles({
  addFundsPanel: { padding: '20px 24px', border: '1px solid #EBEDF0', borderRadius: 16 },
  backTitle: { ...commonText, color: '#0b1829' },
  currentTitle: { ...commonText, color: '#787b80' },
  cardImageWrapper: { width: 48, height: 48, position: 'relative' },
  cardImage: { width: '100%', height: '100%', objectFit: 'contain' },
  swagCardText: {
    color: '#787B80',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    '& strong': {
      fontWeight: 600
    }
  },
  selectOptionText: {
    color: '#0B1829',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: 32
  },
  otherAmountTitle: {
    color: '#3577D4',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: 12
  },
  selectOptionPanel: { paddingTop: 28, paddingLeft: 12 },
  selectOptions: { paddingTop: 0 },
  selectOption: {
    color: '#3577D4',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    border: '1px solid #3577D4'
  },
  newSelectedOption: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    border: ({ selected }) => (selected ? '1px solid #9846DD' : 'solid 1px #3577D4'),
    backgroundColor: '#ffffff',
    color: ({ selected }) => (selected ? '#9846DD !important' : '#3577D4'),
    '&:hover': {
      // border: '1px solid #9846DD',
      backgroundColor: 'rgba(152, 70, 221, 0.2)'
    }
  },
  estimatedButtonPanel: { paddingTop: 16 },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30
  },
  checkIcon: {
    width: '12px',
    height: '12px',
    color: '#4CAF50',
    marginTop: 4
  },
  stepNumberIcon: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    background: '#c7e3c8'
  },
  fundsMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  fundsCard: {
    position: 'relative',
    width: 304,
    height: 80,
    border: ({ selected }) => (selected ? '1px solid #3577D4' : '1px solid #989EA4'),
    background: ({ selected }) => (selected ? ' rgba(53, 119, 212, 0.08)' : 'transparent'),
    borderRadius: 10,
    padding: '16px 20px'
  },
  recommended: {
    position: 'absolute',
    height: 20,
    borderRadius: 10,
    border: '1px solid #4CAF50',
    background: '#E8F5E9',
    padding: '4px 12px',
    left: 20,
    top: -10
  },
  recommendedText: {
    color: '#4CAF50',
    fontSize: 10,
    lineHeight: '10px',
    textAlign: 'center'
  },
  fundsCardTitle: {
    color: ({ selected }) => (selected ? '#3577D4' : '#4A4F54'),
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '16px',
    textAlign: 'left',
    marginBottom: 16
  },
  fundsCardSubtitle: {
    color: ({ selected }) => (selected ? '#3577D4' : '#4A4F54'),
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left'
  },
  selected: {
    opacity: 0.5
  },
  noOpacity: {
    opacity: 1
  },
  test: {
    backgroundColor: 'red'
  },
  estCredits: {
    color: '#3577D4',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    height: 32,
    backgroundColor: '#EBF1FB',
    padding: '0px 16px',
    borderWidth: 1,
    '&:hover': {
      backgroundColor: '#EBF1FB'
    }
  }
});

const foundOptions = [1000, 5000, 10000];

const StepsWrapper = () => {
  const order = useOrder();
  const query = useQueryParams();
  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.btnWrapper}>
      <Grid item>
        <Link to={{ pathname: `/orders-requested/${order.id}/review-order`, search: query.toString() }}>
          <PreviousStep />
        </Link>
      </Grid>
      {/* <Grid item>
        <Link
          to={{ pathname: `/orders-requested/${order.id}/payment-method`, search: query.toString() }}
          onClick={() => {
            setSelectedFund(); // Setting Input field to previously entered value on skip step
          }}
        >
          <SkipStep />
        </Link>
      </Grid> */}
    </Grid>
  );
};

const CustomInputField = ({
  width,
  height,
  placeholderColor,
  selectedFund,
  inputValue,
  setInputValue,
  setInvalid,
  minValue = 0
}) => {
  const [error, setError] = useState();
  const handleInputChange = e => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      setInputValue(value);
    }
  };

  useEffect(() => {
    if (!inputValue || Number(inputValue) >= minValue) {
      setInvalid(false);
      setError();
    } else {
      setInvalid(true);
      setError(`Must add at least ${minValue}`);
    }
  }, [inputValue, minValue, setInvalid]);

  const classes = useStyles();

  return (
    <div>
      <TextField
        value={inputValue}
        onChange={handleInputChange}
        variant="outlined"
        style={{
          borderRadius: 32,
          paddingLeft: 8
        }}
        InputProps={{
          style: { color: '#3577D4', width: width || '200px', height: height || '48px' },
          inputComponent: NumberFormatCustom,
          placeholder: 'Enter an amount'
        }}
        InputLabelProps={{ style: { color: placeholderColor || '#3577D4' } }}
        className={selectedFund !== null ? classes.noOpacity : classes.selected}
        error={error}
      />
      <Grid container justifyContent="center">
        <div style={{ position: 'relative', width: '100%' }}>
          <p
            style={{
              color: '#F44336',
              top: 4,
              fontFamily: 'Inter',
              fontSize: 10,
              fontWeight: 400,
              position: 'absolute',
              textAlign: 'center',
              width: '100%',
              left: 4
            }}
          >
            {error}
          </p>
        </div>
      </Grid>
    </div>
  );
};

const FundSelected = () => {
  const classes = useStyles({ selected: true });
  return (
    <Grid className={classes.fundsMessage}>
      <Typography variant="body4RegularInter" style={{ color: '#4CAF50', marginRight: '7px' }}>
        Funds added to order
      </Typography>
      <Grid container alignItems="center" justifyContent="center" className={classes.stepNumberIcon}>
        <Grid item>
          <Check className={classes.checkIcon} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const FundsCard = ({ value, title, subtitle, tooltip, onSelect, selected, isRecommended }) => {
  const classes = useStyles({ selected });
  return (
    <StylessButton onClick={onSelect}>
      <Box className={classes.fundsCard}>
        {isRecommended && (
          <Box className={classes.recommended}>
            <Typography variant="body4RegularInter" className={classes.recommendedText}>
              Better choice
            </Typography>
          </Box>
        )}
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="body4RegularInter" className={classes.fundsCardTitle}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body4RegularInter" className={classes.fundsCardTitle}>
              {value}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={3}>
          <Grid item>
            <Typography variant="body4RegularInter" className={classes.fundsCardSubtitle}>
              {subtitle}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={tooltip} placement="right" width={180} color="blue">
              <img
                src="/images/account/outline-info.svg"
                alt="Confirmed"
                style={{ width: 12, height: 12, marginBottom: -2 }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </StylessButton>
  );
};

const FundsTextDetails = () => {
  const data = {
    fundsHeading: 'Why add additional funds to my Swag Card?',
    fundsDetails: [
      { id: 1, fundsText: 'Ensures transactions never fail' },
      { id: 2, fundsText: 'Allows you to automate your shipments' },
      { id: 3, fundsText: 'Helps you earn rewards points faster' }
    ]
  };
  return (
    <Grid align="start" style={{ paddingTop: 24, paddingBottom: 16 }}>
      <Typography variant="body4SemiBoldInter" style={{ color: '#4A4F54', fontWeight: 600, marginBottom: 12 }}>
        {data.fundsHeading}
      </Typography>
      {data.fundsDetails.map(item => (
        <Grid key={item.id}>
          <Typography variant="body4RegularInter" style={{ color: '#4A4F54', fontWeight: 40, marginBottom: 2 }}>
            {item.id}. {item.fundsText}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

// const EstimatedCreditsBtn = ({ text, number, icon, onClick }) => {
//   const classes = useStyles({ selected: true });
//   return (
//     <Button variant="secondary" size="small" className={classes.estCredits} onClick={() => onClick(number)}>
//       {text} {moneyStr(number)} {icon}
//     </Button>
//   );
// };

const FundsButton = ({ selected, onClick, text, disabled }) => {
  const classes = useStyles({ selected });
  return (
    <Button
      variant="secondary"
      size="small"
      className={classes.newSelectedOption}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

const recipient = {
  shipping_address1: '361 12th Ave Apartment #1',
  shipping_address2: null,
  shipping_city: 'San Francisco',
  shipping_country: 'US',
  shipping_state: 'CA',
  shipping_zip: '94108'
};

const AddFunds = ({ setInvalid, isCurrentView, setIsCreditsLoading }) => {
  const [message, setMessage] = useState(false);
  const [selectedFund, setSelectedFund] = useState();
  const [customValue, setCustomValue] = useState();
  const [showOptions, setShowOptions] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const [individualPackPrices, setIndividualPackPrices] = useState({});
  const [recommendedCredit, setRecommendedCredit] = useState(0);

  const { defaultToRecommendedCredits } = useFlags();

  const history = useHistory();

  const { setFunds, funds, total_shipping: totalShipping, warehouseProofs } = useOrder();

  useHistory();
  const location = useLocation();
  const query = useQueryParams();

  const addedFunds = query.get('added_funds');

  useEffect(() => {
    const customFunds = selectedFund || customValue;
    if (selectedFund !== undefined || customValue !== undefined)
      if (customFunds > 0) {
        query.set('added_funds', customFunds);
        history.replace({ ...location, search: query.toString() });
      } else {
        query.delete('added_funds');
        history.replace({ ...location, search: query.toString() });
      }
  }, [selectedFund, customValue]);

  useEffect(() => {
    if (defaultToRecommendedCredits && isCurrentView && !formModified) {
      setSelectedFund(recommendedCredit);
      setFormModified(true);
    }
  }, [recommendedCredit, defaultToRecommendedCredits, isCurrentView, formModified]);

  useEffect(() => {
    if (addedFunds && isNumber(Number(addedFunds))) {
      setFunds(Number(addedFunds));
      setMessage(Number(addedFunds) > totalShipping);
    } else {
      setFunds(Number(totalShipping));
    }

    if (selectedFund === undefined && customValue === undefined) {
      if (foundOptions.includes(Number(addedFunds))) setSelectedFund(Number(addedFunds));
      else {
        setCustomValue(Number(addedFunds));
      }
    }
  }, [addedFunds, customValue, selectedFund, setFunds, totalShipping]);

  const selectFund = f => {
    setCustomValue();
    setSelectedFund(f === selectedFund ? 0 : f);
  };

  const dispatch = useDispatch();

  const fetchIndividualPackPricesData = useCallback(async () => {
    const response = await dispatch(fetchIndividualPackPrices());
    if (response.result === 'ok') {
      setIndividualPackPrices(response.data.results[0]);
    } else {
      toErrorPage(response.result, history);
    }
  }, [dispatch, history]);

  const { data: bulkPricingData, isLoading } = useQuery(
    [apiPaths.shippingPrices, warehouseProofs],
    async () => {
      const shippingPayload = [
        {
          quantities: warehouseProofs.map(({ proof, sizes }) => ({
            account_product: proof.product.id,
            quantity: sumByQuantity(sizes)
          })),
          ...recipient
        }
      ];
      return shippingPricesApi.fetch(shippingPayload);
    },
    {
      enabled: !!warehouseProofs?.length > 0 && warehouseProofs.every(({ sizes }) => sumByQuantity(sizes) > 0)
    }
  );

  useEffect(() => {
    setIsCreditsLoading(isLoading);
  }, [isLoading, setIsCreditsLoading]);

  useEffect(() => {
    fetchIndividualPackPricesData();
    // fetchStoragePricesData();
    // fetchBulkPricesToCalifornia();
  }, [fetchIndividualPackPricesData]);

  useEffect(() => {
    if (!isEmpty(individualPackPrices)) {
      const bulkShippingPrice =
        bulkPricingData?.data && bulkPricingData.data[0]
          ? totalShippingPrice(bulkPricingData.data[0].delivery_methods[0].prices.breakdown)
          : 5;

      const total = recalculateRecommendedCredit(
        totalShipping,
        warehouseProofs,
        individualPackPrices,
        bulkShippingPrice
      );

      const correctWarehouse = warehouseProofs.every(({ sizes }) => sumByQuantity(sizes) > 0);

      setRecommendedCredit(correctWarehouse ? total : totalShipping);
    }
  }, [individualPackPrices, bulkPricingData, totalShipping, warehouseProofs]);

  const classes = useStyles({ selected: true });

  return (
    <Box>
      <Header
        header="Add funds to cover shipment costs"
        subtitle="Swag card funds can also be used to cover future order costs"
        image={
          <Box className={classes.cardImageWrapper} style={{ marginLeft: 8 }}>
            <img
              srcSet={imageSrcSet('/images/swag-card/swag-card.png')}
              alt="swag-card"
              className={classes.cardImage}
            />
          </Box>
        }
      />
      <Box className={classes.addFundsPanel}>
        <Grid container>
          <Grid item xs>
            <Typography variant="body2MediumInter" className={classes.selectOptionText}>
              You must choose at least enough to cover existing shipments
            </Typography>
          </Grid>
          <Grid item>{message && <FundSelected />}</Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item>
            <FundsCard
              title="Existing shipments"
              value={moneyStr(totalShipping)}
              selected={
                funds === undefined ||
                (round(funds, 2) !== round(recommendedCredit, 2) && round(funds, 2) === round(totalShipping, 2))
              }
              subtitle="Based on shipments created so far"
              tooltip="Future shipments will require additional funds"
              onSelect={() => setSelectedFund(totalShipping)}
            />
          </Grid>
          <Grid item>
            <FundsCard
              title="Recommended"
              value={moneyStr(recommendedCredit)}
              selected={round(funds, 2) === round(recommendedCredit, 2)}
              subtitle="Based on overall order size"
              tooltip="Estimate based on shipping all products within the US"
              onSelect={() => setSelectedFund(recommendedCredit)}
              isRecommended
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Box className={classes.selectOptionPanel}>
            <StylessButton onClick={() => setShowOptions(prev => !prev)}>
              <Grid container alignItems="center">
                <Grid item>
                  <p className={classes.otherAmountTitle}>Or enter a custom amount</p>
                </Grid>
                <Grid item>
                  {showOptions ? (
                    <ArrowDropUp className={classes.selectOptions} style={{ marginTop: -6 }} />
                  ) : (
                    <ArrowDropDown className={classes.selectOptions} style={{ marginTop: -6 }} />
                  )}
                </Grid>
              </Grid>
            </StylessButton>

            {showOptions && (
              <Grid container alignItems="center" spacing={4} className={classes.selectOptions}>
                {foundOptions.map(fo => (
                  <Grid key={`${fo}`} item>
                    <FundsButton
                      selected={Number(selectedFund) === fo}
                      onClick={() => selectFund(fo)}
                      text={moneyStr(fo)}
                      disabled={fo <= totalShipping}
                    />
                  </Grid>
                ))}
                <CustomInputField
                  width={200}
                  height={48}
                  placeholderColor="#3577D4"
                  selectedFund={foundOptions.includes(Number(customValue))}
                  inputValue={customValue || ''}
                  setInputValue={value => {
                    setCustomValue(value);
                    if (value) setSelectedFund();
                  }}
                  setMessage={setMessage}
                  minValue={totalShipping}
                  setInvalid={setInvalid}
                />
              </Grid>
            )}
            <Grid container alignItems="flex-start" justifyContent="space-between">
              <FundsTextDetails />
            </Grid>
          </Box>
          {/* <Box className={classes.estimatedButtonPanel}>
              <Grid container spacing={8}>
                <Grid item>
                  <Box>
                    <Grid container alignItems="center">
                      <Grid item alignItems="center" style={{ display: 'flex' }}>
                        <EstimatedCreditsBtn
                          text="Estimated credits"
                          number={recommendedCredit}
                          icon={
                            <span style={{ paddingLeft: 3, paddingTop: 3 }}>
                              <Tooltip
                                title="Based on shipping all of your products within the US"
                                placement="right"
                                width={180}
                                color="blue"
                              >
                                <img
                                  src="/images/account/outline-info.svg"
                                  alt="Confirmed"
                                  style={{ width: 12, height: 12 }}
                                />
                              </Tooltip>
                            </span>
                          }
                          onClick={handleNumClick}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid item xs />
                </Grid>
              </Grid>
            </Box> */}
        </Grid>
      </Box>
      <StepsWrapper />
    </Box>
  );
};

export default AddFunds;
