import catalogApi from '../apis/swagup/catalog';
import formOrdersApi from '../apis/swagup/formOrders';
import apiPaths from '../helpers/apiPaths';
import DashBoard from '../apis/DashBoard';
import { errorAndLog, contentAppJSON } from '../helpers/utils';
import gtm from '../utils/gtm';
import {
  CATALOG_API_CALL_START,
  CATALOG_API_CALL_SUCCESS,
  CATALOG_API_CALL_ERROR,
  CATALOG_INITIALIZE,
  CATALOG_LOAD,
  CATALOG_PURCHASE
} from './types';
import { refreshPrices } from './cart';

export const initializeCatalog = (isBulk, template = null, wrapperFunc = data => data) => async (
  dispatch,
  getState
) => {
  let catalog = getState().catalog.items;

  dispatch({ type: CATALOG_INITIALIZE, payload: { isBulk } });

  if (!catalog)
    try {
      dispatch({ type: CATALOG_API_CALL_START });

      catalog = (await catalogApi.fetch()).results;
      dispatch({ type: CATALOG_API_CALL_SUCCESS });
    } catch (error) {
      dispatch({ type: CATALOG_API_CALL_ERROR, payload: error });
      throw error;
    }
  catalog = wrapperFunc(catalog);

  dispatch({ type: CATALOG_LOAD, payload: { catalog, template } });
  dispatch(refreshPrices());
};

export const submitOrder = (products, info, isBulk) => async dispatch => {
  try {
    dispatch({ type: CATALOG_API_CALL_START });

    const order = await formOrdersApi.submit(products, info, isBulk);
    gtm.submitCatalogForm(order);

    dispatch({ type: CATALOG_PURCHASE, payload: { order } });
    dispatch({ type: CATALOG_API_CALL_SUCCESS });
  } catch (error) {
    dispatch({ type: CATALOG_API_CALL_ERROR, payload: error });
    throw error;
  }
};

export const fetchActiveColections = async () => {
  log.debug('fetch active collections Action - Entering');
  try {
    const apiCall = await DashBoard.get(apiPaths.collections, contentAppJSON).then(res => {
      if (res.status === 200) {
        return res.data;
      }
    });
    return apiCall;
  } catch (e) {
    return errorAndLog('fetchActiveCollection', e.status, e.data);
  }
};
