import { date, email as emailFunc, format, length, numericality, required, addValidator } from 'redux-form-validators';
import { CITY_RE, NAME_RE } from '../utils/regexs';
import log from '../logger';
import { getZipValidationRegex } from '../components/global/Forms/commonValidations';
import { joinFields } from './utils';

const requireBilling = isRequired => (isRequired ? [required({ msg: 'Required' })] : []);

const stateValidation = (country, isBilling) =>
  ['US', 'USA'].includes(country)
    ? [
        required({ msg: 'Required' }),
        format({
          // eslint-disable-next-line max-len
          with: /^((A[AEKLPRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$/i,
          message: { defaultMessage: 'Must be a US state' }
        })
      ]
    : [length({ max: 100 }), ...requireBilling(isBilling || ['CA', 'MX', 'IN'].includes(country))];

const zipValidation = (country, isBilling) => {
  const { regex, message } = getZipValidationRegex(country);
  return regex
    ? [
        required({ msg: 'Required' }),
        format({
          with: regex,
          message: { defaultMessage: message }
        })
      ]
    : [length({ max: 64 }), ...requireBilling(isBilling)];
};

const runFieldValidations = (fieldsToValidate, values, validations) => {
  const errors = {};
  if (fieldsToValidate) {
    fieldsToValidate.forEach(field => {
      const fieldValue = values[field];
      const fieldValidations = validations(field, values);
      const error = fieldValidations?.map(fv => fv(fieldValue, values)).find(x => x);
      if (error) errors[field] = error;
    });
  }
  log.debug('runFieldValidations, fieldsToValidate:', fieldsToValidate, 'values:', values, 'errors:', errors);
  return errors;
};

const trimValidator = addValidator({
  defaultMessage: 'Required',
  validator(option) {
    return option.fullname.trimStart().length > 0;
  }
});

const fieldValidation = (field, values) => {
  switch (field) {
    case 'account_type':
      return [required({ msg: 'Please, provide a valid account type' })];

    case 'amount':
      return [
        required({ msg: 'Required' }),
        numericality({
          int: true,
          '>=': 50,
          msg: { greaterThanOrEqualTo: 'Must be at least 50 swag packs' }
        })
      ];

    case 'company':
    case 'ccname':
    case 'cvc':
    case 'how_do_you_plan_to_use_swag_packs':
    case 'shipping_address1':
      return [required({ msg: 'Required' }), length({ max: 100 })];

    case 'first_name':
    case 'last_name':
      return values.first_name || values.last_name
        ? [
            length({ max: 100 }),
            trimValidator({ fullname: joinFields([values.first_name, values.last_name]) }),
            format({
              with: NAME_RE,
              message: { defaultMessage: 'Letters only' }
            })
          ]
        : [required({ msg: 'First name or Last name is required' })];

    case 'name':
    case 'shipping_country':
      return [
        required({ msg: 'Required' }),
        length({ max: 100 }),
        format({
          with: NAME_RE,
          message: { defaultMessage: 'Letters only' }
        })
      ];

    case 'shipping_city':
      return [
        required({ msg: 'Required' }),
        length({ max: 100 }),
        format({
          with: CITY_RE,
          message: { defaultMessage: 'Letters and numbers only' }
        })
      ];

    case 'budget':
      return [
        required({ msg: 'Required' }),
        numericality({
          int: true,
          '>': 0
        })
      ];

    case 'canumberc':
      return [required({ msg: 'Please, provide a valid account number' })];

    case 'ccnumber':
      return [required({ msg: 'Please, provide a payment method' })];

    case 'comments':
    case 'title':
      return [length({ max: 255 })];

    case 'date':
      return [
        date({
          format: 'mm/dd/yyyy',
          '>': 'today',
          allowBlank: true
        })
      ];

    case 'email':
      return [emailFunc({ allowBlank: true })];

    case 'exdate':
      return [
        required({ msg: 'Please, provide a valid credit card expiration' }),
        date({
          format: 'mm/yy',
          '>': 'today',
          msg: 'date is invalid'
        })
      ];

    case 'message':
      return [required({ msg: 'Required' }), length({ min: 3, msg: 'Must be at least 3 characters' })];

    case 'subject':
      return [
        required({ msg: 'Required' }),
        length({ min: 3, max: 255, msg: 'Must be a text between 3 and 255 characters' })
      ];

    case 'naccount':
      return [required({ msg: 'Please, provide a valid name for the account' })];

    case 'phone_number':
      return [
        length({ max: 50 }),
        ...(['US', 'USA'].includes(values.shipping_country) ? [] : [required({ msg: 'Required' })])
      ];

    case 'quantity':
      return [
        required({ msg: 'Required' }),
        numericality({
          int: true,
          '>=': 50,
          msg: { greaterThanOrEqualTo: 'Must be at least 50 swag packs or bulk items' }
        })
      ];

    case 'rnumber':
      return [required({ msg: 'Please, provide a valid account routing number' })];

    case 'shipping_state':
      return stateValidation(values.shipping_country);

    case 'shipping_zip':
      return zipValidation(values.shipping_country);

    case 'size':
      return [length({ max: 3 })];

    default:
      return undefined;
  }
};

// this is a hack that must be changed/improved if we have
// to consider different validations for more fields
const fieldValidationEmailRequired = (field, values) => {
  switch (field) {
    case 'email':
      return [required({ msg: 'Required' }), emailFunc()]; // when email is required

    default:
      return fieldValidation(field, values);
  }
};

export { fieldValidation, runFieldValidations, fieldValidationEmailRequired };
