/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { Animated } from 'react-animated-css';
import { Button } from '@swagup-com/components';
import isEmpty from 'lodash/isEmpty';
import Confetti from 'react-canvas-confetti';
import gtm from '../../../utils/gtm';
import { DecorationFieldEdit, ImageDialog, ZoomIconSearch } from './ProofDetailsSectionsExtension';
import CustomizedSelect from './CustomizedSelect';
import { hasExtension, formatBytes } from './common';
import styles from './styles/itemsDecorationPanel';
import { PRODUCT_STATUS_OPTIONS } from '../../../utils/constants';
import { StylessButton } from '../../buttons';

const useStyles = makeStyles(styles);

const hasArtwork = decoration => decoration.Artwork1 && decoration.Artwork1_S3_Location;

const ActionSection = ({
  children,
  onAccept,
  onDecline,
  acceptText,
  declineText,
  declineVariant,
  oneButton,
  join,
  disabled
}) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="flex-end" alignItems="flex-end" className={classes.continueSection}>
      {!oneButton && (
        <Grid item>
          <Button
            variant={declineVariant}
            size="small"
            onClick={() => {
              onDecline();
              gtm.onClickContinue('Delete decoration clicked');
            }}
            className={classes.wizardButtons}
            disabled={disabled}
          >
            {declineText}
          </Button>
        </Grid>
      )}
      {!join && <Grid item xs />}
      <Grid item>
        {children || (
          <Button variant="primary" size="small" className={classes.wizardButtons} onClick={onAccept}>
            {acceptText}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const FileUploadZone = ({ handleFileUpload, children, disabled, renderComponent }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled,
    onDrop: handleFileUpload,
    accept: 'image/*, application/pdf, application/illustrator, application/postscript'
  });
  return (
    <Grid container {...getRootProps()} style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
      <input {...getInputProps()} disabled={disabled} />
      {renderComponent ? renderComponent({ isDragActive }) : children}
    </Grid>
  );
};

const ArtworkDisplay = ({ decoration, isDecoration }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const nonImage = isDecoration
    ? hasExtension(decoration?.Artwork1, ['.pdf', '.eps', '.ai'])
    : decoration?.Artwork1_S3_Location;
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <div className={classes.artworkContainer} style={isDecoration ? { border: '0.5px solid #E5E7E8' } : {}}>
            <Grid style={{ display: 'flex', padding: '12px 0px', width: '100%', justifyContent: 'space-evenly' }}>
              <Typography style={{ fontFamily: 'Inter', color: '#3577D4', fontSize: 14 }}>
                {nonImage === undefined || nonImage === '' ? 'moontest.svg ' : decoration?.artwork?.fileName}
              </Typography>
              <Typography style={{ fontFamily: 'Inter', fontSize: 14 }}>
                {nonImage === undefined || nonImage === '' ? '132MB' : decoration?.artwork?.fileSize}
              </Typography>
            </Grid>
            <Grid style={{ padding: 4, width: '100%' }}>
              <StylessButton onClick={() => setOpenDialog(true)} width="100%" style={{ cursor: 'zoom-in' }}>
                <div className={classes.container384x284}>
                  <img src={decoration?.Artwork1_S3_Location} alt="swagup" className={classes.logo} />
                  <ZoomIconSearch classes={classes} />
                </div>
              </StylessButton>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <ImageDialog
        img={
          !isDecoration
            ? decoration?.Artwork1_S3_Location || '/images/public/moontest.svg'
            : '/images/public/moontest.svg'
        }
        largeImageSrc={decoration?.Artwork1_S3_Location || '/images/public/moontest.svg'}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </>
  );
};

const ItemsDecorationPanel = ({
  item,
  tabValue,
  decoration,
  updateDecoration,
  setUpdateDecoration,
  disabled,
  disableDecorations,
  readOnly,
  explicitDisableFields = []
}) => {
  const [uploadWarning, setUploadWarning] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const classes = useStyles({ disabled, uploadWarning, deleteWarning });

  const handleFileUpload = acceptedFiles => {
    const image = acceptedFiles[0];
    const filePath = URL.createObjectURL(image);
    const size = formatBytes(image.size);
    if (image) {
      const name = image.name.replace(/\s+/g, '-');
      const searchedDecoration = updateDecoration?.map((i, index) => {
        if (index === tabValue) {
          return {
            ...i,
            artwork: { image, fileName: name, fileSize: size },
            Artwork1: name,
            Artwork1_S3_Location: filePath
          };
        }
        return { ...i };
      });
      updateDecoration[tabValue] = searchedDecoration[tabValue] || {
        artwork: { image, fileName: name, fileSize: size },
        Artwork1: name,
        Artwork1_S3_Location: filePath
      };
      setUpdateDecoration([...updateDecoration]);
    }

    setUploadWarning(!hasExtension(image.name, ['.svg', '.eps', '.ai']));
  };

  const handleTextChange = ({ target: { name, value } }) => {
    const searchedDecoration = updateDecoration.map((i, index) => {
      if (index === tabValue) {
        return { ...i, [name]: value };
      }
      return { ...i };
    });
    updateDecoration[tabValue] = searchedDecoration[tabValue] || { [name]: value };
    setUpdateDecoration([...updateDecoration]);
  };

  const nonImage = decoration?.Artwork1_S3_Location === '';

  return (
    <Grid container className={classes.panelBody}>
      {updateDecoration ? (
        disableDecorations ? (
          <>
            <Grid item className={classes.artworkContainer}>
              <FileUploadZone
                handleFileUpload={handleFileUpload}
                disabled={disabled}
                renderComponent={props => (
                  <Grid
                    container
                    alignItems="center"
                    className={classes.ImageUploadContainer}
                    style={{ border: props?.isDragActive ? '1px dotted #3577d4' : undefined }}
                  >
                    <Grid
                      item
                      xs={decoration?.Artwork1_S3_Location ? 6 : 12}
                      className={classes.uploadWrapper}
                      style={{ border: 0 }}
                    >
                      <Grid justifyContent="center" className={classes.iconWrapper}>
                        <img src="/images/public/file_upload_new.svg" alt="upload" className={classes.icon} />
                      </Grid>
                      <p className={classes.uploadText}>
                        Drag & Drop to upload or{' '}
                        <span className={classes.link} style={{ color: disabled ? '#787b80' : undefined }}>
                          <span style={{ color: '#3577D4' }}>Choose</span> a file
                        </span>
                      </p>
                      <p className={classes.fileFormats}>Accepted file types: GIF, SVG, JPEG, SVG</p>
                    </Grid>
                    {decoration?.Artwork1_S3_Location && (
                      <Grid item xs={6}>
                        <ArtworkDisplay decoration={updateDecoration[tabValue]} isDecoration={false} />
                      </Grid>
                    )}
                  </Grid>
                )}
              />
            </Grid>
            <Grid xs={12}>
              <div className={classes.notesWrapper}>
                <DecorationFieldEdit
                  multiline
                  decoration={updateDecoration[tabValue]}
                  name="Notes"
                  placeholder="Notes"
                  label="Additional Notes"
                  onChange={handleTextChange}
                  editable
                  compactStyle
                  maxLength={500}
                />
              </div>
            </Grid>
          </>
        ) : (
          <Grid item>
            <>
              <Grid container alignItems="center" style={{ height: '100%' }}>
                {!nonImage ? (
                  <Grid container>
                    <p className={classes.proofSectionLabel} style={{ marginBottom: 12 }}>
                      {readOnly ? 'Proof File' : 'Upload Artwork'}
                    </p>
                    {!readOnly ? (
                      <FileUploadZone
                        handleFileUpload={handleFileUpload}
                        disabled={disabled}
                        renderComponent={props => (
                          <Grid
                            container
                            alignItems="center"
                            className={classes.ImageUploadContainer}
                            style={{ border: props?.isDragActive ? '1px dotted #3577d4' : undefined }}
                          >
                            <Grid
                              item
                              xs={decoration?.Artwork1_S3_Location ? 6 : 12}
                              className={classes.uploadWrapper}
                              style={{ border: 0 }}
                            >
                              <Grid justifyContent="center" className={classes.iconWrapper}>
                                <img src="/images/public/file_upload_new.svg" alt="upload" className={classes.icon} />
                              </Grid>
                              <p className={classes.uploadText}>
                                Drag & Drop to upload or{' '}
                                <span className={classes.link} style={{ color: disabled ? '#787b80' : undefined }}>
                                  <span style={{ color: '#3577D4' }}>Choose</span> a file
                                </span>
                              </p>
                              <p className={classes.fileFormats}>Accepted file types: GIF, SVG, JPEG, SVG</p>
                            </Grid>
                            {decoration?.Artwork1_S3_Location && (
                              <Grid item xs={6}>
                                <ArtworkDisplay decoration={updateDecoration[tabValue]} isDecoration={false} />
                              </Grid>
                            )}
                          </Grid>
                        )}
                      />
                    ) : (
                      <Grid
                        container
                        alignItems="center"
                        className={classes.ImageUploadContainer}
                        // style={{ border: props?.isDragActive ? '1px dotted #3577d4' : undefined }}
                      >
                        <Grid item xs={12}>
                          <ArtworkDisplay decoration={updateDecoration[tabValue]} isDecoration={false} />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                ) : null}
                {!isEmpty(item?.available_imprint_options) && (
                  <Grid style={{ marginTop: 20 }}>
                    <p className={classes.proofSectionLabel}>Decorations method</p>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {item?.available_imprint_options.map(i => {
                        const color = i === updateDecoration[tabValue]?.Imprint_Type ? '#125CFF' : '#D6D8DB';
                        return (
                          <Box
                            height={50}
                            width="content-fit"
                            marginRight={4}
                            borderRadius={5}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gap={4}
                            sx={
                              !readOnly
                                ? {
                                    border: `1px solid ${color}`,
                                    cursor: 'pointer',
                                    padding: '12px 12px',
                                    margin: '8px 0px'
                                  }
                                : {
                                    border: `1px solid ${color}`,
                                    padding: '12px 12px',
                                    margin: '8px 0px'
                                  }
                            }
                            onClick={() => {
                              const searchedDecoration = updateDecoration?.map((item, index) => {
                                if (index === tabValue) {
                                  return {
                                    ...item,
                                    Imprint_Type: i
                                  };
                                }
                                return { ...item };
                              });
                              updateDecoration[tabValue] = searchedDecoration[tabValue] || { Imprint_Type: i };
                              if (!readOnly) setUpdateDecoration([...updateDecoration]);
                            }}
                          >
                            <Typography style={{ fontSize: 14, color: '#4A4F54', padding: 12 }}>{i}</Typography>
                          </Box>
                        );
                      })}
                    </div>
                  </Grid>
                )}
                <Grid xs={12} style={{ marginTop: 10 }}>
                  <DecorationFieldEdit
                    decoration={updateDecoration[tabValue]}
                    name="Location"
                    placeholder="Type here"
                    label="Decorations Location"
                    onChange={handleTextChange}
                    editable
                    compactStyle
                    maxLength={100}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid xs={12} style={{ marginTop: 10 }}>
                  <DecorationFieldEdit
                    decoration={updateDecoration[tabValue]}
                    name="Dimensions"
                    placeholder="Type here"
                    label="Decoration Dimension"
                    onChange={handleTextChange}
                    editable
                    compactStyle
                    maxLength={100}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid xs={12} style={{ marginTop: 10 }}>
                  <DecorationFieldEdit
                    decoration={updateDecoration[tabValue]}
                    name="Colors"
                    placeholder="Type here"
                    label="Decoration Color"
                    onChange={handleTextChange}
                    editable
                    compactStyle
                    maxLength={100}
                    disabled={explicitDisableFields.includes('colors') || readOnly}
                  />
                </Grid>
                <Grid xs={12}>
                  <div className={classes.notesWrapper}>
                    <DecorationFieldEdit
                      multiline
                      decoration={updateDecoration[tabValue]}
                      name="Notes"
                      placeholder="Notes"
                      label="Additional Notes"
                      onChange={handleTextChange}
                      editable
                      compactStyle
                      maxLength={500}
                      disabled={readOnly}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          </Grid>
        )
      ) : null}
    </Grid>
  );
};

export default ItemsDecorationPanel;
