import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import DetailsModal from '../../../directory/staffOrders/DetailsModal';
import CancelModal from './CancelModal';
import InvalidAddressModal from './InvalidAddressModal';
import { ShipmentCard } from './ShipmentCard';
import { shipmentStatus } from '../../../../apis/constants';

const ShipmentsGrid = ({ shipments, fetchShipments, onCancel }) => {
  const [shipmentToDelete, setShipmentToDelete] = useState(null);
  const [shipmentForDetails, setShipmentForDetails] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const selectShipmentToDelete = shipment => {
    setShipmentToDelete(shipment);
    setOpenDeleteModal(Boolean(shipment));
  };

  const handleClose = () => {
    setShipmentForDetails(null);
  };

  return (
    <Grid container spacing={4}>
      {shipments.map(sh => (
        <Grid container item xs={12} sm={6} md={4} lg={3} key={sh.id} justifyContent="center">
          <ShipmentCard
            shipment={sh}
            setOrderForDetails={setShipmentForDetails}
            setOrderToDelete={selectShipmentToDelete}
          />
        </Grid>
      ))}
      <CancelModal
        open={openDeleteModal}
        shipmentToDelete={shipmentToDelete}
        clearShipmentToDelete={() => setShipmentToDelete(null)}
        onClose={() => setOpenDeleteModal(false)}
        onCancel={onCancel}
      />
      {shipmentForDetails?.status === shipmentStatus.invalidAddress ? (
        <InvalidAddressModal shipment={shipmentForDetails} fetchShipments={fetchShipments} onClose={handleClose} />
      ) : (
        <DetailsModal order={shipmentForDetails} onClose={handleClose} />
      )}
    </Grid>
  );
};

export default ShipmentsGrid;
