import React from 'react';
import dayjs from 'dayjs';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Button, Tooltip } from '@swagup-com/components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { ImgWithHandler } from '../../../global/ImgUtils';
import { joinFields } from '../../../../helpers/utils';
import ShipmentStatus from '../../../global/ShipmentStatus';
import { redactedText } from '../../../../helpers/helperConstants';
import styles from './styles/shipmentCardStyles';
import { shipmentStatus } from '../../../../apis/constants';

const useStyles = makeStyles(styles);

export const ShipmentCard = ({ shipment, setOrderForDetails, setOrderToDelete }) => {
  const classes = useStyles();
  const titleText = shipment.products.length > 0 ? shipment.products[0].product.name : '';
  const employeeName = shipment.redacted
    ? redactedText
    : joinFields([shipment.employee.first_name, shipment.employee.last_name], ' ');

  const tooltipTracking = shipment.tracking
    ? 'For more information please select the tracking number below'
    : "We are processing this shipment. When it's on the way, we will provide tracking info below.";

  const tooltipEasyPost = `${shipment.easypost_message ? `${shipment.easypost_message}. ` : ''}${
    shipment.easypost_description ? `${shipment.easypost_description}.` : ''
  }`;
  const tooltipMsg = isEmpty(tooltipEasyPost) ? tooltipTracking : tooltipEasyPost;

  const notCancelled = shipment.status !== shipmentStatus.cancelled;

  return (
    <Grid container direction="column" className={classes.root}>
      <div className={classes.imgContainer}>
        <ImgWithHandler
          src={shipment.products.length > 0 ? shipment.products[0].product.image : null}
          alt="shipment-image"
          width={256}
          height={256}
        />
        <div style={{ position: 'absolute', left: 15, top: 15 }}>
          <ShipmentStatus status={shipment.status} withTooltip />
        </div>
        <div style={{ position: 'absolute', right: 15, top: 15 }}>
          <Tooltip placement="bottom-center" arrow title={tooltipMsg} classes={classes}>
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Tooltip>
        </div>
        {shipment.products.length > 1 && (
          <div className={classes.multipleProductsStatusContainer}>
            <div className={classes.multipleProductsStatus}>
              <img src="/images/public/Icon_Grid.svg" alt="Multiple Products" />
              <Typography>Multiple Products</Typography>
            </div>
          </div>
        )}
      </div>
      <div className={classes.cardInfoContainer}>
        <Grid item sm={12} md={12}>
          <Grid container wrap="nowrap">
            <Grid item xs={12} sm={12} md={8}>
              <p className={classes.packTitle} title={titleText}>
                {titleText}
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <p className={classes.packDateText}>{dayjs(shipment.date).format('MM/DD/YYYY')}</p>
            </Grid>
          </Grid>

          <div style={{ marginTop: 'auto' }}>
            <div>
              <p title={employeeName} className={classes.packEmployeeName}>
                {employeeName}
              </p>
            </div>
            <p className={classes.packNormaltext}>
              Tracking Info:
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={clsx(
                  classes.trackingInfoLink,
                  shipment.tracking ? classes.linkAlike : classes.packNormaltext
                )}
                href={shipment.tracking_url}
              >
                {shipment.tracking || '-'}
              </a>
            </p>
          </div>
        </Grid>
        <Grid container className={classes.packButtonsContainer}>
          <Grid item xs={6} sm={7} md={6} align="left">
            <Button size="small" className={classes.packViewDetails} onClick={() => setOrderForDetails(shipment)}>
              View Details
            </Button>
          </Grid>
          <Grid item xs={6} sm={5} md={6} align="right">
            {!shipment.tracking && notCancelled && !shipment.in_picking && (
              <Button
                size="small"
                className={classes.packDeletetext}
                variant="text"
                onClick={() => setOrderToDelete(shipment)}
              >
                Cancel shipment
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
