import React, { useState, useEffect } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Grid,
  Dialog,
  Typography,
  makeStyles,
  withStyles,
  Switch
} from '@material-ui/core';
import { KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers';
import InfoIcon from '@material-ui/icons/Info';
import { useHistory } from 'react-router-dom';
import { Button, Typography as SwagupTypography } from '@swagup-com/components';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useErrorHandler } from 'react-error-boundary';
import round from 'lodash/round';
import dayjs from 'dayjs';
import ImageUpload from '../../global/ImageUpload';
import { submitOrder } from '../../../actions/catalog';
import { changeQuantity } from '../../../actions/cart';
import { NAME_RE, EMAIL_RE } from '../../../utils/regexs';
import styles, {
  accordionStyles,
  accordionSummaryStyles,
  accordionDetailsStyles,
  datePickerStyles
} from './CheckoutForm.styles';
import { useChiliPiper, useCompany, useProfile } from '../../../hooks';
import { getFirstLastName, joinFields } from '../../../helpers/utils';
import { Img } from '../../global/ImgUtils';
import gtm from '../../../utils/gtm';
import { useAuth } from '../../global/Authentication/AuthProvider';
import { isWeekend } from '../../../helpers/commonDateFunctions';
import { PhoneField, TextField } from '../../global/reactHookFormFields';
import accountProductsApi from '../../../apis/swagup/accountProducts';
import apiPaths from '../../../helpers/apiPaths';
import { handleClearbitEmailValidation } from '../../../actions';

const ImageUploadController = ({ control, name, label, onProgress }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange } }) => (
      <ImageUpload
        label={label}
        fileBefore="/images/custom/assets/icons/cloud-upload.svg"
        accept="image/*, application/pdf, application/illustrator, application/postscript"
        reject="image/gif"
        minSize={10}
        maxSize={200 * 1024 * 1024}
        onChange={onChange}
        onProgress={onProgress}
      />
    )}
  />
);

const LogoHintDialog = ({ open, onClose, classes }) => (
  <Dialog maxWidth="md" open={open} onClose={onClose}>
    <Grid container justifyContent="space-between" className={classes.hintDialogContainer}>
      <Grid item>
        <Typography className={classes.hintDialogHeader}>SwagUp Learning</Typography>
        <Typography variant="h4" component="h2" style={{ marginBottom: 8 }}>
          Vector file
        </Typography>
        <Typography className={classes.hintDialogListTitle}>
          Benefit of providing vector file
          <br />
          at this stage
        </Typography>
        <ul className={classes.hintDialogList}>
          <Typography component="li" className={classes.hintDialogListItem}>
            Provides high quality print
          </Typography>
          <Typography component="li" className={classes.hintDialogListItem}>
            Faster turnaround
          </Typography>
          <Typography component="li" className={classes.hintDialogListItem}>
            Less hiccups during design process
          </Typography>
        </ul>
        <div className={classes.hintDialogSeparator} />
        <Typography className={classes.hintDialogListTitle}>How to recognize a vector file?</Typography>
        <ul className={classes.hintDialogList}>
          <Typography component="li" className={classes.hintDialogListItem}>
            The file should end in: .svg, .eps, .ai, .pdf
          </Typography>
        </ul>
        <Button variant="outlined" onClick={onClose} className={classes.hintDialogCloseBtn}>
          Close
        </Button>
      </Grid>
      <Grid item className={classes.hintDialogImgContainer}>
        <Img
          src="/images/catalog/with-vector-file.png"
          alt="with vector file"
          width={336}
          height={160}
          className={classes.hintDialogImg}
        />
        <Img
          src="/images/catalog/without-vector-file.png"
          alt="without vector file"
          width={336}
          height={160}
          className={classes.hintDialogImg}
        />
      </Grid>
    </Grid>
  </Dialog>
);

const UploadLogoSection = ({
  fromProducts,
  formMethods,
  uploadsInProgress,
  onContinue,
  onUpload,
  isLoading,
  classes
}) => {
  const { control, formState } = formMethods;
  const { errors } = formState;
  const [openLogoHint, setOpenLogoHint] = React.useState(false);

  return (
    <Grid item xs={12}>
      <Typography className={classes.fieldTitle}>Upload the logo you&apos;d like to put on your swag</Typography>
      <Grid container alignItems="center" className={classes.logoHintContainer}>
        <Img src="/images/catalog/logo-hint-icon.svg" alt="hints for logo format" width={32} height={32} />
        <Grid item className={classes.logoHintTextContainer}>
          <Typography className={classes.logoHintTextTitle}>
            Upload Vector file for the best quality and faster turnaround
          </Typography>
          <Typography component="small" className={classes.logoHintTextHint}>
            The file should end in: .svg, .eps, .ai, .pdf
          </Typography>
        </Grid>
        <Button variant="text" onClick={() => setOpenLogoHint(true)} className={classes.logoHintButton}>
          Learn more
        </Button>
        <LogoHintDialog open={openLogoHint} onClose={() => setOpenLogoHint(false)} classes={classes} />
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <ImageUploadController control={control} name="primaryLogo" label="Primary Logo" onProgress={onUpload} />
        </Grid>
        <Grid item xs={6}>
          <ImageUploadController control={control} name="secondaryLogo" label="Secondary Logo" onProgress={onUpload} />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.actions}>
          <Button
            type={fromProducts ? 'submit' : 'button'}
            variant="primary"
            loading={isLoading}
            disabled={
              Boolean(errors.quantity || errors.budget || errors.comments) || uploadsInProgress > 0 || isLoading
            }
            onClick={() => {
              onContinue();
              gtm.onClickContinue('Mockups request form');
            }}
            style={{ height: 56 }}
          >
            {fromProducts ? 'Submit Design(s)' : 'Continue'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ProductsForm = props => (
  <>
    <Typography variant="h4" component="h2" style={{ margin: '20px 0px' }}>
      Design Information
    </Typography>
    <UploadLogoSection fromProducts {...props} />
  </>
);

const parseToNaturalNumber = value => Math.max(parseInt(value, 10), 0);

const Accordion = withStyles(accordionStyles)(MuiAccordion);
const AccordionSummary = withStyles(accordionSummaryStyles)(MuiAccordionSummary);
const AccordionDetails = withStyles(accordionDetailsStyles)(MuiAccordionDetails);
const KeyboardDatePicker = withStyles(datePickerStyles)(MuiKeyboardDatePicker);

const OrderForm = ({
  formMethods,
  loading,
  pack,
  minQuantity,
  minimumDeliveryDate,
  defaultDeliveryDate,
  uploadsInProgress,
  contactDetailsExpanded,
  setContactDetailsExpanded,
  onUpload,
  classes
}) => {
  const { register, control, getValues, setValue, formState, watch } = formMethods;
  const { name, email, companyName } = getValues();
  const { errors } = formState;

  const firmDate = watch('firm_ihd');

  return (
    <>
      <Accordion square expanded={!contactDetailsExpanded}>
        <AccordionSummary>
          <Grid container justifyContent="space-between">
            <SwagupTypography variant="h3BoldInter" component="h2" style={{ color: '#131415', fontWeight: 500 }}>
              1. Tell us about your Swag Project
            </SwagupTypography>
            {contactDetailsExpanded && (
              <Button
                variant="text"
                size="small"
                onClick={() => setContactDetailsExpanded(false)}
                style={{ padding: 0 }}
              >
                <img src="/images/account/edit-icon.svg" alt="edit" style={{ marginRight: 7 }} />
                Edit
              </Button>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {pack && (
              <>
                <Grid item xs={6}>
                  <SwagupTypography className={classes.formLabel} variant="body3RegularInter">
                    How many swag packs are you looking to get?<small>*</small>
                  </SwagupTypography>
                  <TextField
                    type="number"
                    placeholder={`Minimum of ${minQuantity}`}
                    fullWidth
                    error={errors.quantity?.message}
                    {...register('quantity', {
                      required: 'Required',
                      min: { value: minQuantity, message: `Must be at least ${minQuantity} swag packs` },
                      valueAsNumber: true
                    })}
                    onChange={e => setValue('quantity', parseToNaturalNumber(e.target.value), { shouldValidate: true })}
                    className={classes.formInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SwagupTypography className={classes.formLabel} variant="body3RegularInter">
                    What is your budget per pack?<small>*</small>
                  </SwagupTypography>
                  <TextField
                    className={classes.formInput}
                    type="number"
                    placeholder="Enter budget"
                    fullWidth
                    error={errors.budget?.message}
                    {...register('budget', {
                      required: 'Required',
                      min: { value: 1, message: ' ' },
                      valueAsNumber: true
                    })}
                    onChange={e => setValue('budget', parseToNaturalNumber(e.target.value), { shouldValidate: true })}
                  />
                </Grid>
              </>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SwagupTypography className={classes.formLabel} variant="body3RegularInter">
                  Do you need these by a certain date?
                </SwagupTypography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <KeyboardDatePicker
                      className={classes.formInput}
                      {...field}
                      autoOk
                      minDate={minimumDeliveryDate}
                      // shouldDisableDate={date => isWeekend(date.toDate())}
                      format="MMMM Do, YYYY"
                      inputVariant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        style: { cursor: 'default' },
                        inputProps: { style: { cursor: 'default' } }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item container alignItems="center" justifyContent="space-between" xs={6}>
                <Grid item container style={{ flexDirection: 'column' }} xs={8}>
                  <SwagupTypography
                    className={classes.formLabel}
                    variant="body3RegularInter"
                    style={{ color: '#0B1829', marginBottom: 12 }}
                  >
                    This is a firm date
                  </SwagupTypography>
                  <SwagupTypography
                    className={classes.formLabel}
                    variant="body3RegularInter"
                    style={{ color: '#131415', marginBottom: 0 }}
                  >
                    For ex, I need this for a conference or an event
                  </SwagupTypography>
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    color="primary"
                    inputProps={{ 'aria-label': 'checkbox with primary color' }}
                    {...register('firm_ihd')}
                    className={firmDate ? classes.switchInputOn : classes.switchInputOff}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item container xs={6} alignItems="center" style={{ gap: 4, flexWrap: 'nowrap' }}>
              <InfoIcon style={{ color: '#2A63F6', fill: '#2A63F6', fontSize: '14px' }} />
              <SwagupTypography variant="body4RegularInter" style={{ color: '#2A63F6' }}>
                Our typical turnaround time is 3-4 weeks but orders can be expedited on request.
              </SwagupTypography>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 18 }}>
              <SwagupTypography className={classes.formLabel} variant="body3RegularInter">
                Any additional notes or comments you like to add
              </SwagupTypography>
              <TextField
                className={classes.formInput}
                multiline
                placeholder="Insert card copy, special requests, or anything we missed"
                fullWidth
                error={errors.comments?.message}
                {...register('comments', {
                  maxLength: { value: 255, message: 'Must have less than 255 characters' }
                })}
              />
            </Grid>

            <Grid container spacing={2} style={{ marginLeft: 4 }}>
              <SwagupTypography className={classes.formLabel} variant="body3RegularInter">
                Coupon code
              </SwagupTypography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                className={classes.formInput}
                fullWidth
                placeholder="eg. SUMMER2024"
                {...register('coupon_code')}
                withTooltip
              />
            </Grid>

            <Grid item xs={12} style={{ paddingBottom: 20 }}>
              <Grid item container xs={6} alignItems="center" style={{ gap: 4 }}>
                <InfoIcon style={{ color: '#989EA4', fill: '#989EA4', fontSize: '14px' }} />
                <SwagupTypography variant="body4RegularInter" style={{ color: '#989EA4' }}>
                  Code will be applied after submission
                </SwagupTypography>
              </Grid>
            </Grid>

            <UploadLogoSection
              formMethods={formMethods}
              errors={errors}
              uploadsInProgress={uploadsInProgress}
              onContinue={() => setContactDetailsExpanded(true)}
              control={control}
              onUpload={onUpload}
              classes={classes}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={contactDetailsExpanded}>
        <AccordionSummary>
          <SwagupTypography variant="h3BoldInter" component="h2" style={{ color: '#131415', fontWeight: 500 }}>
            2. Contact Details
          </SwagupTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SwagupTypography className={classes.formLabel} variant="body3RegularInter">
                Your full name<small>*</small>
              </SwagupTypography>
              <TextField
                placeholder="Jane Doe"
                fullWidth
                error={errors.name?.message}
                {...register('name', {
                  required: 'Required',
                  pattern: {
                    value: NAME_RE,
                    message: 'Letters only'
                  },
                  validate: value => value.trim().length > 0 || 'Invalid'
                })}
                className={classes.formInput}
              />
            </Grid>
            <Grid item xs={6}>
              <SwagupTypography className={classes.formLabel} variant="body3RegularInter">
                Work email address<small>*</small>
              </SwagupTypography>
              <TextField
                className={classes.formInput}
                type="email"
                placeholder="example@company.com"
                fullWidth
                error={errors.email?.message}
                {...register('email', {
                  required: 'Required',
                  pattern: {
                    value: EMAIL_RE,
                    message: 'Invalid email'
                  },
                  validate: async value => handleClearbitEmailValidation(value)
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <SwagupTypography className={classes.formLabel} variant="body3RegularInter">
                What&apos;s the name of your company?<small>*</small>
              </SwagupTypography>
              <TextField
                className={classes.formInput}
                name="companyName"
                placeholder="Your company name"
                fullWidth
                error={errors.companyName?.message}
                {...register('companyName', {
                  required: 'Required',
                  validate: value => value.trim().length > 0 || 'Invalid'
                })}
                onChange={e => setValue('companyName', e.target.value.trimStart(), { shouldValidate: true })}
                style={{ padding: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <SwagupTypography className={classes.formLabel} variant="body3RegularInter">
                Contact number
              </SwagupTypography>
              <PhoneField
                className={classes.formInput}
                control={control}
                name="phone"
                variant="outlined"
                error={errors.phone}
              />
            </Grid>
            <Grid item sm={12} md={6} className={classes.actions}>
              <Button
                type="submit"
                variant="primary"
                disabled={
                  !!(errors.name || errors.email || errors.companyName) || !(name && email && companyName) || loading
                }
              >
                Submit mockup request
              </Button>
              <Typography className={classes.notice}>
                You will receive proofs and a quote before finalizing order
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const buildLeadData = info => {
  const [firstName, lastName] = getFirstLastName(info.name);
  return {
    FirstName: firstName,
    LastName: lastName,
    Email: info.email,
    Phone: info.phone,
    Company: info.companyName
  };
};

const buildAccountProducts = (cart, cartInfo, isBulk) => {
  if (isBulk) {
    const bulk = cart.map(({ product, colorIdx }) => ({
      base_product: product.id,
      base_product_sf_id: product.salesforce_id,
      theme_color: colorIdx !== null ? product.available_colors[colorIdx].label : '',
      theme_color_hex: colorIdx !== null ? product.available_colors[colorIdx].color : '',
      primary_logo_path: cartInfo.primaryLogo,
      secondary_logo_path: cartInfo.secondaryLogo
    }));

    return bulk.length === 1 ? bulk[0] : bulk;
  }

  const pack = {
    pack_items: cart.map(({ product, quantity, colorIdx }) => ({
      base_product: product.id,
      base_product_sf_id: product.salesforce_id,
      units_per_pack: quantity,
      theme_color: colorIdx !== null ? product.available_colors[colorIdx].label : '',
      theme_color_hex: colorIdx !== null ? product.available_colors[colorIdx].color : ''
    })),
    primary_logo_path: cartInfo.primaryLogo,
    secondary_logo_path: cartInfo.secondaryLogo
  };

  return pack;
};

const useStyles = makeStyles(styles);

const CheckoutForm = ({ fromProducts, onSuccessPathStart }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleError = useErrorHandler();

  const { items: cart, pack, numberOfColors, minQuantity, loading: cartLoading } = useSelector(state => state.cart);
  const { loading: catalogLoading } = useSelector(state => state.catalog);
  const { isAuthenticated, isPending } = useAuth();
  const isLoggedIn = !isPending && isAuthenticated;
  const { data: profile } = useProfile({ enabled: isLoggedIn });
  const { data: company } = useCompany({ enabled: isLoggedIn });
  const dispatch = useDispatch();
  const [isRouting, setIsRouting] = React.useState(false);
  const loading = cartLoading || catalogLoading || isRouting;

  const [contactDetailsExpanded, setContactDetailsExpanded] = useState(false);
  const [uploadsInProgress, setUploadsInProgress] = useState(0);
  const queryClient = useQueryClient();

  const pricePerPack = pack
    ? pack.prices[pack.quantity]
    : cart.reduce((sum, i) => round(sum + round(i.prices[i.quantity] * i.quantity, 2), 2), 0);
  const minimumDeliveryDate = dayjs().add(1, 'days');
  const defaultDeliveryDate = dayjs().add(4, 'weeks');

  const formMethods = useForm({
    mode: 'all',
    defaultValues: {
      budget: Math.ceil(pricePerPack),
      quantity: pack?.quantity || 1,
      numberOfColors,
      date: defaultDeliveryDate,
      name: joinFields([profile.first_name, profile.last_name], ' '),
      email: profile.email || '',
      phone: profile.primary_phone || '',
      companyName: company.name || '',
      primaryLogo: null,
      secondaryLogo: null
    }
  });
  const { handleSubmit, watch } = formMethods;

  const quantity = watch('quantity');

  const submitLead = useChiliPiper();

  useEffect(() => {
    if (quantity >= minQuantity) dispatch(changeQuantity(quantity));
  }, [dispatch, minQuantity, quantity]);

  const createProductsMutation = useMutation(products => accountProductsApi.createAccountProducts(products), {
    onSuccess: () => {
      queryClient.invalidateQueries([apiPaths.accountProducts]);
      history.push('/products');
    },
    onError: error => handleError(error.data)
  });

  const onSubmit = info => {
    const cartInfo = { ...info, cartQuantity: pack?.quantity };
    const lead = buildLeadData(info);
    const onSuccessWithData = () =>
      history.push({ pathname: `/${onSuccessPathStart}/thanks`, state: { userName: lead.FirstName } });

    if (fromProducts) {
      const products = buildAccountProducts(cart, cartInfo, pack === null);
      createProductsMutation.mutate(products);
    } else {
      dispatch(submitOrder(cart, cartInfo, pack === null))
        .then(() => {
          setIsRouting(true);
          submitLead({
            lead,
            onRouted: onSuccessWithData,
            onError: onSuccessWithData
          });
        })
        .catch(() => history.push('/error'));
    }
  };

  const onUpload = inProgress => setUploadsInProgress(v => v + (inProgress ? 1 : -1));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      {fromProducts ? (
        <ProductsForm
          formMethods={formMethods}
          isLoading={createProductsMutation.isLoading}
          uploadsInProgress={uploadsInProgress}
          onContinue={() => setContactDetailsExpanded(true)}
          onUpload={onUpload}
          classes={classes}
        />
      ) : (
        <OrderForm
          formMethods={formMethods}
          loading={loading}
          pack={pack}
          minQuantity={minQuantity}
          minimumDeliveryDate={minimumDeliveryDate}
          defaultDeliveryDate={defaultDeliveryDate}
          uploadsInProgress={uploadsInProgress}
          contactDetailsExpanded={contactDetailsExpanded}
          setContactDetailsExpanded={setContactDetailsExpanded}
          onUpload={onUpload}
          classes={classes}
        />
      )}
    </form>
  );
};

export default CheckoutForm;
